import { ChevronLeft, ChevronRight, KeyboardArrowUp } from '@mui/icons-material'
import { T, useTranslate } from '@tolgee/react'
import axios from 'axios'
import classNames from 'classnames'
import { ViewContext } from 'components/lib'
import { useCallback, useContext, useRef, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Microbutton } from 'ui'
import ChatbotsCarouselHeader from './ChatbotsCarouselHeader'
import LibraryItem from './LibraryItem'

export const ChatbotsCarousel = ({
  items,
  title,
  refetchData,
  seeMoreUrl,
  searchHintsUI,
  clearSearch,
  isSearchActive,
  icon,
  disableSlider = false,
  setNumberPages,
  total,
}) => {
  const { t } = useTranslate()
  const context = useContext(ViewContext)
  const sliderRef = useRef(null)
  const [key, setKey] = useState(new Date())
  const [isExpanded, setIsExpanded] = useState(false)

  const [navigationState, setNavigationState] = useState({ showPrev: false, showNext: true })

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return
    sliderRef.current.swiper.slidePrev()
    sliderRef.current.swiper.slidePrev()
    sliderRef.current.swiper.slidePrev()

    setNavigationState(() => ({ showPrev: !sliderRef.current?.swiper.isBeginning, showNext: true }))
  }, [])

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return
    sliderRef.current.swiper.slideNext()
    sliderRef.current.swiper.slideNext()
    sliderRef.current.swiper.slideNext()

    setNavigationState(() => ({ showPrev: true, showNext: !sliderRef.current?.swiper.isEnd }))
  }, [setNavigationState])

  const handleLikeChatbot = async (id) => {
    try {
      const url = items.find((item) => item.id === id)?.isLiked
        ? '/api/user/liked_chatbots/remove'
        : '/api/user/liked_chatbots/add'
      await axios.post(url, { chatbot_id: id })

      refetchData(new Date())
    } catch (err) {
      context.handleError(err)
    }
  }

  const handleRecommendChatbot = async (id) => {
    try {
      const url = items.find((item) => item.id === id)?.isRecommended
        ? '/api/user/recommended_chatbots/remove'
        : '/api/user/recommended_chatbots/add'
      await axios.post(url, { chatbot_id: id })

      refetchData(new Date())
    } catch (err) {
      context.handleError(err)
    }
  }

  if (!items.length) return

  return (
    <div
      key={key}
      className={classNames(
        'flex flex-col p-[14px] lg:px-[50px] lg:pb-[30px] lg:pt-[25px]',
        disableSlider ? 'gap-[16px]' : 'gap-[6px]'
      )}
    >
      <ChatbotsCarouselHeader
        isSearchActive={isSearchActive}
        icon={icon}
        title={title}
        clearSearch={clearSearch}
        searchHintsUI={searchHintsUI}
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
      />
      {disableSlider || isExpanded ? (
        <>
          <div className='flex flex-wrap gap-[10px] py-[10px] lg:gap-[20px]'>
            {items.map((item) => {
              return (
                <LibraryItem
                  key={item.id}
                  item={item}
                  handleLikeChatbot={handleLikeChatbot}
                  handleRecommendChatbot={handleRecommendChatbot}
                />
              )
            })}
          </div>
          <div className='flex justify-center'>
            <div className='bg-brand-form-accent-light flex items-center gap-1 rounded-[4px]'>
              <Microbutton
                variant='transparent-light'
                className='hover:!bg-brand-form-accent !bg-transparent'
                text={<T keyName='eleo-collapse-section'>Collapse section</T>}
                icon={<KeyboardArrowUp fontSize='inherit' />}
                onClick={() => setIsExpanded(false)}
              />
              <div className='h-full  border-x border-white px-2'>
                <span className='text-brand-violet-light  text-[12px]'>
                  <T
                    keyName='eleo-showind-n-items'
                    defaultValue='Showing <span>{val}</span> from <span>{maxVal}</span>'
                    params={{
                      val: items.length,
                      maxVal: total !== 0 && total < 100 ? total : t('eleo-many', 'many'),
                      span: <span className='font-medium'></span>,
                    }}
                  ></T>
                </span>
              </div>
              <Microbutton
                variant='transparent-light'
                className='hover:!bg-brand-form-accent !bg-transparent'
                text={<T keyName='eleo-load-more'>Load more</T>}
                onClick={() => setNumberPages?.((prev) => prev + 1)}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={classNames('slider-container relative py-[10px]')}>
            <Swiper
              ref={sliderRef}
              spaceBetween={10}
              breakpoints={{
                1024: {
                  spaceBetween: 20,
                },
              }}
              slidesPerView={'auto'}
              className='!ml-0 select-none'
              watchOverflow
              onInit={() => setKey(new Date())}
              onTouchEnd={(e) =>
                setNavigationState({ showPrev: !e.isBeginning, showNext: !e.isEnd })
              }
            >
              {items.map((item) => {
                return (
                  <SwiperSlide key={item.id} className='!w-[190px]'>
                    <LibraryItem
                      key={item.id}
                      item={item}
                      handleLikeChatbot={handleLikeChatbot}
                      handleRecommendChatbot={handleRecommendChatbot}
                    />
                  </SwiperSlide>
                )
              })}
            </Swiper>
            {navigationState.showPrev && <SamplePrevArrow onClick={handlePrev} />}
            {navigationState.showNext && sliderRef.current?.swiper?.allowSlideNext && (
              <SampleNextArrow onClick={handleNext} />
            )}
          </div>
        </>
      )}

      {/* {seeMoreUrl && (
        <h4 className='flex justify-end'>
          <Link to={seeMoreUrl}>
            <T keyName={'eleo-see-more'}>See more</T>
          </Link>
        </h4>
      )} */}
    </div>
  )
}

function SampleNextArrow(props) {
  const { onClick } = props

  return (
    <div className='next-arrow hidden lg:block'>
      <div
        className='absolute -right-[25px] top-[38%] z-[11] flex  h-[90px] w-[50px] cursor-pointer  items-center justify-center rounded-[25px] bg-white shadow-[0_0_10px_rgba(0,0,0,0.25)]'
        onClick={onClick}
      >
        <button className='flex items-center justify-center'>
          <ChevronRight className='!text-brand-body' sx={{ height: '28px', width: '28px' }} />
        </button>
      </div>
    </div>
  )
}

function SamplePrevArrow(props) {
  const { onClick } = props

  if (props.currentSlide === 0) return
  return (
    <div className='prev-arrow hidden lg:block'>
      <div
        className='absolute -left-[20px] top-[38%] z-[11] flex  h-[90px] w-[50px] cursor-pointer  items-center justify-center rounded-[25px] bg-white shadow-[0_0_10px_rgba(0,0,0,0.25)]'
        onClick={onClick}
      >
        <button className='flex items-center justify-center'>
          <ChevronLeft className='!text-brand-body' sx={{ height: '28px', width: '28px' }} />
        </button>
      </div>
    </div>
  )
}
