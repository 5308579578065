/***
 *
 *   SIGN UP (user)
 *   Signup form for child users
 *
 **********/

import { useTranslate } from '@tolgee/react'
import { Animate, AuthContext, Card, Form, Link, Row, SocialSignin } from 'components/lib'
import { DEFAULT_PAGE } from 'constants'
import { errorToast } from 'helpers'
import { useContext, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

export function SignupUser(props) {
  const context = useContext(AuthContext)
  const [params, setParams] = useSearchParams()

  const { t } = useTranslate()

  const id = params.get('id')
  const email = params.get('email')

  useEffect(() => {
    const error = params.get('error')
    if (error) {
      if (error === 'EMAIL_IN_USE')
        errorToast(
          t(
            'eleo-error-email-in-use',
            'You already have an account registered with this email address. Please use another email address'
          ),
          null,
          10000
        )
      else errorToast(error, null, 10000)

      // Clear error from url
      const id = params.get('id')
      const email = params.get('email')
      setParams({ id: id, email: email })
    }
  }, [params, setParams, t])

  return (
    <Animate type='pop'>
      <Row title='Create Your Account'>
        <Card restrictWidth center>
          <SocialSignin network={['google', 'facebook', 'twitter']} showOr invite={id} />

          <Form
            inputs={{
              name: {
                label: 'First Name',
                value: '',
                type: 'text',
                required: true,
                errorMessage: 'Please enter your first name',
              },
              email: {
                label: 'Email',
                value: email,
                type: 'email',
                required: true,
              },
              password: {
                label: 'Password',
                type: 'password',
                required: true,
                complexPassword: true,
              },
              confirm_password: {
                type: 'hidden',
                value: null,
              },
              invite_id: {
                type: 'hidden',
                value: id,
              },
            }}
            url='/api/user'
            method='POST'
            redirect={DEFAULT_PAGE}
            buttonText='Create Account'
            callback={context.signin}
          />

          <div className='mt-4'>
            Already registered? <Link url='/signin' text='Log In' />
          </div>
        </Card>
      </Row>
    </Animate>
  )
}
