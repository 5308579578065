import { T, useTranslate } from '@tolgee/react'
import { errorToast } from 'helpers'
import MicRecorder from 'mic-recorder-to-mp3'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useGetTranscription } from 'toolComponents/generic/audio/transcription'
import AudioInputLayout from './AudioInputLayout'
import { Mic } from '@mui/icons-material'
import Lottie from 'react-lottie'
import * as animationData from '../sparkle-lottie.json'

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
}

export default function ChatPushToTalkInput({
  state,
  isGenerating,
  handleSubmit,
  handleStopRequest,
  audioStream,
  setAudioStream,
  isChatbot = false,
}) {
  const { t } = useTranslate()

  const [isLoading, setIsLoading] = useState(false)
  const [isRecording, setIsRecording] = useState(false)
  const [speechVolume, setSpeechVolume] = useState(false)
  const [activationType, setActivationType] = useState()

  const getTranscription = useGetTranscription(isChatbot)

  const startMicRef = useRef()
  const animationFrameRef = useRef(null)
  const speakingTimeoutRef = useRef(null)

  const recorderRef = useRef()
  const streamRef = useRef()
  const audioContextRef = useRef()

  useEffect(() => {
    return () => {
      if (animationFrameRef.current) cancelAnimationFrame(animationFrameRef.current)
    }
  }, [])

  const handleStartRecording = useCallback(async () => {
    if (audioStream) setAudioStream()

    try {
      recorderRef.current = new MicRecorder({
        bitRate: 128,
      })

      recorderRef.current
        .start()
        .then(() => setIsRecording(true))
        .catch((err) => {
          if (err.name === 'NotAllowedError')
            errorToast(
              t('eleo-error-microphone-permission', 'You have to enable the microphone permission')
            )
          else {
            console.log(err)
            errorToast(t('eleo-error-generic'))
          }
        })

      const audioContext = new window.AudioContext()
      audioContextRef.current = audioContext
      const stream = await window.navigator.mediaDevices.getUserMedia({ audio: true })
      streamRef.current = stream
      const source = audioContext.createMediaStreamSource(stream)

      const analyser = audioContext.createAnalyser()
      analyser.minDecibels = -60
      analyser.smoothingTimeConstant = 0.8
      analyser.fftSize = 256

      source.connect(analyser)
      const analyserData = new Uint8Array(analyser.frequencyBinCount)

      const checkAudioLevel = () => {
        analyser.getByteFrequencyData(analyserData)

        let sum = 0
        for (let i = 0; i < analyserData.length; i++) {
          sum += analyserData[i]
        }
        const average = sum / analyserData.length
        const threshold = 1

        if (average > threshold) {
          if (speakingTimeoutRef.current) clearTimeout(speakingTimeoutRef.current)
          setSpeechVolume(Math.min(average / 10, 0.2))
          speakingTimeoutRef.current = setTimeout(() => {
            setSpeechVolume(0)
          }, 250)
        }

        animationFrameRef.current = requestAnimationFrame(checkAudioLevel)
      }

      checkAudioLevel()
    } catch (err) {
      if (err.name === 'NotAllowedError')
        errorToast(
          t('eleo-error-microphone-permission', 'You have to enable the microphone permission')
        )
      else {
        console.log(err)
        errorToast(t('eleo-error-generic'))
      }
    }
  }, [t, audioStream, setAudioStream])

  const handleStopRecording = useCallback(
    (isCancelRequest = false) => {
      if (animationFrameRef.current) cancelAnimationFrame(animationFrameRef.current)
      if (streamRef.current) {
        streamRef.current.getAudioTracks().forEach(function (track) {
          track.stop()
        })
      }
      if (audioContextRef.current) {
        audioContextRef.current
          .close()
          .then(() => console.log('Context closed'))
          .catch((err) => console.log(err))
      }

      try {
        recorderRef.current
          ?.stop()
          .getMp3()
          .then(([buffer, blob]) => {
            setIsRecording(false)

            if (buffer.length < 25 || isCancelRequest) return

            getTranscription({
              recordingBlob: blob,
              setIsLoading: setIsLoading,
              callback: async (text) => {
                await handleSubmit(null, text)
              },
              ...(state.language.value !== 'auto' && { language: state.language?.code }),
            })
          })
      } catch (err) {
        console.log(err)

        setIsRecording(false)
        setIsLoading(false)
      } finally {
        setActivationType()
      }
    },
    [handleSubmit, getTranscription, state.language]
  )

  useEffect(() => {
    function handleKeyDown(event) {
      if (event.code === 'Escape') {
        handleStopRecording(true)
        handleStopRequest()
        setIsLoading(false)
      }
      if (event.code !== 'Space' || event.repeat) return
      if (isLoading) return

      setActivationType('space')
      handleStartRecording()
    }
    function handleKeyUp(event) {
      if (event.code !== 'Space') return

      handleStopRecording()
    }

    window.addEventListener('keydown', handleKeyDown)
    window.addEventListener('keyup', handleKeyUp)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
      window.removeEventListener('keyup', handleKeyUp)
    }
  }, [isLoading, handleStopRecording, handleStartRecording, handleStopRequest])

  function handleChangeRecording() {
    if (isGenerating || isLoading) return

    if (isRecording) {
      handleStopRecording()
    } else {
      handleStartRecording()
    }
  }

  const getStatusText = () => {
    if (isRecording) {
      switch (activationType) {
        case 'hold':
          return <T keyName='eleo-chat-voice-status-listening-hold'>Listening...</T>
        case 'space':
          return (
            <>
              <T keyName='eleo-chat-voice-status-listening-space'>
                Listening, release SPACE to send
              </T>
              <div className='hidden justify-center opacity-50 md:flex'>
                <T keyName='eleo-esc-to-abort'>ESC to abort</T>
              </div>
            </>
          )

        default:
          return (
            <>
              <div className='hidden md:block'>
                <T keyName='eleo-chat-voice-status-listening-click'>Listening, click to send</T>
                <div className='hidden justify-center opacity-50 md:flex'>
                  <T keyName='eleo-esc-to-abort'>ESC to abort</T>
                </div>
              </div>

              <div className='block md:hidden'>
                <T keyName='eleo-chat-voice-status-listening-simple'>Listening...</T>
                <div className='flex items-center'>
                  <T
                    keyName='eleo-chat-voice-status-listening-mobile'
                    defaultValue='Tap <mic></mic> to send'
                    params={{
                      mic: (
                        <span className='inline-flex text-[14px]'>
                          <Mic fontSize='inherit' />
                        </span>
                      ),
                    }}
                  ></T>
                </div>
              </div>
            </>
          )
      }
    }

    if (isLoading) {
      return (
        <div className='flex items-center gap-[6px]'>
          <span className='block md:hidden'>
            <Lottie options={defaultOptions} height={24} width={24} />
          </span>
          <div>
            <div className='md:text-center'>
              <T keyName='eleo-chat-voice-status-answering'>Answering</T>
            </div>
            <div className='hidden justify-center opacity-50 md:flex'>
              <T keyName='eleo-click-or-esc-to-abort'>Click or ESC to abort</T>
            </div>
            <div className='flex justify-center md:hidden'>
              <T keyName='eleo-tap-mic-to-abort' params={{ mic: <Mic fontSize='inherit' /> }}></T>
            </div>
          </div>
        </div>
      )
    }

    return (
      <>
        <div className='hidden md:block'>
          <T keyName='eleo-chat-ptt-status-cta'>Click / hold SPACE to talk</T>
          <div className='flex justify-center opacity-50'>
            <T keyName='eleo-esc-to-abort'>ESC to abort</T>
          </div>
        </div>
        <span className=' md:hidden'>
          <T
            keyName='eleo-chat-ptt-status-cta-mobile'
            defaultValue='<div>Tap / hold <mic></mic></div><div>to talk</div>'
            params={{
              mic: (
                <span className='inline-flex text-[14px]'>
                  <Mic fontSize='inherit' />
                </span>
              ),
              div: <div className='flex items-center'></div>,
            }}
          ></T>
        </span>
      </>
    )
  }

  const mouseDownDelayRef = useRef()
  const mouseUpDelayRef = useRef()

  return (
    <AudioInputLayout
      getStatusText={getStatusText}
      isLoading={isLoading}
      isRecording={isRecording}
      speechVolume={speechVolume}
      onMouseDown={() => {
        if (mouseDownDelayRef.current) return
        mouseDownDelayRef.current = true
        setTimeout(() => {
          mouseDownDelayRef.current = false
        }, 500)

        if (isLoading) {
          handleStopRequest()
          handleStartRecording(true)
          setIsLoading(false)

          return
        }
        startMicRef.current = performance.now()

        setActivationType('hold')
        handleChangeRecording()
      }}
      onMouseUp={() => {
        if (mouseUpDelayRef.current) return
        mouseUpDelayRef.current = true
        setTimeout(() => {
          mouseUpDelayRef.current = false
        }, 500)

        if (startMicRef.current && performance.now() - startMicRef.current > 1000) {
          handleStopRecording()
        } else {
          setActivationType('click')
        }
      }}
    />
  )
}
