import { useState, useEffect } from 'react'

export default function usePanelSize(storageKey, breakpoint = 500) {
  const [isLarge, setIsLarge] = useState(
    Number(localStorage.getItem(`${storageKey}-panel-width`)) > breakpoint
  )

  useEffect(() => {
    function listener(e) {
      console.log(e.detail)
      if (e.detail <= breakpoint) setIsLarge((prev) => (prev ? false : prev))
      else if (e.detail > breakpoint) setIsLarge((prev) => (!prev ? true : prev))
    }

    window.addEventListener('onPanelResize', listener)
    return () => window.removeEventListener('onPanelResize', listener)
  }, [breakpoint])

  return isLarge
}
