import { APP_NAME } from 'constants'
import ChatBot from 'views/tools/chat-bot'
import { About } from 'views/website/about'
import { Contact } from 'views/website/contact'
import { Home } from 'views/website/home'
import Partners from 'views/website/partners'
import { Privacy } from 'views/website/privacy'
import { Questions } from 'views/website/questions'
import { Terms } from 'views/website/terms'

const Routes = [
  {
    path: '/',
    view: Home,
    // layout: 'home',
    title: `${APP_NAME}`,
  },
  {
    path: '/chat/:botName',
    view: ChatBot,
    layout: 'chatBot',
    title: 'Chat',
  },
  {
    path: '/contact',
    view: Contact,
    layout: 'home',
    title: 'Contact',
  },
  {
    path: '/questions',
    view: Questions,
    layout: 'home',
    title: 'Questions',
  },
  {
    path: '/about',
    view: About,
    layout: 'home',
    title: 'About',
  },
  {
    path: '/partners',
    view: Partners,
    layout: 'home',
    title: 'Partners',
  },
  {
    path: '/privacy',
    view: Privacy,
    layout: 'home',
    title: 'Privacy Policy',
  },
  {
    path: '/terms',
    view: Terms,
    layout: 'home',
    title: 'Terms & Conditions',
  },
]

export default Routes
