import { Add, AddCircle, Error, Help } from '@mui/icons-material'
import { T, useTranslate } from '@tolgee/react'
import axios from 'axios'
import classNames from 'classnames'
import { addContextToFormData } from 'components/document-context/helper'
import { HelpTooltips } from 'components/help/helpTooltips'
import { useDocumentContext } from 'components/hooks/context'
import { useWindowDimensions } from 'components/hooks/window'
import { Loader, Message, ViewContext } from 'components/lib'
import Tour from 'components/tour'
import { errorToast, successToast } from 'helpers'
import { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { YourStoryAPIService } from 'service/your-story-rest-api.service'
import {
  SelectChosenTemplate,
  SelectIsUnsavedChanges,
  SelectYourStoryText,
  setClearFields,
  setIsUnsavedChanges,
  setSelectedTemplate,
  setSelectedTemplateId,
  setSelectedTemplateName,
  setYourStoryText,
} from 'store/account/yourStory'
import { DEFAULT_MODAL_DATA, Modal } from 'toolComponents/generic/modal'
import { useGetTemplateList } from 'toolComponents/writer/hooks/useGetTemplateList'
import { ListPosition, MuiTooltip } from 'ui'
import StoryActions from './StoryActions'
import { StoryDocs } from './StoryDocs'
import StoryGenerator from './StoryGenerator'

// Tour steps
const steps = [
  {
    target: '#selector',
    content: (
      <T keyName='eleo-tour-story-1'>
        Edit and update Your Story. Choose a previously created Your Story to update information.
      </T>
    ),
  },
  {
    target: '#textbox',
    content: (
      <T keyName='eleo-tour-story-2'>
        Write Your Story. Paste or type information about yourself or your company as text. You can
        use it when creating documents or launching a Chatbot.
      </T>
    ),
  },
  {
    target: '#save',
    content: (
      <T keyName='eleo-tour-story-3'>
        Save changes. You can create multiple different Your Story files.
      </T>
    ),
  },
]

const YourStory = () => {
  const { t } = useTranslate()
  const { width } = useWindowDimensions()
  const dispatch = useDispatch()
  const context = useContext(ViewContext)
  const documentContext = useDocumentContext('yourStory')
  const { templateList, reload, templatesRecordId, templateOptions, templateListLoading } =
    useGetTemplateList('your_story_templates', '/your-story/templates')

  const storyText = useSelector(SelectYourStoryText)
  const [storyTextWordLength, setStoryTextWordLength] = useState(0)
  const selectedStory = useSelector(SelectChosenTemplate)
  const isUnsavedChanges = useSelector(SelectIsUnsavedChanges)

  const [isLoading, setIsLoading] = useState(false)
  const [modalData, setModalData] = useState(DEFAULT_MODAL_DATA)
  const [newStoryName, setNewStoryName] = useState('')
  const [templateName, setTemplateName] = useState(selectedStory?.templateName)
  const [documentsToDelete, setDocumentsToDelete] = useState([])
  // Only for mobile
  const [isShowSelector, setIsShowSelector] = useState(true)

  useEffect(() => {
    if (templateList?.length && !selectedStory && width > 640) {
      handleSelectTemplate({ label: templateList[0].templateName, value: templateList[0].id })
    }
  }, [templateList])

  useEffect(() => {
    setDocumentsToDelete([])

    if (selectedStory) {
      setTemplateName(selectedStory.templateName)
    }
  }, [selectedStory])

  const handleSelectTemplate = (selectedTemplate) => {
    const template = templateList.find((template) => {
      return template.id === selectedTemplate.value
    })

    if (template) {
      dispatch(
        setSelectedTemplate({
          id: template.id,
          templateName: template.templateName,
          storyText: template.storyText ?? '',
          documents: template.documents,
        })
      )

      documentContext.clearContext()
      template.documents.map((doc) =>
        documentContext.addDocument({
          label: doc.name,
          id: doc.document_id,
          type: doc.mimetype === 'url' ? 'website' : 'document',
          isChunk: doc.is_chunk,
        })
      )

      dispatch(setYourStoryText(template.storyText))
      dispatch(setIsUnsavedChanges(false))
      setIsShowSelector(false)
    }
  }

  const handleSaveStoryTemplate = async (name, isDuplicate = false) => {
    if (!templateName.length) {
      errorToast(
        t('eleo-save-template-error-please-point-template-name', 'Please point template name')
      )
      return
    }

    setIsLoading(true)

    let data = new FormData()
    if (templatesRecordId) data.append('templatesRecordId', templatesRecordId)
    if (selectedStory.id) data.append('templateId', selectedStory.id)
    data.append('your_story_templates[templateName]', name ?? selectedStory.templateName)
    data.append('your_story_templates[storyText]', storyText)

    data = await addContextToFormData(data, documentContext.docContext)

    // Only for updating
    for (const document_id of documentsToDelete) {
      data.append('documents_to_delete', document_id)
    }

    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
    }

    try {
      let url = `api/your-story/${selectedStory.id ? 'update' : 'save'}-template`
      if (isDuplicate) url = 'api/your-story/duplicate'

      const res = await axios.post(url, data, config)

      if (!isDuplicate) dispatch(setSelectedTemplateId(res.data.id))

      successToast(t(`eleo-success-${res.data.message}`, res.data.message))
      dispatch(setIsUnsavedChanges(false))
      await reload()
    } catch (error) {
      context.handleError(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleDeleteTemplate = async (templateId) => {
    const template = templateList.find((template) => {
      return template.id === templateId
    })

    setModalData({
      isVisible: true,
      title: (
        <T
          keyName='eleo-your-story-delete-modal-title'
          defaultValue='Delete <span>{storyName}</span>'
          params={{ storyName: template.templateName, span: <span className='italic'></span> }}
        />
      ),
      subtitle: <T keyName='eleo-your-story-delete-modal-subtitle'>This action is irreversible</T>,
      acceptLabel: t('eleo-delete', 'delete'),
      type: 'delete-story',
      context: { storyId: templateId },
    })
  }

  const handleCreateNewStory = async () => {
    setNewStoryName('')
    const displayStoryCreationModal = () =>
      setModalData({
        isVisible: true,
        title: <T keyName='eleo-your-story-create-modal-title' defaultValue='Create Your Story' />,
        acceptLabel: t('eleo-create', 'create'),
        type: 'create',
      })

    if (isUnsavedChanges) {
      displayUnsavedChangesModal({
        secondaryCallback: () => setTimeout(displayStoryCreationModal, 300),
        primaryCallback: async () => {
          await handleSaveStoryTemplate()
          setTimeout(displayStoryCreationModal, 300)
        },
      })
    } else {
      displayStoryCreationModal()
    }
  }

  const displayUnsavedChangesModal = (context) => {
    setModalData({
      isVisible: true,
      title: (
        <T
          keyName='eleo-your-story-unsaved-changes-title'
          defaultValue='You did not save the changes'
        />
      ),
      subtitle: (
        <T keyName='eleo-your-story-unsaved-changes-subtitle'>
          You will lose permamently all applied changes.
        </T>
      ),
      acceptLabel: t('eleo-save-and-continue', 'save and continue'),
      useSecondaryAccept: true,
      secondaryAcceptLabel: t('eleo-discard-and-continue', 'discard and continue'),
      type: 'unsaved-changes',
      context: context,
    })
  }

  const modalContent = {
    create: (
      <div className='space-y-[5px] p-[24px]'>
        <p className='body-small text-brand-gray-dark'>
          {t('eleo-your-story-modal-create-input-label', 'Name')}
        </p>
        <input
          autoFocus
          spellCheck={false}
          value={newStoryName}
          onChange={(e) => setNewStoryName(e.target.value)}
          className='h-[36px] w-full rounded-[4px] border bg-white py-[10px] pl-3 pr-[4px] text-[13px]'
          placeholder=''
        />
      </div>
    ),
    rename: (
      <div className='space-y-[5px] p-[24px]'>
        <p className='body-small text-brand-gray-dark'>
          {t('eleo-your-story-modal-create-input-label', 'Name')}
        </p>
        <input
          autoFocus
          spellCheck={false}
          value={newStoryName}
          onChange={(e) => setNewStoryName(e.target.value)}
          className='h-[36px] w-full rounded-[4px] border bg-white py-[10px] pl-3 pr-[4px] text-[13px]'
          placeholder=''
        />
      </div>
    ),
    duplicate: (
      <div className='space-y-[5px] p-[24px]'>
        <p className='body-small text-brand-gray-dark'>
          {t('eleo-your-story-modal-duplicate-input-label', 'New name')}
        </p>
        <input
          autoFocus
          spellCheck={false}
          value={newStoryName}
          onChange={(e) => setNewStoryName(e.target.value)}
          className='h-[36px] w-full rounded-[4px] border bg-white py-[10px] pl-3 pr-[4px] text-[13px]'
          placeholder=''
        />
      </div>
    ),
  }

  const handleCallback = async (type, context, isSubmitSecondaryEffect) => {
    switch (type) {
      case 'create':
        dispatch(setClearFields())
        documentContext.clearContext()
        dispatch(
          setSelectedTemplate({
            id: undefined,
            templateName: newStoryName,
            storyText: '',
            documents: [],
          })
        )
        setNewStoryName('')
        setIsShowSelector(false)
        dispatch(setIsUnsavedChanges(true))

        break

      case 'rename':
        dispatch(setSelectedTemplateName(newStoryName))
        setNewStoryName('')
        dispatch(setIsUnsavedChanges(true))
        break

      case 'unsaved-changes':
        if (isSubmitSecondaryEffect) context.secondaryCallback?.()
        else context.primaryCallback?.()
        break

      case 'delete-story':
        await YourStoryAPIService.deleteTemplate({
          templateId: context.storyId,
        })
        documentContext.clearContext()
        dispatch(setClearFields())
        dispatch(setIsUnsavedChanges(false))
        reload()
        break

      case 'duplicate':
        await handleSaveStoryTemplate(newStoryName, true)
        break

      default:
        break
    }
  }

  if (!templateList?.length && templateListLoading) return <Loader />

  return (
    <>
      <div className='flex h-full'>
        {/* Selector */}
        <div
          className={classNames(
            'bg-brand-form-accent-light relative flex w-full flex-col sm:w-[200px] md:w-[300px]',
            !isShowSelector && 'hidden sm:flex'
          )}
        >
          <div className='max-h-full overflow-y-auto p-[14px] pb-0'>
            {templateOptions.map((story) => (
              <ListPosition
                key={story.value}
                padding={12}
                height={width > 640 ? 35 : 40}
                text={story.label}
                className={classNames(
                  'max-w-full truncate ',
                  story.value === selectedStory?.id
                    ? 'bg-brand-violet hover:bg-brand-violet !text-white'
                    : 'even:bg-[#5F5FBA05]'
                )}
                onClick={() => {
                  if (isUnsavedChanges) {
                    displayUnsavedChangesModal({
                      secondaryCallback: () => handleSelectTemplate(story),
                      primaryCallback: () => {
                        handleSaveStoryTemplate()
                        handleSelectTemplate(story)
                      },
                    })
                  } else {
                    handleSelectTemplate(story)
                  }
                }}
              />
            ))}
          </div>

          <div className='mt-auto p-2 pt-0 sm:mt-0 sm:p-[14px]'>
            {Boolean(templateOptions.length) && (
              <div className='border-brand-form-accent-light mx-3 my-[6px] border-b' />
            )}
            <ListPosition
              text={<T keyName='eleo-create-your-story'>Create Your Story</T>}
              icon={width > 640 ? <Add fontSize='inherit' /> : <AddCircle fontSize='inherit' />}
              iconPlacement='left'
              height={width > 640 ? 32 : 40}
              className='bg-brand-violet sm:text-brand-violet uppercase text-white sm:bg-transparent sm:normal-case
'
              onClick={handleCreateNewStory}
            />
          </div>
        </div>

        {/* Main page */}
        {selectedStory && (
          <div
            className={classNames(
              'flex h-full flex-1 flex-col overflow-y-auto overflow-x-clip bg-white',
              isShowSelector && 'hidden sm:flex'
            )}
          >
            <div className='p-6'>
              <div className='flex justify-between'>
                <div className='text-brand-violet font-medium'>
                  <T keyName='eleo-edit'>Edit</T>{' '}
                  <span className='italic'>{selectedStory.templateName}</span>
                </div>
                <div className='text-brand-form-accent text-[18px]'>
                  <MuiTooltip
                    enterTouchDelay={0}
                    title={<T keyName='eleo-your-story'>Your Story</T>}
                    content={
                      <T
                        keyName='eleo-your-story-help-content'
                        params={{
                          a: (
                            // eslint-disable-next-line jsx-a11y/anchor-has-content
                            <a
                              href='https://docs.eleo.ai/tools/chat#how-are-words-counted-in-eleo-chat'
                              target='_blank'
                              rel='noreferrer'
                              className='mt-2 block underline'
                            ></a>
                          ),
                        }}
                        defaultValue='Content added to Your Story will be attached to requests sent to Eleo. It will be taken into consideration when generating response. <a>More about Your Story</a>'
                      ></T>
                    }
                    enterDelay={0}
                    leaveDelay={0}
                    offset={[-10, -20]}
                    placement='bottom-end'
                  >
                    <Help fontSize='inherit' />
                  </MuiTooltip>
                </div>
              </div>

              <Message
                className='body-small !mb-0 mt-1 !p-3 text-[#ED7700] text-opacity-80'
                type='warning'
                isFlex
                text={
                  <T
                    keyName='eleo-chatbot-modal-access-warning'
                    params={{
                      strong: <strong className='font-semibold'></strong>,
                      a: (
                        // eslint-disable-next-line jsx-a11y/anchor-has-content
                        <a
                          href='https://docs.eleo.ai/tools/chat#how-are-words-counted-in-eleo-chat'
                          target='_blank'
                          rel='noreferrer'
                          className='underline'
                        ></a>
                      ),
                    }}
                    defaultValue="Attaching Your Story to a tool (chat, writer…) will result in charging it's words to your account <strong>on every request</strong>. <a>Read more</a>"
                  ></T>
                }
                icon={<Error className='float-left mr-[10px]' fontSize='small' />}
              />
            </div>

            <StoryDocs
              documentContext={documentContext}
              setDocumentsToDelete={setDocumentsToDelete}
            />
            <StoryGenerator
              storyText={storyText}
              setWordLength={setStoryTextWordLength}
              wordLength={storyTextWordLength}
            />

            <HelpTooltips tool='yourStory' />
            <Tour steps={steps} name='story' />
            <StoryActions
              selectedStory={selectedStory}
              handleSaveStoryTemplate={handleSaveStoryTemplate}
              documentContext={documentContext}
              handleDeleteTemplate={handleDeleteTemplate}
              setModalData={setModalData}
              setNewStoryName={setNewStoryName}
              setIsShowSelector={setIsShowSelector}
              displayUnsavedChangesModal={displayUnsavedChangesModal}
              isLoading={isLoading}
              handleSelectTemplate={handleSelectTemplate}
            />
          </div>
        )}
      </div>
      <Modal
        isVisible={modalData.isVisible}
        containerClasses='bg-black bg-opacity-[3%]'
        hideModal={() => setModalData(DEFAULT_MODAL_DATA)}
        title={modalData.title}
        subtitle={modalData.subtitle}
        acceptLabel={modalData.acceptLabel}
        content={modalContent[modalData.type]}
        callback={(isSubmitSecondaryEffect) =>
          handleCallback(modalData.type, modalData.context, isSubmitSecondaryEffect)
        }
        isValid={!['create'].includes(modalData.type) || newStoryName?.length}
        useSecondaryAccept={modalData.useSecondaryAccept}
        secondaryAcceptLabel={modalData.secondaryAcceptLabel}
        width='!'
      />
    </>
  )
}

export default YourStory
