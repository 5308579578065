export const transcribeLanguageCodeMap = {
  en: 'en',
  ar: 'ar-AE',
  fr: 'fr-FR',
  es: 'es-ES',
  tr: 'tr-TR',
  ru: 'ru-RU',
  ro: 'ro-RO',
  pt: 'pt-PT',
  pl: 'pl-PL',
  nl: 'nl-NL',
  it: 'it-IT',
  is: 'is-IS',
  fi: 'fi-FI',
  de: 'de-DE',
  yue: 'yue-CN',
  ko: 'ko-KR',
  hi: 'hi-IN',
  arb: 'arb',
  cs: 'cs-CZ',
  da: 'da-DK',
  sv: 'sv-SE',
  ja: 'ja-JP',
  ca: 'ca-ES',
}
