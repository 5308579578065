import { AutoAwesome, ContentCopy, Error, Help } from '@mui/icons-material'
import { T, useTranslate } from '@tolgee/react'
import classNames from 'classnames'
import { useChatbotCategories } from 'components/hooks/chatbotCategories'
import { Message, useNavigate } from 'components/lib'
import { transformSelectOptionValue } from 'helpers'
import {
  CHATBOT_AVAILABILITY_OPTIONS,
  CHATBOT_VISIBILITY_OPTIONS,
  compareChatbotConfigs,
  generateWebsiteCode,
  ignoredFieldPerType,
  transformLinkToOrigin,
} from 'helpers/chatbot'
import { CopyToClipBoard } from 'helpers/clipboard'
import { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  SelectChatBotForumData,
  SelectChatBotInputValues,
  SelectChatBotSettings,
  setChatBotCustomUrlData,
  setChatBotForumData,
  setChatBotHistoryLength,
  setChatBotInputValues,
  setChatBotSettings,
  setSelectedChatBotSettings,
  setSelectedChatBotValues,
} from 'store/account/chatBot'
import { DEFAULT_MODAL_DATA, Modal } from 'toolComponents/generic/modal'
import ImageDropZone from 'toolComponents/image/ImageDropZone'
import { CheckBox, Input, Microbutton, MuiTooltip, MultipleSelect, ReactSelect } from 'ui'
import useGetGroupListsInfo from '../userGroups/hooks/useGetGroupListsInfo'
import ChatPositionSelector from './chatResult/chatPositionSelector'
import { PublishItem } from './publishItem'
import { useSearchParams } from 'react-router-dom'
import { ContextMenu } from 'components/context-menu'

export function ChatbotPublishing({
  isPanelLarge,
  isShowPublishing,
  publishState,
  setPublishState,
  chatbotServerState,
  chatbotDraftServerState,
  handleFileUpload,
  isUpdatePending,
  isDraftSaved,
  handleGenerateChatBot,
}) {
  const { t } = useTranslate()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [params, setParams] = useSearchParams()

  const chatBotSettings = useSelector(SelectChatBotSettings)
  const chatBotForumData = useSelector(SelectChatBotForumData)
  const chatBotInputValues = useSelector(SelectChatBotInputValues)

  const { chatbotCategories } = useChatbotCategories()
  const { handleGetGroupList, groupListOptions } = useGetGroupListsInfo()

  const [embedWebsiteName, setEmbedWebsiteName] = useState('')
  const [modalData, setModalData] = useState(DEFAULT_MODAL_DATA)
  const [embedWebsitesValue, setEmbedWebsitesValue] = useState(chatBotSettings.websites)

  const [savedData, setSavedData] = useState({
    inputValues: chatBotInputValues,
    settings: chatBotSettings,
  })
  const [enhanceData, setEnhanceData] = useState({ short: false, long: false })

  useEffect(() => {
    handleGetGroupList(true)
  }, [])

  useLayoutEffect(() => {
    const requestedModal = params.get('publishing')
    if (requestedModal === 'forum') showForumSettingsModal(true)
    if (requestedModal === 'link') showPublicSettingsModal(true)
    if (requestedModal === 'telegram') showTelegramSettingsModal(true)
    if (requestedModal === 'embed') showEmbedSettingsModal(true)

    // const p = new URLSearchParams(params)
    // p.delete('publishing')
    // setParams(p)
  }, [params, setParams])

  useEffect(() => {
    if (embedWebsitesValue) {
      const values = embedWebsitesValue?.map((website) =>
        transformLinkToOrigin(website?.value || website)
      )
      const filteredValues = values.filter((val) => val)
      const unique = [...new Set(filteredValues)]

      dispatch(setChatBotSettings({ field: 'websites', value: unique }))
    }
  }, [embedWebsitesValue, dispatch])

  const handleDispatchFieldValue = (params) => {
    dispatch(setChatBotInputValues(params))
  }

  const handleSetSelectedGroups = useCallback(() => {
    if (groupListOptions.length && chatBotInputValues.relatedGroups) {
      return groupListOptions.filter((option) =>
        chatBotInputValues.relatedGroups.includes(option.value)
      )
    }
    return []
  }, [groupListOptions, chatBotInputValues])

  const showForumSettingsModal = (requestedModal = false) => {
    setSavedData({
      inputValues: chatBotInputValues,
      settings: chatBotSettings,
    })
    // if (!chatBotForumData?.displayName.length) {
    //   dispatch(setChatBotForumData({ field: 'displayName', value: chatBotInputValues.name }))
    // }

    setModalData({
      isVisible: true,
      title: (
        <T
          keyName='eleo-chatbot-modal-forum-title'
          defaultValue='Publish chatbot {chatbotName} on Chat Forum'
          params={{ chatbotName: chatBotInputValues.name }}
        />
      ),
      subtitle: (
        <T
          keyName='eleo-chatbot-modal-forum-subtitle'
          defaultValue='This data will be displayed on the Chat Forum only.'
        />
      ),
      acceptLabel: requestedModal ? (
        <T keyName='eleo-save-and-publish'>Save and publish</T>
      ) : (
        <T keyName='eleo-save'></T>
      ),
      type: 'forum',
      context: {},
    })
  }

  const showEmbedSettingsModal = (requestedModal = false) => {
    setSavedData({
      inputValues: chatBotInputValues,
      settings: chatBotSettings,
    })

    setModalData({
      isVisible: true,
      title: (
        <T
          keyName='eleo-chatbot-modal-embed-title'
          defaultValue='Publish chatbot {chatbotName} on your website'
          params={{ chatbotName: chatBotInputValues.name }}
        />
      ),
      subtitle: (
        <MuiTooltip
          title={<T keyName='eleo-charges'>Charges</T>}
          content={wordUsageTooltip}
          enterDelay={0}
          leaveDelay={0}
          offset={[-10, -20]}
          placement='bottom-end'
        >
          <div>
            <Message
              className='body-small !mb-0 mt-1 !p-3 font-medium text-[#ED7700]'
              type='warning'
              isFlex
              text={
                <T
                  keyName='eleo-chatbot-modal-access-warning'
                  defaultValue="Attaching Your Story to a tool (chat, writer…) will result in charging it's words to your account <strong>on every request</strong>. <a>Read more</a>"
                  params={{
                    strong: <strong className='font-bold'></strong>,
                    a: (
                      // eslint-disable-next-line jsx-a11y/anchor-has-content
                      <a
                        href='https://docs.eleo.ai/tools/chatbot#webpage-embed'
                        target='_blank'
                        rel='noreferrer'
                        className='underline'
                      ></a>
                    ),
                  }}
                ></T>
              }
              icon={<Error className='float-left mr-[10px]' />}
              secondaryIcon={<Help fontSize='small' className='ml-auto' />}
            />
          </div>
        </MuiTooltip>
      ),
      acceptLabel: requestedModal ? (
        <T keyName='eleo-save-and-publish'>Save and publish</T>
      ) : (
        <T keyName='eleo-save'></T>
      ),
      type: 'embed',
    })
  }

  const showTelegramSettingsModal = (requestedModal = false) => {
    setSavedData({
      inputValues: chatBotInputValues,
      settings: chatBotSettings,
    })

    setModalData({
      isVisible: true,
      title: (
        <T
          keyName='eleo-chatbot-modal-telegram-title'
          defaultValue='Chatbot on Telegram publishing settings'
        />
      ),
      subtitle: (
        <MuiTooltip
          title={<T keyName='eleo-charges'>Charges</T>}
          content={wordUsageTooltip}
          enterDelay={0}
          leaveDelay={0}
          offset={[-10, -20]}
          placement='bottom-end'
        >
          <div>
            <Message
              className='body-small !mb-0 mt-1 !p-3 font-medium text-[#ED7700]'
              type='warning'
              isFlex
              text={
                <T
                  keyName='eleo-chatbot-modal-access-warning'
                  defaultValue="Attaching Your Story to a tool (chat, writer…) will result in charging it's words to your account <strong>on every request</strong>. <a>Read more</a>"
                  params={{
                    strong: <strong className='font-bold'></strong>,
                    a: (
                      // eslint-disable-next-line jsx-a11y/anchor-has-content
                      <a
                        href='https://docs.eleo.ai/tools/chatbot#telegram-bot'
                        target='_blank'
                        rel='noreferrer'
                        className='underline'
                      ></a>
                    ),
                  }}
                ></T>
              }
              icon={<Error className='float-left mr-[10px]' />}
              secondaryIcon={<Help fontSize='small' className='ml-auto' />}
            />
          </div>
        </MuiTooltip>
      ),
      acceptLabel: requestedModal ? (
        <T keyName='eleo-save-and-publish'>Save and publish</T>
      ) : (
        <T keyName='eleo-save'></T>
      ),
      type: 'telegram',
    })
  }

  const showPublicSettingsModal = (requestedModal = false) => {
    setSavedData({
      inputValues: chatBotInputValues,
      settings: chatBotSettings,
    })

    setModalData({
      isVisible: true,
      title: (
        <T
          keyName='eleo-chatbot-modal-public-title'
          defaultValue='Direct link publishing settings'
          params={{ chatbotName: chatBotInputValues.name }}
        />
      ),
      subtitle: (
        <MuiTooltip
          title={<T keyName='eleo-charges'>Charges</T>}
          content={wordUsageTooltip}
          enterDelay={0}
          leaveDelay={0}
          offset={[-10, -20]}
          placement='bottom-end'
        >
          <div>
            <Message
              className='body-small !mb-0 mt-1 !p-3 font-medium text-[#ED7700]'
              type='warning'
              isFlex
              text={
                <T keyName='eleo-chatbot-modal-public-warning'>
                  Words used by this chatbot will be charged to your account. Chatbot will be
                  available for anyone with the link.
                </T>
              }
              icon={<Error className='float-left mr-[10px]' />}
              secondaryIcon={<Help fontSize='small' className='ml-auto' />}
            />
          </div>
        </MuiTooltip>
      ),
      acceptLabel: requestedModal ? (
        <T keyName='eleo-save-and-publish'>Save and publish</T>
      ) : (
        <T keyName='eleo-save'></T>
      ),
      type: 'public',
      context: {},
    })
  }

  const setPublishStateOnActivateForType = (type) => {
    setPublishState((prev) => {
      return {
        ...prev,
        [type]:
          (chatbotDraftServerState?.settings.publishing?.[type] ??
          chatbotServerState?.settings.publishing?.[type])
            ? compareChatbotConfigs(
                chatbotServerState?.inputValues,
                chatBotInputValues,
                ignoredFieldPerType[type]
              )[0]
              ? 'active'
              : 'pending'
            : 'unpublished',
      }
    })
  }

  const wordUsageTooltip = (
    <div className='space-y-[6px]'>
      <div>
        <T keyName='eleo-chatbot-words-tooltip-1'>
          Each question asked by the user and the Chatbot's answer generates a specific number of
          words that will be deducted from your plan.
        </T>
      </div>

      <div>
        <a
          target='_blank'
          rel='noreferrer'
          href='https://docs.eleo.ai/tools/chatbot#how-are-words-calculated-in-eleo-chatbot'
          className='text-brand-violet font-semibold underline opacity-100'
        >
          <T keyName='eleo-chatbot-words-tooltip-2'>How does Eleo count the words?</T>
        </a>
      </div>
      <div>
        <T keyName='eleo-chatbot-words-tooltip-3'>
          After using up the words in your plan, the chatbot will stop chatting and inform your
          users that it is inactive. You will be informed by e-mail about the use of the limit.
        </T>
      </div>
      <div>
        <T keyName='eleo-chatbot-words-tooltip-4'>
          To reactivate the Chatbot you can buy extra words or increase your plan.
        </T>
      </div>
    </div>
  )

  const modalContent = {
    forum: (
      <div className='max-h-[80dvh] w-full overflow-y-auto overflow-x-clip p-[24px]'>
        <div className='space-y-4'>
          <div className='space-y-4'>
            <div className='w-full space-y-[6px]'>
              <div className='body-small text-brand-gray-dark pl-[2px]'>
                <T keyName='eleo-chatbot-visibility'>Who can see this chatbot</T>
              </div>

              <div className='w-full'>
                <ReactSelect
                  options={CHATBOT_VISIBILITY_OPTIONS}
                  setSelectedValue={({ value }) => {
                    handleDispatchFieldValue({ field: 'visibilityOption', value: value })
                  }}
                  controlHeight='auto'
                  controlMinHeight='36px'
                  placeHolder={t('eleo-select-drop-down-placeholder', 'Select')}
                  value={transformSelectOptionValue(
                    CHATBOT_VISIBILITY_OPTIONS,
                    chatBotInputValues.visibilityOption
                  )}
                  menuPlacement='bottom'
                  portalToBody
                />
              </div>
            </div>

            {chatBotInputValues.visibilityOption === 'groups' && (
              <div className='w-full space-y-[6px]'>
                <div className='body-small text-brand-gray-dark pl-[2px]'>
                  <T keyName='eleo-chatbot-user-groups'>Groups allowed to see this chatbot</T>
                </div>

                <div className='flex w-full gap-[10px]'>
                  <ReactSelect
                    options={groupListOptions}
                    setSelectedValue={(selected) => {
                      const selectedIds = selected.data.map((item) => item.value)
                      handleDispatchFieldValue({ field: 'relatedGroups', value: selectedIds })
                    }}
                    isMulti
                    controlHeight='auto'
                    controlMinHeight='36px'
                    placeHolder={t('eleo-select-drop-down-placeholder', 'Select')}
                    value={handleSetSelectedGroups()}
                    menuPlacement='bottom'
                    portalToBody
                  />
                  <Microbutton
                    variant='transparent-light'
                    text={<T keyName='eleo-manage-groups'>Manage groups</T>}
                    onClick={() => {
                      if (isUpdatePending && !isDraftSaved) {
                        setModalData({
                          isVisible: true,
                          title: (
                            <T
                              keyName='eleo-chatbot-modal-unsaved-changes-title'
                              defaultValue='You have unsaved changes'
                            />
                          ),
                          subtitle: (
                            <T
                              keyName='eleo-chatbot-modal-unsaved-changes-subtitle'
                              defaultValue='You will lose permamently all the changes you applied.'
                            />
                          ),
                          acceptLabel: <T keyName='eleo-discard-and-close'>Discard and close</T>,
                          cancelLabel: <T keyName='eleo-back'>Back</T>,
                          type: 'unsaved-changes-groups',
                          context: {},
                        })
                      } else navigate('/account/user-groups')
                    }}
                  />
                </div>
              </div>
            )}
          </div>

          <div className='w-full space-y-[6px]'>
            <div className='body-small text-brand-gray-dark pl-[2px]'>
              <T keyName='eleo-chatbot-availability'>Who can use this chatbot</T>
            </div>

            <div className='w-full'>
              <ReactSelect
                options={CHATBOT_AVAILABILITY_OPTIONS}
                setSelectedValue={({ value }) => {
                  handleDispatchFieldValue({ field: 'availabilityOption', value: value })
                }}
                controlHeight='auto'
                controlMinHeight='36px'
                placeHolder={t('eleo-select-drop-down-placeholder', 'Select')}
                value={transformSelectOptionValue(
                  CHATBOT_AVAILABILITY_OPTIONS,
                  chatBotInputValues.availabilityOption
                )}
                menuPlacement='bottom'
                portalToBody
              />
            </div>
          </div>
        </div>

        <div className='border-brand-form-accent -mx-[24px] my-6 border-b' />

        <div className='space-y-4'>
          {/* Row 1 */}
          {/* <div className='grid grid-cols-2 gap-5'>
             <div className='space-y-[6px]'>
              <div className='body-small text-brand-gray-dark pl-[2px]'>
                <T keyName='eleo-chat-bot-display-name-label'>Display name</T>
              </div>
              <Input
                className='h-[36px] w-full text-[13px]'
                maxLength={30}
                value={chatBotForumData?.displayName}
                placeholder={t('eleo-chat-bot-name-placeholder', 'Chatbot name')}
                onChange={(name) =>
                  dispatch(setChatBotForumData({ field: 'displayName', value: name }))
                }
              />
            </div> 
          </div> */}

          {/* Row 2 */}
          <div className='relative w-full space-y-[6px]'>
            <div className='body-small text-brand-gray-dark pl-[2px]'>
              <T keyName='eleo-chat-bot-short-description-label'>Short description</T>
            </div>
            <Input
              className='placeholder-font-regular h-[50px] w-full rounded-[8px] text-[13px] placeholder-[#36363680]'
              maxLength={40}
              placeholder={t(
                'eleo-chat-bot-short-description-placeholder',
                'Place for the short description'
              )}
              value={chatBotForumData.shortDescription}
              onChange={(shortDescription) =>
                dispatch(
                  setChatBotForumData({ field: 'shortDescription', value: shortDescription })
                )
              }
              isTextArea
              resize='none'
            />

            <Microbutton
              iconPlacement='left'
              variant='transparent-light'
              icon={<AutoAwesome fontSize='18' />}
              text={t('eleo-enhance', 'Enhance')}
              className={classNames('!absolute right-1 top-[26px] !m-0 h-[28px]')}
              onClick={() => setEnhanceData((prev) => ({ ...prev, short: true }))}
            />

            {enhanceData.short && (
              <div className='absolute bottom-0 right-1 z-50  translate-y-full'>
                <ContextMenu
                  noHeader
                  selectedText={`
Chatbot name: ${chatBotInputValues.name}
Current intro: ${chatBotInputValues.intro}
${chatBotInputValues.instructions ? 'Chatbots instructions: ' + chatBotInputValues.instructions : ''}
${chatBotInputValues.tone ? 'Chatbots tone: ' + chatBotInputValues.tone : ''}`}
                  tools={['rephrase']}
                  forceNotSynonyms
                  regenerateUrl='/api/ai/enhance_prompt'
                  type='chatbot-short-description'
                  handleChangeSelectedText={(newText) => {
                    dispatch(setChatBotForumData({ field: 'shortDescription', value: newText }))
                    setEnhanceData((prev) => ({ ...prev, short: false }))
                  }}
                  onClose={() => setEnhanceData((prev) => ({ ...prev, short: false }))}
                />
              </div>
            )}
          </div>

          {/* Row 3 */}
          <div className='relative w-full space-y-[6px]'>
            <div className='body-small text-brand-gray-dark pl-[2px]'>
              <T keyName='eleo-chat-bot-description-label'>Description</T>
            </div>
            <Input
              style={{ fieldSizing: 'content' }}
              className='placeholder-font-regular !max-h-[250px] min-h-[60px] w-full rounded-[8px] pr-[80px] text-[13px] placeholder-[#36363680]'
              placeholder={t('eleo-chat-bot-description-placeholder', 'Place for the description')}
              maxLength={400}
              isTextArea
              value={chatBotForumData.longDescription}
              onChange={(longDescription) =>
                dispatch(setChatBotForumData({ field: 'longDescription', value: longDescription }))
              }
              resize='none'
            />

            <Microbutton
              iconPlacement='left'
              variant='transparent-light'
              icon={<AutoAwesome fontSize='18' />}
              text={t('eleo-enhance', 'Enhance')}
              className={classNames('!absolute right-1 top-[26px] !m-0 h-[28px]')}
              onClick={() => setEnhanceData((prev) => ({ ...prev, long: true }))}
            />

            {enhanceData.long && (
              <div className='absolute right-1 top-0 z-50 -translate-y-[90%]'>
                <ContextMenu
                  noHeader
                  selectedText={`
Chatbot name: ${chatBotInputValues.name}
Current intro: ${chatBotInputValues.intro}
${chatBotInputValues.instructions ? 'Chatbots instructions: ' + chatBotInputValues.instructions : ''}
${chatBotInputValues.shortDescription ? 'Chatbots short description: ' + chatBotInputValues.shortDescription : ''}
${chatBotInputValues.tone ? 'Chatbots tone: ' + chatBotInputValues.tone : ''}`}
                  tools={['rephrase']}
                  forceNotSynonyms
                  regenerateUrl='/api/ai/enhance_prompt'
                  type='chatbot-description'
                  handleChangeSelectedText={(newText) => {
                    dispatch(
                      setChatBotForumData({
                        field: 'longDescription',
                        value: newText.substring(0, 400),
                      })
                    )
                    setEnhanceData((prev) => ({ ...prev, long: false }))
                  }}
                  onClose={() => setEnhanceData((prev) => ({ ...prev, long: false }))}
                />
              </div>
            )}
          </div>

          <div className='space-y-[6px]'>
            <div className='body-small text-brand-gray-dark pl-[2px]'>
              <T keyName='eleo-chat-bot-category-label'>Category</T>
            </div>
            <ReactSelect
              options={chatbotCategories}
              portalToBody
              setSelectedValue={({ value }) =>
                dispatch(setChatBotForumData({ field: 'category', value: value }))
              }
              placeHolder={t('eleo-select-drop-down-placeholder', 'Select')}
              value={transformSelectOptionValue(chatbotCategories, chatBotForumData.category)}
              menuPlacement='top'
              menuAlignment='right'
            />
          </div>

          <div className='space-y-[6px]'>
            <label htmlFor='custom-url-checkbox' className='flex items-center gap-[4px] pl-[2px]'>
              <CheckBox
                id='custom-url-checkbox'
                checkBoxValue={chatBotInputValues.customUrls.forum.enableCustomUrl}
                setChecked={({ checked }) =>
                  dispatch(
                    setChatBotCustomUrlData({
                      type: 'forum',
                      field: 'enableCustomUrl',
                      value: checked,
                    })
                  )
                }
              />
              <span className='body-secondary text-brand-gray-dark select-none'>
                <T keyName='eleo-chatbot-custom-url-label'>Custom link</T>
              </span>
            </label>

            <Input
              className='h-[36px] w-full text-[13px]'
              value={
                `${window.location.origin}/chatbot/chat/` +
                (chatBotInputValues.customUrls.forum.customUrlName || '')
              }
              onChange={(val) => {
                if (!val.startsWith(`${window.location.origin}/chatbot/chat/`)) return

                dispatch(
                  setChatBotCustomUrlData({
                    type: 'forum',
                    field: 'customUrlName',
                    value: val.replace(`${window.location.origin}/chatbot/chat/`, ''),
                  })
                )
              }}
              disabled={!chatBotInputValues.customUrls.forum.enableCustomUrl}
            />
          </div>

          <div className='relative w-full space-y-[6px]'>
            <div className='body-small text-brand-gray-dark pl-[2px]'>
              <MuiTooltip
                title={<T keyName='eleo-tooltip-chat-history-limit-title'>Chat history limit</T>}
                placement='top-start'
                content={
                  <T
                    keyName='eleo-tooltip-chat-history-limit-content'
                    defaultValue='<div>The number of words from the conversation that the chatbot remembers at a given moment.</div> This amount of words is added to the words charged on every user request.'
                    params={{
                      div: <div className='mb-1'></div>,
                    }}
                  ></T>
                }
              >
                <span className='decoration-brand-form-accent underline underline-offset-2'>
                  <T keyName='eleo-chat-bot-chat-history-limit'>Chat history limit</T>
                </span>
              </MuiTooltip>
            </div>
            <div className='flex gap-[10px]'>
              <div className='relative w-full'>
                <Input
                  className='placeholder-font-regular w-full text-[13px] placeholder-[#36363680]'
                  placeholder={t(
                    'eleo-chat-bot-chat-history-limit-placeholder',
                    'History length limit eg. 10000'
                  )}
                  value={chatBotInputValues.maxConversationLength.forum}
                  onChange={(val) => {
                    const positiveNumberRegex = /^(?:0|[1-9]\d*)(?:\.\d+)?$/
                    if (positiveNumberRegex.test(val))
                      dispatch(setChatBotHistoryLength({ type: 'forum', value: Number(val) }))
                  }}
                />

                <div className='body-secondary absolute right-3 top-1/2 -translate-y-1/2'>
                  <T keyName='eleo-words'>words</T>
                </div>
              </div>
              <Microbutton
                variant='transparent-light'
                type='tiny'
                text={<T keyName='eleo-reset-to-default'>Reset to default</T>}
                onClick={() =>
                  dispatch(setChatBotInputValues({ field: 'maxConversationLength', value: 10000 }))
                }
              />
            </div>
          </div>
        </div>
      </div>
    ),
    public: (
      <div className='max-h-[80dvh] w-full overflow-y-auto overflow-x-clip p-[24px]'>
        <div className='space-y-4'>
          <div className='space-y-[6px]'>
            <div className='flex justify-between'>
              <label
                htmlFor='monthly-limit-checkbox'
                className='flex items-center gap-[4px] pl-[2px]'
              >
                <CheckBox
                  id='monthly-limit-checkbox'
                  checkBoxValue={chatBotInputValues.isMonthlyLimit}
                  setChecked={({ checked }) =>
                    handleDispatchFieldValue({ field: 'isMonthlyLimit', value: checked })
                  }
                />
                <span className='body-secondary text-brand-gray-dark select-none'>
                  <T keyName='eleo-chatbot-monthly-limit'>Monthly word limit for chatbot use</T>
                </span>
              </label>

              <MuiTooltip
                title={<T keyName='eleo-tooltip-word-limit-title'>Word limit</T>}
                content={
                  <T
                    keyName='eleo-tooltip-wrod-limit-content'
                    defaultValue='<div>Set the maximum number of words that the Chatbot can use for all conversations
                    in a given month.</div><div>Once the limit is exceeded, the chatbot will stop chatting and
                    inform your users that it is inactive.</div><div> You will be informed by e-mail about the
                    use of the limit.</div>'
                    params={{
                      div: <div className='mb-1'></div>,
                    }}
                  ></T>
                }
              >
                <Help fontSize='small' className='text-brand-accent-flat' />
              </MuiTooltip>
            </div>

            <Input
              className='h-[36px] w-full text-[13px]'
              value={chatBotInputValues.monthlyLimit}
              onChange={(val) => {
                handleDispatchFieldValue({
                  field: 'monthlyLimit',
                  value: val,
                })
              }}
              type='number'
              disabled={!chatBotInputValues.isMonthlyLimit}
            />
          </div>
          <div className='space-y-[6px]'>
            <label htmlFor='custom-url-checkbox' className='flex items-center gap-[4px] pl-[2px]'>
              <CheckBox
                id='custom-url-checkbox'
                checkBoxValue={chatBotInputValues.customUrls.public.enableCustomUrl}
                setChecked={({ checked }) =>
                  // handleDispatchFieldValue({ field: 'enableCustomUrl', value: checked })
                  dispatch(
                    setChatBotCustomUrlData({
                      type: 'public',
                      field: 'enableCustomUrl',
                      value: checked,
                    })
                  )
                }
              />
              <span className='body-secondary text-brand-gray-dark select-none'>
                <T keyName='eleo-chatbot-custom-url-label'>Custom link</T>
              </span>
            </label>

            <Input
              className='h-[36px] w-full text-[13px]'
              value={
                `${window.location.origin}/chat/` +
                (chatBotInputValues.customUrls.public.customUrlName || '')
              }
              onChange={(val) => {
                if (!val.startsWith(`${window.location.origin}/chat/`)) return
                dispatch(
                  setChatBotCustomUrlData({
                    type: 'public',
                    field: 'customUrlName',
                    value: val.replace(`${window.location.origin}/chat/`, ''),
                  })
                )
              }}
              disabled={!chatBotInputValues.customUrls.public.enableCustomUrl}
            />
          </div>

          <div className='relative w-full space-y-[6px]'>
            <div className='body-small text-brand-gray-dark pl-[2px]'>
              <MuiTooltip
                title={<T keyName='eleo-tooltip-chat-history-limit-title'>Chat history limit</T>}
                placement='top-start'
                content={
                  <T
                    keyName='eleo-tooltip-chat-history-limit-content'
                    defaultValue='<div>The number of words from the conversation that the chatbot remembers at a given moment.</div> This amount of words is added to the words charged on every user request.'
                    params={{
                      div: <div className='mb-1'></div>,
                    }}
                  ></T>
                }
              >
                <span className='decoration-brand-form-accent underline underline-offset-2'>
                  <T keyName='eleo-chat-bot-chat-history-limit'>Chat history limit</T>
                </span>
              </MuiTooltip>
            </div>
            <div className='flex gap-[10px]'>
              <div className='relative w-full'>
                <Input
                  className='placeholder-font-regular w-full text-[13px] placeholder-[#36363680]'
                  placeholder={t(
                    'eleo-chat-bot-chat-history-limit-placeholder',
                    'History length limit eg. 10000'
                  )}
                  value={chatBotInputValues.maxConversationLength.public}
                  onChange={(val) => {
                    const positiveNumberRegex = /^(?:0|[1-9]\d*)(?:\.\d+)?$/
                    if (positiveNumberRegex.test(val))
                      dispatch(setChatBotHistoryLength({ type: 'public', value: Number(val) }))
                  }}
                />

                <div className='body-secondary absolute right-3 top-1/2 -translate-y-1/2'>
                  <T keyName='eleo-words'>words</T>
                </div>
              </div>
              <Microbutton
                variant='transparent-light'
                type='tiny'
                text={<T keyName='eleo-reset-to-default'>Reset to default</T>}
                onClick={() =>
                  dispatch(setChatBotInputValues({ field: 'maxConversationLength', value: 10000 }))
                }
              />
            </div>
          </div>
        </div>
      </div>
    ),
    embed: (
      <div className='max-h-[80dvh] w-full overflow-y-auto overflow-x-clip p-[24px]'>
        <div className='space-y-5'>
          {/* Row 1 */}
          <div className='items-start gap-5 space-y-[14px] lg:flex lg:space-y-0'>
            <ImageDropZone
              baseImage={chatBotSettings.chatIcon}
              setBaseImage={(image) =>
                dispatch(setChatBotSettings({ field: 'chatIcon', value: image }))
              }
              handleFileUpload={(e) =>
                handleFileUpload(
                  e,
                  (url) => dispatch(setChatBotSettings({ field: 'chatIcon', value: url })),
                  'chatBot/chatIcon/'
                )
              }
              classes='!size-[130px] !min-w-[130px] !m-0'
              ctaText={
                <T
                  keyName='eleo-upload-chat-icon'
                  defaultValue='Upload<br></br>Chat icon'
                  params={{ br: <br></br> }}
                />
              }
            />

            <div className='flex-1 space-y-[14px]'>
              <div className='space-y-[6px]'>
                <div className='body-small text-brand-gray-dark flex justify-between gap-2 pl-[2px]'>
                  <T keyName='eleo-chat-bot-result-chat-window-width'>Window width</T>
                </div>
                <div className='relative'>
                  <Input
                    className='h-[36px] w-full text-[13px]'
                    placeholder='40px'
                    value={chatBotSettings.windowWidth}
                    onChange={(width) => {
                      if (!Number(width)) return
                      dispatch(setChatBotSettings({ field: 'windowWidth', value: width }))
                    }}
                  />
                  <div className='absolute right-3 top-1/2 -translate-y-1/2 text-[13px] text-gray-400'>
                    px
                  </div>
                </div>
              </div>
              <div className='space-y-[6px]'>
                <div className='body-small text-brand-gray-dark pl-[2px]'>
                  <T keyName='eleo-chat-bot-result-chat-window-height'>Window height</T>
                </div>
                <div className='relative'>
                  <Input
                    className='h-[36px] w-full text-[13px]'
                    placeholder='40px'
                    value={chatBotSettings.windowHeight}
                    onChange={(width) => {
                      if (!Number(width)) return
                      dispatch(setChatBotSettings({ field: 'windowHeight', value: width }))
                    }}
                  />
                  <div className='absolute right-3 top-1/2 -translate-y-1/2 text-[13px] text-gray-400'>
                    px
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Row 2 */}
          <div className='gap-5'>
            <div className='space-y-[6px]'>
              <div className='body-small text-brand-gray-dark pl-[2px]'>
                <T keyName='eleo-chat-bot-icon-place'>icon place</T>
              </div>
              <ChatPositionSelector
                handleDispatchChatSettings={(params) => dispatch(setChatBotSettings(params))}
                initialPosition={chatBotSettings.chatPlacement}
              />
            </div>
          </div>

          {/* Row 3 */}
          <div className='space-y-[6px]'>
            <div className='flex justify-between'>
              <label
                htmlFor='monthly-limit-checkbox'
                className='flex items-center gap-[4px] pl-[2px]'
              >
                <CheckBox
                  id='monthly-limit-checkbox'
                  checkBoxValue={chatBotInputValues.isMonthlyLimit}
                  setChecked={({ checked }) =>
                    handleDispatchFieldValue({ field: 'isMonthlyLimit', value: checked })
                  }
                />
                <span className='body-secondary text-brand-gray-dark select-none'>
                  <T keyName='eleo-chatbot-monthly-limit'>Monthly word limit for chatbot use</T>
                </span>
              </label>
              <MuiTooltip
                title={<T keyName='eleo-tooltip-word-limit-title'>Word limit</T>}
                content={
                  <T
                    keyName='eleo-tooltip-wrod-limit-content'
                    defaultValue='<div>Set the maximum number of words that the Chatbot can use for all conversations
                    in a given month.</div><div>Once the limit is exceeded, the chatbot will stop chatting and
                    inform your users that it is inactive.</div><div> You will be informed by e-mail about the
                    use of the limit.</div>'
                    params={{
                      div: <div className='mb-1'></div>,
                    }}
                  ></T>
                }
              >
                <Help fontSize='small' className='text-brand-accent-flat' />
              </MuiTooltip>
            </div>

            <Input
              className='h-[36px] w-full text-[13px]'
              value={chatBotInputValues.monthlyLimit}
              onChange={(val) => {
                handleDispatchFieldValue({
                  field: 'monthlyLimit',
                  value: val,
                })
              }}
              type='number'
              disabled={!chatBotInputValues.isMonthlyLimit}
            />
          </div>

          {/* Row 4 */}
          <div className='space-y-[6px]'>
            <div className='body-small text-brand-gray-dark pl-[2px]'>
              <T keyName='eleo-chat-bot-code-website-list'>Website URL(s)</T>
            </div>
            <MultipleSelect
              value={chatBotSettings.websites.map((website) => {
                return {
                  label: transformLinkToOrigin(website),
                  value: transformLinkToOrigin(website),
                }
              })}
              setValue={setEmbedWebsitesValue}
              inputValue={embedWebsiteName}
              setInputValue={setEmbedWebsiteName}
              placeHolder={t(
                'eleo-website-urls-placeholder-text',
                'For example https://www.eleo.ai'
              )}
            />
          </div>

          <div className='relative w-full space-y-[6px]'>
            <div className='body-small text-brand-gray-dark pl-[2px]'>
              <MuiTooltip
                title={<T keyName='eleo-tooltip-chat-history-limit-title'>Chat history limit</T>}
                placement='top-start'
                content={
                  <T
                    keyName='eleo-tooltip-chat-history-limit-content'
                    defaultValue='<div>The number of words from the conversation that the chatbot remembers at a given moment.</div> This amount of words is added to the words charged on every user request.'
                    params={{
                      div: <div className='mb-1'></div>,
                    }}
                  ></T>
                }
              >
                <span className='decoration-brand-form-accent underline underline-offset-2'>
                  <T keyName='eleo-chat-bot-chat-history-limit'>Chat history limit</T>
                </span>
              </MuiTooltip>
            </div>
            <div className='flex gap-[10px]'>
              <div className='relative w-full'>
                <Input
                  className='placeholder-font-regular w-full text-[13px] placeholder-[#36363680]'
                  placeholder={t(
                    'eleo-chat-bot-chat-history-limit-placeholder',
                    'History length limit eg. 10000'
                  )}
                  value={chatBotInputValues.maxConversationLength.embed}
                  onChange={(val) => {
                    const positiveNumberRegex = /^(?:0|[1-9]\d*)(?:\.\d+)?$/
                    if (positiveNumberRegex.test(val))
                      dispatch(setChatBotHistoryLength({ type: 'embed', value: Number(val) }))
                  }}
                />

                <div className='body-secondary absolute right-3 top-1/2 -translate-y-1/2'>
                  <T keyName='eleo-words'>words</T>
                </div>
              </div>
              <Microbutton
                variant='transparent-light'
                type='tiny'
                text={<T keyName='eleo-reset-to-default'>Reset to default</T>}
                onClick={() =>
                  dispatch(setChatBotInputValues({ field: 'maxConversationLength', value: 10000 }))
                }
              />
            </div>
          </div>

          <div className='border-brand-form-accent !my-6 -mx-[24px] border-b' />

          {/* Row 5 */}
          <div className='w-full space-y-[6px]'>
            <div className='body-small text-brand-gray-dark pl-[2px] font-semibold'>
              <T keyName='eleo-chat-bot-code-for-the-website'>Code for the website</T>
            </div>
            {/* when publishing chatbotId is null, prevent user to copy widget code, unless they publish bot */}

            {!chatBotInputValues.selectedChatBot ? (
              <Message
                className='body-small !mb-0 mt-1 !p-3 font-medium text-[#ED7700]'
                type='warning'
                isFlex
                text={
                  <div>
                    <T keyName='eleo-chatbot-modal-publish-bot-to-use-widget-code-alert'>
                      Please, publish your chatbot to use widget code
                    </T>
                  </div>
                }
                icon={<Error className='float-left mr-[10px]' />}
              />
            ) : null}

            <div
              className={classNames(
                'bg-brand-form-accent-light border-brand-form-accent relative h-[100px] rounded-[4px] border p-[10px] pr-0 text-[13px]',
                {
                  'pointer-events-none opacity-70': !chatBotInputValues.selectedChatBot,
                }
              )}
            >
              <div className='h-full overflow-y-scroll text-[12px]'>
                <code className='!bg-transparent opacity-90'>
                  {generateWebsiteCode(chatBotSettings, chatBotInputValues.selectedChatBot)}
                </code>
              </div>

              <Microbutton
                variant='solid'
                className='!absolute bottom-2 right-2'
                icon={<ContentCopy fontSize='inherit' />}
                text={<T keyName='eleo-copy-code'>Copy code</T>}
                onClick={() =>
                  CopyToClipBoard(
                    generateWebsiteCode(chatBotSettings, chatBotInputValues.selectedChatBot),
                    null,
                    null
                  )
                }
              />
            </div>
          </div>
        </div>
      </div>
    ),
    telegram: (
      <div className='max-h-[80dvh] w-full overflow-y-auto overflow-x-clip p-[24px]'>
        <div className='space-y-5'>
          {/* Row 1 */}
          <div className='space-y-[6px]'>
            <div className='flex justify-between'>
              <label
                htmlFor='monthly-limit-checkbox'
                className='flex items-center gap-[4px] pl-[2px]'
              >
                <CheckBox
                  id='monthly-limit-checkbox'
                  checkBoxValue={chatBotInputValues.isMonthlyLimit}
                  setChecked={({ checked }) =>
                    handleDispatchFieldValue({ field: 'isMonthlyLimit', value: checked })
                  }
                />
                <span className='body-secondary text-brand-gray-dark select-none'>
                  <T keyName='eleo-chatbot-monthly-limit'>Monthly word limit for chatbot use</T>
                </span>
              </label>
              <MuiTooltip
                title={<T keyName='eleo-tooltip-word-limit-title'>Word limit</T>}
                content={
                  <T
                    keyName='eleo-tooltip-wrod-limit-content'
                    defaultValue='<div>Set the maximum number of words that the Chatbot can use for all conversations
                    in a given month.</div><div>Once the limit is exceeded, the chatbot will stop chatting and
                    inform your users that it is inactive.</div><div> You will be informed by e-mail about the
                    use of the limit.</div>'
                    params={{
                      div: <div className='mb-1'></div>,
                    }}
                  ></T>
                }
              >
                <Help fontSize='small' className='text-brand-accent-flat' />
              </MuiTooltip>
            </div>

            <Input
              className='h-[36px] w-full text-[13px]'
              value={chatBotInputValues.monthlyLimit}
              onChange={(val) => {
                handleDispatchFieldValue({
                  field: 'monthlyLimit',
                  value: val,
                })
              }}
              type='number'
              disabled={!chatBotInputValues.isMonthlyLimit}
            />
          </div>

          {/* Row 2 */}
          <div className='space-y-[6px]'>
            <div className='body-small text-brand-gray-dark pl-[2px]'>
              <T keyName='eleo-chat-bot-telegram-token-label'>Telegram bot token</T>
            </div>
            <Input
              className='h-[36px] w-full text-[13px]'
              placeholder={t('eleo-chat-bot-telegram-placeholder', 'Your Telegram Bot URL')}
              value={chatBotSettings.telegramToken}
              onChange={(val) => {
                dispatch(setChatBotSettings({ field: 'telegramToken', value: val }))
              }}
            />
          </div>
        </div>
      </div>
    ),
  }

  return (
    <>
      <div className='overflow-clip px-[12px] md:-mx-3'>
        <div
          id='publishing'
          className={classNames(
            'overflow-hidden px-1 transition-all duration-500 lg:px-0',
            isShowPublishing
              ? {
                  'mb-5 mt-2': true,
                  // 'lg:h-[194px]': isPanelLarge,
                  // 'md:h-[450px]': !isPanelLarge,
                }
              : 'h-0 -translate-y-full',

            // isPanelLarge ? 'flex flex-wrap gap-[14px]' : 'flex flex-col items-center space-y-[14px]'
            'flex flex-wrap gap-[14px]'
          )}
        >
          <PublishItem
            text={<T keyName='eleo-publishing-forum'>Chat forum</T>}
            state={publishState.forum}
            isActive={chatBotSettings.publishing?.forum}
            handleSettings={() => showForumSettingsModal()}
            setIsActive={(val) => {
              if (val) {
                showForumSettingsModal()
                setPublishStateOnActivateForType('forum')
              } else {
                dispatch(
                  setChatBotSettings({
                    field: 'publishing',
                    value: { ...chatBotSettings.publishing, forum: val },
                  })
                )
              }
            }}
            url={
              chatbotServerState?.settings.publishing?.forum &&
              `${window.location.origin}/chatbot/chat/${chatbotServerState.inputValues?.customUrls?.forum?.enableCustomUrl ? chatbotServerState.inputValues.customUrls.forum?.customUrlName : chatBotInputValues.selectedChatBot}`
            }
          />
          <PublishItem
            text={<T keyName='eleo-publishing-public'>Direct link</T>}
            state={publishState.public}
            isActive={chatBotSettings.publishing?.public}
            handleSettings={() => showPublicSettingsModal()}
            setIsActive={(val) => {
              if (val) {
                showPublicSettingsModal()
                setPublishStateOnActivateForType('public')
              } else {
                dispatch(
                  setChatBotSettings({
                    field: 'publishing',
                    value: { ...chatBotSettings.publishing, public: val },
                  })
                )
              }
            }}
            url={
              chatbotServerState?.settings.publishing?.public &&
              `${window.location.origin}/chat/${chatbotServerState.inputValues?.customUrls?.public?.enableCustomUrl ? chatbotServerState.inputValues.customUrls.public?.customUrlName : chatBotInputValues.selectedChatBot}`
            }
          />
          <PublishItem
            text={<T keyName='eleo-publishing-embed'>Webpage embed</T>}
            state={publishState.embed}
            isActive={chatBotSettings.publishing?.embed}
            handleSettings={() => showEmbedSettingsModal()}
            setIsActive={(val) => {
              if (val) {
                setPublishStateOnActivateForType('embed')
                showEmbedSettingsModal()
              } else {
                dispatch(
                  setChatBotSettings({
                    field: 'publishing',
                    value: { ...chatBotSettings.publishing, embed: val },
                  })
                )
              }
            }}
          />
          <PublishItem
            text={<T keyName='eleo-publishing-telegram'>Telegram bot</T>}
            state={publishState.telegram}
            isActive={chatBotSettings.publishing?.telegram}
            handleSettings={() => showTelegramSettingsModal()}
            setIsActive={(val) => {
              if (val) {
                setPublishStateOnActivateForType('telegram')
                showTelegramSettingsModal()
              } else {
                dispatch(
                  setChatBotSettings({
                    field: 'publishing',
                    value: { ...chatBotSettings.publishing, telegram: val },
                  })
                )
              }
            }}
          />

          {/* Ghost items for flex to work properly */}
          <div className='min-w-[200px] flex-1'></div>
          <div className='min-w-[200px] flex-1'></div>
        </div>
      </div>
      <Modal
        isVisible={modalData.isVisible}
        containerClasses='bg-black bg-opacity-[3%]'
        hideModal={(shouldSave) => {
          // If not saving restore old values
          if (!shouldSave && ['forum', 'public', 'embed', 'telegram'].includes(modalData.type)) {
            dispatch(setSelectedChatBotValues(savedData.inputValues))
            dispatch(setSelectedChatBotSettings(savedData.settings))
          }

          if (shouldSave) {
            dispatch(
              setChatBotSettings({
                field: 'publishing',
                value: { ...chatBotSettings.publishing, [modalData.type]: true },
              })
            )
          }

          if (shouldSave && modalData.type === 'unsaved-changes-groups')
            navigate('/account/user-groups')

          setModalData(DEFAULT_MODAL_DATA)

          // Modal opened via 'edit publishing options' shortcut - close the modal
          if (params.get('publishing')) {
            if (shouldSave) handleGenerateChatBot()

            const p = new URLSearchParams(params)
            p.delete('publishing')
            p.delete('edit')
            setParams(p)
          }
        }}
        title={modalData.title}
        subtitle={modalData.subtitle}
        acceptLabel={modalData.acceptLabel}
        content={modalContent[modalData.type]}
        isValid
        isPrimary
        submitOnEnter={false}
        width={450}
        acceptButtonClasses='grow-[2]'
        disableCloseOnBackdropClick={!params.get('publishing')}
      />
    </>
  )
}
