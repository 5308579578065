import { useState, useEffect, useRef } from 'react'
import { useChatbotCategories } from 'components/hooks/chatbotCategories'
import { T, useTranslate } from '@tolgee/react'
import { Link } from 'react-router-dom'
import { Input } from 'ui'
import { useSearchParams } from 'react-router-dom'
import { ExpandMore, Search } from '@mui/icons-material'
import { Select, MenuItem } from '@mui/material'
import Popover from '@mui/material/Popover'
import classNames from 'classnames'
import CloseIcon from '@mui/icons-material/Close'

//mobile UI specific
const allCategories = {
  label: 'All categories',
  value: 'eleo-chat-library-all-categories',
}

export default function CategoriesSwitch({ filter, setFilter, categoryClassNamesOverrides }) {
  const { t } = useTranslate()
  const { chatbotCategories } = useChatbotCategories()
  const [visibleCount, setVisibleCount] = useState(6)
  const [currentWindowWidth, setCurrentWindowWidth] = useState(0)
  const [anchorEl, setAnchorEl] = useState(null)
  const [params, setParams] = useSearchParams()
  const category = params.get('category')
  const containerRef = useRef(null)
  const [selectedCategory, setSelectedCategory] = useState('eleo-chat-library-all-categories')

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth
      setCurrentWindowWidth(width)
      if (width > 1900) setVisibleCount(7)
      else if (width > 1800 || width > 1600) setVisibleCount(4)
      else if (width > 1400) setVisibleCount(3)
      else if (width > 1200) setVisibleCount(2)
      else if (width > 900) setVisibleCount(1)
      else setVisibleCount(0)
    }

    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const visibleCategories = chatbotCategories.slice(0, visibleCount)
  const hiddenCategories =
    currentWindowWidth <= 1024
      ? [allCategories, ...chatbotCategories.slice(visibleCount)]
      : chatbotCategories.slice(visibleCount)

  const selectedCategoryInHiddenList =
    category && hiddenCategories.find((cat) => cat.value === category)

  const handleCategoryChange = (value) => {
    if (value === category || value === allCategories.value) {
      setParams(
        (prev) => {
          prev.delete('category')
          return prev
        },
        { replace: true }
      )
    } else {
      setParams({ category: value }, { replace: true })
    }
  }

  useEffect(() => {
    if (category) {
      setSelectedCategory(category)
    } else {
      setSelectedCategory('eleo-chat-library-all-categories')
    }
  }, [category])

  return (
    <div
      ref={containerRef}
      className={classNames(
        'border-y-brand-violet-semi_transparent sticky right-0 top-0  z-[99] w-full border-y bg-[#e6e6f4] px-[10px] py-[10px] lg:px-[50px]',
        categoryClassNamesOverrides
      )}
    >
      <div className='flex items-center gap-[20px] lg:gap-[40px]'>
        <div>
          <nav className='flex items-center gap-[10px]'>
            <div className='hidden lg:block'>
              <div
                className={classNames(
                  'flex h-[38px] cursor-pointer items-center whitespace-nowrap rounded-[4px] p-[12px] text-[12px] font-medium leading-[1em] text-[#3F3F86] transition-all duration-300',
                  !category
                    ? 'bg-brand-violet-tertiary text-white'
                    : 'hover:bg-brand-violet-semi_transparent/20'
                )}
                onClick={() => {
                  setParams(
                    (prev) => {
                      prev.delete('category')
                      return prev
                    },
                    { replace: true }
                  )
                }}
              >
                <T keyName={'eleo-chat-library-all-categories'}>All categories</T>
              </div>
            </div>

            {visibleCategories.map((chatbotCategory) => (
              <div key={chatbotCategory.value} className='hidden lg:block'>
                <div
                  aria-current={chatbotCategory.value === category ? 'page' : undefined}
                  className={classNames(
                    'flex h-[38px] cursor-pointer items-center whitespace-nowrap rounded-[4px] p-[12px] text-[12px]  font-medium leading-[1em] text-[#3F3F86] transition-all duration-300',
                    chatbotCategory.value === category
                      ? 'bg-brand-violet-tertiary text-white'
                      : 'hover:bg-brand-violet-semi_transparent/20'
                  )}
                  onClick={() => handleCategoryChange(chatbotCategory.value)}
                >
                  {chatbotCategory.label}
                </div>
              </div>
            ))}

            {hiddenCategories.length > 0 && (
              <>
                <div
                  className={classNames(
                    'hover:bg-brand-violet-semi_transparent/20 flex h-[38px] w-[120px] cursor-pointer items-center  gap-[3px] whitespace-nowrap  rounded-[4px] p-[12px] text-[12px] font-medium leading-[1em] text-[#3F3F86] transition-all duration-300  md:w-[130px] lg:w-auto'
                  )}
                  onClick={(e) => setAnchorEl(e.currentTarget)}
                >
                  {currentWindowWidth > 1024 ? (
                    <span>
                      <T keyName='eleo-chat-library-category-more'>More</T>
                    </span>
                  ) : (
                    <span className='truncate'>{t(`${selectedCategory}`)}</span>
                  )}

                  <ExpandMore sx={{ height: '18px', width: '18px' }} />
                </div>

                <Popover
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  transformOrigin={{ vertical: -10, horizontal: 25 }}
                  onClose={() => setAnchorEl(null)}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                  PaperProps={{
                    style: { boxShadow: 'rgba(0, 0, 0, 0.45) 1px 1px 5px 0px' },
                  }}
                >
                  <div className='py-2'>
                    {hiddenCategories.map((chatbotCategory) => (
                      <div
                        key={chatbotCategory.value}
                        className={classNames(
                          'hover:bg-brand-violet-semi_transparent/20 cursor-pointer px-4 py-2 text-[12px] font-medium',
                          chatbotCategory.value === category
                            ? 'bg-brand-violet-tertiary text-white'
                            : 'text-brand-violet-tertiary'
                        )}
                        onClick={() => {
                          handleCategoryChange(chatbotCategory.value)
                          setAnchorEl(null)
                        }}
                      >
                        {chatbotCategory.label}
                      </div>
                    ))}
                  </div>
                </Popover>
              </>
            )}

            {selectedCategoryInHiddenList && (
              <div className='hidden lg:block'>
                <div
                  className={classNames(
                    'flex h-[38px] cursor-pointer items-center  whitespace-break-spaces rounded-[4px] p-[12px] text-[12px] font-medium leading-[1em] transition-all  duration-300 lg:whitespace-nowrap',
                    'bg-brand-violet-tertiary text-white'
                  )}
                  onClick={() => handleCategoryChange(selectedCategoryInHiddenList.value)}
                >
                  {selectedCategoryInHiddenList.label}
                </div>
              </div>
            )}
          </nav>
        </div>

        {setFilter && (
          <div className='relative w-full min-w-[170px] xl:min-w-[350px] 2xl:min-w-[400px]'>
            <Input
              className={classNames(
                'placeholder:!text-brand-violet-secondary !border-brand-violet-semi_transparent/20   !h-[40px]  w-full truncate rounded-[4px] border !py-[9px] !pl-[12px] pr-[30px] placeholder:!text-[13px] placeholder:!font-normal    placeholder:first-letter:!uppercase',
                {
                  '!bg-brand-violet-tertiary !text-white': filter.length,
                }
              )}
              value={filter}
              placeholder={t('eleo-chatbot-library-search-in', 'Search in {category}', {
                category: t(`${selectedCategory}`),
              })}
              onChange={setFilter}
            />
            {/* <p>
              {t('eleo-chatbot-library-search-in', 'Search in {category}', {
                category: t(`${selectedCategory}`),
              })}
            </p> */}
            <div className='absolute right-[10px] top-[6px]'>
              {filter.length ? (
                <div
                  onClick={() => setFilter('')}
                  className='flex h-[28px] w-[28px] cursor-pointer items-center justify-center rounded-[4px] transition-all duration-300 hover:bg-[#ffffff1a]'
                >
                  <CloseIcon
                    className='cursor-pointer text-white'
                    sx={{ height: '20px', width: '20px' }}
                  />
                </div>
              ) : (
                <Search
                  className='text-brand-violet-secondary'
                  sx={{ height: '20px', width: '20px' }}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
