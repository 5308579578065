import classNames from 'classnames'
import { useLanguages } from 'components/hooks/language'
import { useEffect, useMemo, useState } from 'react'
import ChatTextInput from 'toolComponents/chat/InputFields/Text'
import ChatPushToTalkInput from 'toolComponents/chat/InputFields/audio/PushToTalk'
import ChatVoiceInput from 'toolComponents/chat/InputFields/audio/Voice'
import ChatBotOptionBar from './chatBotOptionBar'

const ChatBotForm = ({
  isGenerating,
  handleAbort,
  isRecording,
  handleSubmit,
  isTranscriptionLoading,
  handleChangeRecording,
  handleStopRequest,
  setMessage,
  message,
  language,
  handleClearChat,
  userCanAccessHistory,
  setHistoryIsVisible,
  isAutoAudio,
  audioStream,
  setTtsIndex,
  setAudioStream,
  setIsAutoAudio,
  setLanguage,
  widget,
  isSearchWeb,
  isSearchWebAllowed,
  setSearchWeb,
  isLanguageChangeAllowed,
  isModeChangeAllowed,
  isContextAllowed,
  documentContext,
  isExternal,
  messages,
  defaultMode,
  isHistoryVisible,
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const { languages } = useLanguages()

  const [inputMode, setInputMode] = useState(defaultMode ?? 'text')

  useEffect(() => {
    if (inputMode === 'text') {
      setIsAutoAudio(false)
    } else {
      setIsAutoAudio(true)
    }
  }, [inputMode, setIsAutoAudio])

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleVolumeOff = () => {
    setIsAutoAudio(false)
    setAudioStream()
    setTtsIndex()
  }

  const textInput = useMemo(
    () => (
      <ChatTextInput
        state={{ language: language, inputValue: message }}
        dispatch={(e) => {
          if (e.type === 'SET_INPUT') {
            setMessage(e.payload)
          }
        }}
        handleSubmit={handleSubmit}
        isGenerating={isGenerating}
        handleStopRequest={handleStopRequest}
        enableEnhance={false}
        isChatbot
      />
    ),
    [language, message, setMessage, handleSubmit, isGenerating, handleStopRequest]
  )

  const pushToTalkInput = useMemo(
    () => (
      <ChatPushToTalkInput
        state={{ language: languages.find((lang) => lang.value === language) ?? language }}
        handleSubmit={handleSubmit}
        isGenerating={isGenerating}
        handleStopRequest={handleStopRequest}
        audioStream={audioStream}
        setAudioStream={setAudioStream}
        isChatbot
      />
    ),
    [
      isGenerating,
      handleSubmit,
      handleStopRequest,
      audioStream,
      setAudioStream,
      language,
      languages,
    ]
  )

  const voiceInput = useMemo(
    () => (
      <ChatVoiceInput
        state={{ language: language }}
        dispatch={(e) => {
          if (e.type === 'SET_INPUT') {
            setMessage(e.payload)
          }
        }}
        handleSubmit={handleSubmit}
        audioStream={audioStream}
        setAudioStream={setAudioStream}
        chatLog={messages}
        isChatbot
      />
    ),
    [handleSubmit, audioStream, setAudioStream, messages, language, setMessage]
  )

  const inputs = {
    text: textInput,
    read: textInput,
    voice: voiceInput,
    push: pushToTalkInput,
  }

  return (
    <div className={classNames(isExternal ? 'bg-brand-grey-bg' : 'bg-brand-violet/10', 'w-full')}>
      <div className='flex w-full flex-col items-center gap-[10px] p-2 py-3 sm:gap-[16px] sm:p-4 md:px-[30px] md:py-[40px] lg:gap-[24px] 2xl:px-[72px] 2xl:py-[40px]'>
        {inputs[inputMode]}

        <ChatBotOptionBar
          language={language}
          languages={languages}
          setLanguage={setLanguage}
          handleClose={handleClose}
          isAutoAudio={isAutoAudio}
          audioStream={audioStream}
          handleVolumeOff={handleVolumeOff}
          setIsAutoAudio={setIsAutoAudio}
          userCanAccessHistory={userCanAccessHistory}
          setHistoryIsVisible={setHistoryIsVisible}
          isHistoryVisible={isHistoryVisible}
          handleClearChat={handleClearChat}
          isSearchWebAllowed={isSearchWebAllowed}
          isSearchWeb={isSearchWeb}
          isContextAllowed={isContextAllowed}
          setSearchWeb={setSearchWeb}
          isLanguageChangeAllowed={isLanguageChangeAllowed}
          documentContext={documentContext}
          isExternal={isExternal}
          inputMode={inputMode}
          setInputMode={setInputMode}
          isModeChangeAllowed={isModeChangeAllowed}
        />
      </div>
    </div>
  )
}

export default ChatBotForm
