/***
 *
 *   UPGRADE
 *   Upgrade from a free to paid plan
 *
 **********/

import { T } from '@tolgee/react'
import {
  Animate,
  AuthContext,
  Card,
  Event,
  PaymentForm,
  ViewContext,
  useNavigate,
  usePlans,
} from 'components/lib'
import { DEFAULT_PAGE } from 'constants'
import { periods } from 'helpers'
import { useContext, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

export function Upgrade(props) {
  const navigate = useNavigate()
  const params = useSearchParams()[0]

  const [period, setPeriod] = useState(params.get('period') ?? 'month')
  const start_date = params.get('start_date')

  // context/data
  const authContext = useContext(AuthContext)
  const context = useContext(ViewContext)
  const data = usePlans()
  let plans = data?.data?.periods?.[period]
  let plan

  // selected plan
  if (plans) {
    plan = params.get('plan')

    // Handle plan passed by name instead of price
    if (!plan?.startsWith('price')) {
      const planPrice = plans.find((p) => p.label.toLowerCase().split(' ')[0] === plan)?.planId
      if (planPrice) plan = planPrice
    }

    // remove free & set selected
    plans = plans.filter((x) => x.value !== 'free')
    plans = plans.map((x) => ({ value: x.planId, label: x.label }))

    plan = plan ? plan : plans[0].value
  }

  function handleChange(data) {
    if (data.input === 'period') setPeriod(data.value)
  }

  return (
    <Animate>
      <Card
        restrictWidth
        title='Upgrade Your Account'
        loading={data.loading}
        className='upgrade-form'
      >
        <PaymentForm
          key={period}
          inputs={{
            start_date: {
              type: 'hidden',
              default: start_date,
            },
            period: {
              label: <T keyName='eleo-period'>Period</T>,
              type: 'select',
              default: periods.find((p) => p.value === period)?.value,
              options: periods,
            },
            plan: {
              label: 'Plan',
              type: 'select',
              default: plan,
              options: plans,
            },
            promo: {
              label: <T keyName='eleo-gift-code'>Gift Code</T>,
              type: 'text',
              placeholder: 'ELEO-PROMO',
            },
            token: {
              label: 'Credit Card',
              type: 'creditcard',
              required: true,
            },
          }}
          url={start_date ? '/api/account/upgrade/future' : '/api/account/upgrade'}
          method='POST'
          buttonText='Upgrade Now'
          callback={(res) => {
            // update the auth context so user can use features on the new plan
            Event('upgraded', { plan: res.data.data.plan })
            if (res.data.data.update) authContext.update({ plan: res.data.data.plan })
            context.updateLimits()

            if (authContext.user?.plan?.name === 'Free' && res.data.data.plan.name !== 'Free')
              window.gtag('event', 'free_plan_upgrade', {
                plan: res.data.data.plan.name,
              })

            setTimeout(() => {
              if (res.data.data.plan.name === 'Team') navigate('/account/users')
              else navigate(DEFAULT_PAGE)
            }, 2000)
          }}
          onChange={handleChange}
        />
      </Card>
    </Animate>
  )
}
