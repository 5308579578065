import { EmojiEvents, Groups, Build, Bolt } from '@mui/icons-material'
import { T } from '@tolgee/react'
import { Button } from 'ui'
import AffilateImg from '../../images/affilate.png'

const features = [
  {
    name: 'Passion and Influence',
    description:
      "By joining the Eleo program, you become part of something bigger. Your influence can transform how people and companies operate, while you gain the reputation of an authority and guide in artificial intelligence. You'll have access to thousands of new topics and conversations igniting millions of minds worldwide.",
    icon: Groups,
    tolgee_id: 'eleo-partners-feature-1',
  },
  {
    name: 'Financial Rewards',
    description:
      'Our program offers exceptional financial terms. You can earn up to 30% of the gross revenue from each new customer who begins using artificial intelligence thanks to you. This is your chance for a stable and attractive income! Eleo services are groundbreaking, offering users immense time savings and new developmental opportunities. With your commitment and the Eleo offering, you can attract thousands of users from any corner of the globe.',
    icon: EmojiEvents,
    tolgee_id: 'eleo-partners-feature-2',
  },
  {
    name: 'Support and Tools',
    description:
      "You won't be on this journey alone. You'll receive a special Code that allows you to track your successes and offers an enticing bonus for your users. Additionally, we provide marketing support and access to tools to effectively promote Eleo services. We don't just speak words into the wind—experience our services, start using them, and witness their power.",
    icon: Build,
    tolgee_id: 'eleo-partners-feature-3',
  },
  {
    name: 'Flexibility and Opportunities',
    description:
      "We're open to collaboration and ready to adapt to your needs. Create your strategies, unleash your creativity, and relish the freedom in achieving goals. We'll support your efforts every step of the way.",
    icon: Bolt,
    tolgee_id: 'eleo-partners-feature-4',
  },
]

const affiliateData = [
  {
    name: 'Reach a Wide Audience.',
    description:
      "Eleo is a versatile application with a broad appeal, making it attractive to a large and diverse audience. By becoming an Eleo affiliate, you have the opportunity to tap into this vast market and acquire a substantial number of customers. You'll be at the forefront of a rapidly expanding field, positioning yourself for success in the AI industry.",
    tolgee_id: 'eleo-partners-affiliate-feature-1',
  },
  {
    name: 'Access to Additional Tools.',
    description:
      "As an Eleo affiliate, you'll gain access to exclusive bonus codes and other compelling offers that you can share with your audience. These additional tools will not only enhance your channels but also drive conversions, ultimately boosting your earning potential as an affiliate.",
    tolgee_id: 'eleo-partners-affiliate-feature-2',
  },
  {
    name: 'Reliable Affiliate Program.',
    description:
      "Eleo's affiliate program is conducted in collaboration with Rewardful, a reputable and independent service. This partnership ensures transparent and dependable payment processing, giving you access to comprehensive statistics and tools to support your efforts effectively.",
    tolgee_id: 'eleo-partners-affiliate-feature-3',
  },
  {
    name: 'Lucrative Commission Structure.',
    description:
      'Eleo offers an exceptionally attractive commission of 30% on the revenue generated by users you refer within 12 months of their initial purchase. This generous compensation plan provides a significant opportunity for you to maximize your earnings as an Eleo affiliate.',
    tolgee_id: 'eleo-partners-affiliate-feature-4',
  },
  {
    name: 'Global Reach.',
    description:
      'Eleo is designed for use in 8 different languages, allowing you to be among the first influencers to offer Eleo in your country. This presents a unique opportunity to establish yourself as a pioneer in promoting Eleo within your local market, positioning you as a leading influencer in the AI space.',
    tolgee_id: 'eleo-partners-affiliate-feature-5',
  },
]

export default function Partners() {
  return (
    <div className='bg-white py-24 sm:py-32'>
      <div className='mx-auto max-w-7xl px-6 lg:px-8'>
        <div className='mx-auto max-w-2xl lg:text-center'>
          <h2 className='text-base font-semibold leading-7 text-indigo-600'>
            <T keyName='eleo-partners-name'>Partners</T>
          </h2>
          <p className='mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl'>
            <T keyName='eleo-partners-title'>
              Join the Eleo Revolution and Become an Authority in Artificial Intelligence!
            </T>
          </p>
          <p className='mt-6 text-lg leading-8 text-gray-600'>
            <T keyName='eleo-partners-description'>
              Do you ever think about stepping onto the stage of a new technological era, where
              artificial intelligence becomes an integral part of business? That's now possible with
              the Eleo Revolution! Here's your chance to be part of a groundbreaking movement that's
              changing the rules of the business game and opening doors for you. Right here, within
              the Eleo program, you have the opportunity to become our foremost authority, achieving
              success and earning while at it. We want you to be our "Ambassador," not just
              recommending and promoting Eleo services, but also contributing to reshaping how
              people engage with technology. What is Eleo? It's the future that's becoming a reality
              today. It's the platform opening doors to using artificial intelligence in everyday
              operations. Through Eleo, individuals and companies gain access to tools that
              revolutionize business processes, expedite decision-making, and shape success.
            </T>
          </p>
        </div>
        <div className='mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl'>
          <dl className='grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16'>
            {features.map((feature) => (
              <div key={feature.name} className='relative pl-16'>
                <dt className='text-base font-semibold leading-7 text-gray-900'>
                  <div className='bg-brand-violet absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg'>
                    <feature.icon className='h-6 w-6 text-white' aria-hidden='true' />
                  </div>
                  <T keyName={`${feature.tolgee_id}-title`}>{feature.name}</T>
                </dt>
                <dd className='mt-2 text-base leading-7 text-gray-600'>
                  <T keyName={`${feature.tolgee_id}-content`}>{feature.description}</T>
                </dd>
              </div>
            ))}
          </dl>
        </div>
        <div className='mx-auto mt-8 max-w-2xl lg:text-center'>
          <p className='text-lg leading-8 text-gray-600'>
            <T keyName='eleo-partners-outro'>
              If you're ready to be part of this extraordinary journey, let us know! We believe your
              commitment can bring not only financial benefits but also the satisfaction of being a
              pioneer in the technological revolution.
            </T>
          </p>
        </div>
        <Affiliate />
      </div>
      <div className='relative isolate overflow-hidden bg-gray-900'>
        <div className='px-6 py-24 sm:px-6 sm:py-32 lg:px-8'>
          <div className='mx-auto max-w-2xl text-center'>
            <h2 className='text-3xl font-bold tracking-tight text-white sm:text-4xl'>
              <T keyName='eleo-affiliate-outro-header'>
                Embrace the potential of AI and elevate your affiliate marketing journey with Eleo.
              </T>
            </h2>
            <p className='mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-300'>
              <T keyName='eleo-affiliate-outro-sub-header'>
                By joining the Eleo Affiliate Program, you'll not only be aligning yourself with a
                cutting-edge AI application but also positioning yourself for success in a rapidly
                evolving industry. With a wide-reaching audience, access to exclusive tools,
                reliable affiliate support, an attractive commission structure, and the opportunity
                to be a trailblazer in your region, becoming an Eleo affiliate is a strategic and
                rewarding decision.
              </T>
            </p>
            <div className='mt-10 flex items-center justify-center gap-x-6'>
              <Button color='violet' href='https://eleo.getrewardful.com/signup' target='_blank'>
                <T keyName='eleo-affiliate-outro-action'>Join Now</T>
              </Button>
            </div>
          </div>
        </div>
        <svg
          viewBox='0 0 1024 1024'
          className='absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)]'
          aria-hidden='true'
        >
          <circle
            cx={512}
            cy={512}
            r={512}
            fill='url(#8d958450-c69f-4251-94bc-4e091a323369)'
            fillOpacity='0.7'
          />
          <defs>
            <radialGradient id='8d958450-c69f-4251-94bc-4e091a323369'>
              <stop stopColor='#7775D6' />
              <stop offset={1} stopColor='#E935C1' />
            </radialGradient>
          </defs>
        </svg>
      </div>
    </div>
  )
}

const Affiliate = () => {
  return (
    <div className='overflow-hidden bg-white py-24 sm:py-32'>
      <div className='mx-auto max-w-7xl md:px-6 lg:px-8'>
        <div className='grid grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:grid-cols-2 lg:items-start'>
          <div className='px-6 lg:px-0 lg:pr-4 lg:pt-4'>
            <div className='mx-auto max-w-2xl lg:mx-0 lg:max-w-lg'>
              <h2 className='text-base font-semibold leading-7 text-indigo-600'>
                <T keyName='eleo-affiliate-sub-header'>Friends of Eleo</T>
              </h2>
              <p className='mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl'>
                <T keyName='eleo-affiliate-header'>Eleo Affiliate Program</T>
              </p>
              <p className='mt-6 text-lg leading-8 text-gray-600'>
                <T keyName='eleo-affiliate-description'>
                  Joining the Eleo Affiliate Program is a smart move for anyone looking to leverage
                  the power of AI and boost their revenue streams. Here's why it's worth becoming an
                  Eleo affiliate.
                </T>
              </p>
              <dl className='mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none'>
                {affiliateData.map((feature) => (
                  <div key={feature.name} className='relative'>
                    <dt className='inline font-semibold text-gray-900'>
                      {/* <feature.icon
                        className="absolute left-1 top-1 h-5 w-5 text-indigo-600"
                        aria-hidden="true"
                      /> */}
                      <T keyName={`${feature.tolgee_id}-name`}>{feature.name}</T>
                    </dt>{' '}
                    <dd className='inline'>
                      <T keyName={`${feature.tolgee_id}-description`}>{feature.description}</T>
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
          <div className='sm:px-6 lg:px-0'>
            <div className='relative isolate overflow-hidden bg-indigo-500 px-6 pt-8 sm:mx-auto sm:max-w-2xl sm:rounded-3xl sm:pl-16 sm:pr-0 sm:pt-16 lg:mx-0 lg:max-w-none'>
              <div
                className='absolute -inset-y-px -left-3 -z-10 w-full origin-bottom-left skew-x-[-30deg] bg-indigo-100 opacity-20 ring-1 ring-inset ring-white'
                aria-hidden='true'
              />
              <div className='mx-auto max-w-2xl sm:mx-0 sm:max-w-none'>
                <img
                  src={AffilateImg}
                  alt='Product screenshot'
                  width={2432}
                  height={1442}
                  className='-mb-12 w-[57rem] max-w-none rounded-tl-xl bg-gray-800 ring-1 ring-white/10'
                />
              </div>
              <div
                className='pointer-events-none absolute inset-0 ring-1 ring-inset ring-black/10 sm:rounded-3xl'
                aria-hidden='true'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
