import classNames from 'classnames'
import React, { useState } from 'react'
import { ChevronLeft, ChevronRight, Construction } from '@mui/icons-material'
import { ResizableContainer } from 'ui'
import { useWindowDimensions } from 'components/hooks/window'

export const ToolLayoutPrimary = ({
  folded,
  setFolded,
  leftSideContent,
  rightSideContent,
  children,
  storageKey,
  breakpoint = 500,
  maxPanelWidth = 750,
  minPanelWidth = 320,
  useTabularLayout = false,
  tabs = {},
}) => {
  const { width } = useWindowDimensions()
  const layoutLarge = width >= 1024
  const panelMaxWidth = Math.min(maxPanelWidth, Math.floor(width * 0.5))
  const [activeTab, setActiveTab] = useState(Object.keys(tabs)?.[0])

  if (!useTabularLayout)
    return (
      <div className='relative h-full'>
        <div className='relative flex h-full flex-wrap overflow-y-scroll lg:flex-nowrap'>
          {layoutLarge ? (
            <ResizableContainer
              isOpen={!folded}
              setIsOpen={(val) => setFolded(!val)}
              storageKey={storageKey}
              minWidth={minPanelWidth}
              initWidth={600}
              maxWidth={panelMaxWidth}
              breakpoint={breakpoint}
            >
              {leftSideContent}
            </ResizableContainer>
          ) : (
            <div
              className={classNames('h-full w-full', {
                'absolute hidden': folded,
              })}
            >
              {leftSideContent}
            </div>
          )}
          <div className={classNames('relative', layoutLarge ? 'flex-1' : 'w-full')}>
            {/* Fold button */}
            {!layoutLarge && (
              <div
                className={classNames(
                  'fold absolute -top-[15px] left-1/2 z-[10] flex h-[50px] w-[16px]  rotate-90 cursor-pointer items-center justify-center rounded-[4px] lg:left-0 lg:top-[50%] lg:rotate-0',
                  {
                    'bg-brand-green': !folded,
                    'bg-[#36363680]': folded,
                  }
                )}
                onClick={() => setFolded((prev) => !prev)}
              >
                {folded ? (
                  <ChevronRight size={22} style={{ color: '#ffffff' }} weight='bold' />
                ) : (
                  <ChevronLeft size={22} style={{ color: '#ffffff' }} weight='bold' />
                )}
              </div>
            )}
            {rightSideContent}
          </div>
          {children}
        </div>
      </div>
    )

  // Tabular layouts

  // Desktop
  if (layoutLarge)
    return (
      <div className='relative h-full'>
        <div className='bg-brand-grey-bg relative flex h-full flex-wrap lg:flex-nowrap'>
          <ResizableContainer
            isOpen={!folded}
            setIsOpen={(val) => setFolded(!val)}
            storageKey={storageKey}
            minWidth={minPanelWidth}
            initWidth={600}
            maxWidth={panelMaxWidth}
            breakpoint={breakpoint}
          >
            {leftSideContent}
          </ResizableContainer>

          <div className='relative flex-1'>{rightSideContent}</div>
        </div>
      </div>
    )

  // Mobile
  return (
    <div className='relative h-full'>
      <div className='flex'>
        {Object.keys(tabs).map((tab) => (
          <div
            key={tab}
            className={classNames(
              activeTab === tab
                ? 'text-brand-violet border-brand-violet-light border-b-[3px] bg-[#F6F6FA] font-medium shadow-[0_0_5px_#00000025]'
                : 'text-brand-violet-light opacity-80',
              ' flex h-[40px] flex-1 items-center justify-center text-[12px] capitalize'
            )}
            onClick={() => setActiveTab(tab)}
          >
            <div>{tabs[tab].title}</div>
          </div>
        ))}
      </div>
      <div className='border-brand-form-accent-light h-[calc(100%-40px)] border-t shadow-[0_0_5px_#00000025]'>
        {tabs[activeTab].component}
      </div>
    </div>
  )
}
