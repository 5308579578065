import { useTranslate, T } from '@tolgee/react'
import DashboardItem from './DashboardItem'
import { useWindowDimensions } from 'components/hooks/window'

import Image from '../../images/dashboard/image.png'
import Write from '../../images/dashboard/writer.png'
import Chat from '../../images/dashboard/chat.png'
import Story from '../../images/dashboard/story.png'
import Translate from '../../images/dashboard/translator.png'
import Chatbot from '../../images/dashboard/chatbot.png'
import Advice from '../../images/dashboard/advice.png'
import Ideas from '../../images/dashboard/idea.png'

const items = [
  {
    name: <T keyName='eleo-dashboard-chat-title'>Chat</T>,
    description: (
      <T keyName='eleo-dashboard-chat-description'>Get any information talking to Eleo</T>
    ),
    image: Chat,
    href: '/chat',
  },
  {
    name: <T keyName='eleo-dashboard-writer-title'>Write</T>,
    description: (
      <T keyName='eleo-dashboard-writer-description'>
        An article, post, description, story, or any kind of text
      </T>
    ),
    image: Write,
    bestOnDesktop: true,
    href: '/writer',
  },
  {
    name: <T keyName='eleo-dashboard-image-title'>Create Images</T>,
    description: (
      <T keyName='eleo-dashboard-image-description'>Create and modify in various styles</T>
    ),
    image: Image,
    href: '/image',
  },
  {
    name: <T keyName='eleo-dashboard-translator-title'>Translate</T>,
    description: (
      <T keyName='eleo-dashboard-translator-description'>Like native speaker, into 50 launguages</T>
    ),
    image: Translate,
    href: '/translator',
  },
  // {
  //   name: <T keyName='eleo-dashboard-advice-title'>Get Advice</T>,
  //   description: (
  //     <T keyName='eleo-dashboard-advice-description'>
  //       In the fields like science, marketing, law, or business
  //     </T>
  //   ),
  //   image: Advice,
  //   bestOnDesktop: true,
  //   href: '/advisor',
  // },
  // {
  //   name: <T keyName='eleo-dashboard-ideas-title'>Generate Ideas</T>,
  //   description: (
  //     <T keyName='eleo-dashboard-ideas-description'>For texts, promotions, products, or services</T>
  //   ),
  //   image: Ideas,
  //   bestOnDesktop: true,
  //   href: '/great-ideas',
  // },
  {
    name: <T keyName='eleo-dashboard-chatbot-title'>Create Chatbot</T>,
    description: (
      <T keyName='eleo-dashboard-chatbot-description'>And share it or put on your website</T>
    ),
    image: Chatbot,
    bestOnDesktop: true,
    href: '/chatbot/library',
    infoTooltip: 'eleo-dashboard-tooltip-chatbot',
  },
  {
    name: <T keyName='eleo-dashboard-story-title'>Create Your Story</T>,
    description: <T keyName='eleo-dashboard-story-description'>And use it as a context anywhere</T>,
    image: Story,
    href: '/account/your-story',
    infoTooltip: 'eleo-dashboard-tooltip-story',
  },
]

export default function DashboardLayout() {
  const { t } = useTranslate()
  const { width } = useWindowDimensions()

  let cols = 1
  if (width >= 466) cols = 2
  if (width >= 768) cols = 3
  if (width >= 1280) cols = 3

  return (
    <div className='space-y-[16px] pb-8 md:space-y-[30px] xl:space-y-[50px]'>
      {/* Main text */}
      <div className='pt-[16px] text-center text-[15px] font-semibold leading-[1.2em] md:pt-[40px] md:text-2xl xl:pt-[50px]'>
        {t('eleo-dashboard-main', 'What do you want to do?')}
      </div>

      {/* Grid */}
      <div
        className='xs:gap-[16px] mx-auto grid justify-center gap-[10px] px-4 md:gap-[20px] xl:gap-[30px]'
        style={{
          gridTemplateColumns: cols > 2 ? `repeat(${cols}, min-content)` : `repeat(${cols}, auto)`,
        }}
      >
        {items.map((item) => (
          <DashboardItem
            key={item.href}
            name={item.name}
            description={item.description}
            image={item.image}
            href={item.href}
            infoTooltip={item.infoTooltip}
            bestOnDesktop={item.bestOnDesktop}
          />
        ))}
      </div>
    </div>
  )
}
