import { T, useTranslate } from '@tolgee/react'
import axios from 'axios'
import classNames from 'classnames'
import { useWindowDimensions } from 'components/hooks/window'
import { Modal } from 'components/lib'
import { successToast } from 'helpers'
import eleoLogo from 'images/eleo_raw.svg'
import { CircleNotch } from 'phosphor-react'
import { useEffect, useState } from 'react'
import { Button, History } from 'ui'

const ChatBotHistory = ({
  chatBotHistoryData,
  historyIsLoading,
  setMessages,
  intro,
  setChatId,
  setShowHistory,
  chatBotId,
  chatId,
  setChatBotHistoryData,
  setAudioStream,
}) => {
  const { t } = useTranslate()
  const { width } = useWindowDimensions()
  const [isLoading, setIsLoading] = useState(false)
  const [isPaginateEnd, setIsPaginateEnd] = useState(true)
  const [modal, setModal] = useState({
    content: null,
    visible: false,
  })
  // return (
  //   <div className='absolute z-30 h-full w-full max-w-[190px] bg-[#ffffff] shadow-lg'>
  //     <div className='h-full overflow-y-auto'>
  //       <div className='border-b-[1px] pb-[10px] pt-[110px] text-center text-lg'>HISTORY</div>
  //       {historyIsLoading ? (
  //         <Loader />
  //       ) : (
  //         <div>
  //           {chatBotHistoryData.length ? (
  //             <>
  //               {chatBotHistoryData.map((history) => (
  //                 <ul
  //                   className='relative border-b-[1px] px-[20px] py-[15px] text-center'
  //                   key={history.chatId}
  //                   onClick={() => {
  //                     setMessages([
  //                       ...(intro && [{ role: 'assistant', content: intro }]),
  //                       ...history.history,
  //                     ])
  //                     setChatId(history.chatId)
  //                   }}
  //                 >
  //                   <span className='absolute -top-[9px] left-[55px] rounded-[20px] border bg-[#ffffff]  px-[5px] text-[11px]'>
  //                     {moment(history.date_created).format('YY.MM.DD HH:mm')}
  //                   </span>
  //                   <li className='cursor-pointer capitalize'>{history.name}</li>
  //                 </ul>
  //               ))}
  //             </>
  //           ) : null}
  //         </div>
  //       )}
  //     </div>
  //   </div>
  // )

  useEffect(() => {
    if (chatBotHistoryData.length && chatBotHistoryData.length % 20 === 0) setIsPaginateEnd(false)
    else setIsPaginateEnd(true)
  }, [chatBotHistoryData.length])

  function loadChat(id) {
    const chatData = chatBotHistoryData.find((item) => item.id === id)
    setMessages([...(intro && [{ role: 'assistant', content: intro }]), ...chatData.history])
    setChatId(chatData.id)
    setAudioStream()

    if (width < 1024) setShowHistory(false)
  }
  function showClearHistoryModal() {
    setModal({
      visible: true,
      content: (
        <>
          {' '}
          <p className='text-brand-body text-[18px] font-medium leading-[1.2em]'>
            <T keyName='eleo-delete-history-confirmation-header'>
              Are you sure you want to clear your history?
            </T>{' '}
            <br />
            <span className='text-brand-pink'>
              <T keyName='eleo-delete-history-confirmation-title'>This action is irreversible.</T>
            </span>
          </p>
          <Button
            className={classNames(
              '!hover:bg-[#FB427C] !float-none h-[45px] !w-full !bg-[#FB427C]',
              {
                'pointer-events-none opacity-60': isLoading,
              }
            )}
            onClick={handleClearHistory}
          >
            <div className='flex items-center gap-[5px]'>
              {isLoading && (
                <CircleNotch weight='fill' color='#FFFFFF' size={24} className='animate-spin' />
              )}
              <p className='!mb-0 uppercase'>
                <T keyName='eleo-clear-history-modal-title'>clear history</T>
              </p>
            </div>
          </Button>
        </>
      ),
    })
  }

  async function handleClearHistory() {
    try {
      setIsLoading(true)
      await axios.post('/api/chat-bot/histories/delete', { chatBotId: chatBotId })

      setChatBotHistoryData([])
      setChatId(null)
      setModal({ visible: false, content: null })
      successToast(
        <T keyName={`eleo-success-Histories deleted successfully`}>
          Histories deleted successfully
        </T>
      )
    } catch (err) {
    } finally {
      setIsLoading(false)
    }
  }

  function showDeleteHistoryItemsModal(items) {
    setModal({
      visible: true,
      content: (
        <>
          <p className='text-brand-body text-[18px] font-medium leading-[1.2em]'>
            <T keyName='eleo-delete-selected-history-confirmation-header'>
              Are you sure you want to delete selected history?{' '}
            </T>{' '}
            <br />
            <span className='text-brand-pink'>
              <T keyName='eleo-delete-selected-history-confirmation-title'>
                This action is irreversible.
              </T>
            </span>
          </p>
          <Button
            className={classNames(
              '!hover:bg-[#FB427C] !float-none h-[45px] !w-full !bg-[#FB427C]',
              {
                'pointer-events-none opacity-60': isLoading,
              }
            )}
            onClick={() => handleDeleteHistoryItems(items)}
          >
            <div className='flex items-center gap-[5px]'>
              {isLoading && (
                <CircleNotch weight='fill' color='#FFFFFF' size={24} className='animate-spin' />
              )}
              <p className='!mb-0 uppercase'>
                <T keyName='eleo-confirm-delete-selected-history'>delete</T>
              </p>
            </div>
          </Button>
        </>
      ),
    })
  }

  async function handleDeleteHistoryItems(items) {
    if (!items.length) return

    try {
      setIsLoading(true)

      const data = { items: items, chatBotId: chatBotId }
      await axios.post('/api/chat-bot/histories/delete/some', data)

      setChatBotHistoryData((prev) => prev.filter((item) => !items.includes(item.id)))
      if (items.includes(chatId)) setChatId(null)
      setModal({ visible: false, content: null })

      successToast(
        <T keyName={`eleo-success-History deleted successfully`}>History deleted successfully</T>
      )
    } catch (err) {
    } finally {
      setIsLoading(false)
    }
  }

  async function handlePaginate() {
    const currentPage = Math.ceil(chatBotHistoryData.length / 20)

    const res = await axios.get(`/api/chats?skip=${currentPage}`)
    setChatBotHistoryData((prev) => [...prev, ...res.data])
  }

  return (
    <div className='w-full border-r lg:w-[320px]'>
      <History
        headerText={t('eleo-history-header-chatbot', 'history')}
        dateText={t('eleo-history-item-date', 'Started:')}
        storyText={t('eleo-history-item-story', 'Your story:')}
        wordCounterText={t('eleo-history-item-words', 'words:')}
        historyEmptyText={t('eleo-tool-history-is-empty', 'history is empty')}
        clearHistoryText={t('eleo-history-header-clear', 'Clear History')}
        storyMissingText={t('eleo-history-item-story-missing', 'None')}
        logo={eleoLogo}
        storageKey='chatbot'
        items={chatBotHistoryData}
        loadItem={loadChat}
        closeHistory={() => setShowHistory(false)}
        showYourStory={false}
        removeItems={showDeleteHistoryItemsModal}
        clearHistory={showClearHistoryModal}
        fetchMoreItems={handlePaginate}
        hasMore={!isPaginateEnd}
      />
      {modal.visible && (
        <Modal
          setHideModal={setModal}
          text={<p className='text-brand-body text-[19px] font-bold'>{modal.text}</p>}
        >
          {modal.content}
        </Modal>
      )}
    </div>
  )
}

export default ChatBotHistory
