import { createSlice } from '@reduxjs/toolkit'

export const yourStoryInitialState = {
  yourStoryText: '',
  selectedTemplate: undefined,
  isUnsavedChanges: false,
}

export const yourStorySlice = createSlice({
  name: 'yourStory',
  initialState: yourStoryInitialState,
  reducers: {
    setYourStoryText: (state, action) => {
      state.yourStoryText = action.payload
    },
    setSelectedTemplate: (state, action) => {
      state.selectedTemplate = action.payload
    },
    setSelectedTemplateName: (state, action) => {
      state.selectedTemplate.templateName = action.payload
    },
    setSelectedTemplateId: (state, action) => {
      state.selectedTemplate.id = action.payload
    },
    setIsUnsavedChanges: (state, action) => {
      state.isUnsavedChanges = action.payload
    },
    setClearFields: () => yourStoryInitialState,
  },
})

// Action creators are generated for each case reducer function
export const {
  setYourStoryText,
  setSelectedTemplate,
  setClearFields,
  setSelectedTemplateName,
  setIsUnsavedChanges,
  setSelectedTemplateId,
} = yourStorySlice.actions

//selectors
export const SelectYourStoryText = (state) => state.yourStory.yourStoryText
export const SelectChosenTemplate = (state) => state.yourStory.selectedTemplate
export const SelectIsUnsavedChanges = (state) => state.yourStory.isUnsavedChanges
// export const replyTextGeneratedResultHistory = (state) =>
//   state.replyText.generatedTextHistory.generatedText

export default yourStorySlice.reducer
