import {
  Add,
  ArticleOutlined,
  Close,
  DescriptionOutlined,
  DesignServicesOutlined,
  Edit,
  Error,
  WebAssetOutlined,
} from '@mui/icons-material'
import { T, useTranslate } from '@tolgee/react'
import classNames from 'classnames'
import { errorToast, isValidUrl } from 'helpers'
import { useState } from 'react'
import AutosizeInput from 'react-input-autosize'
import { useDispatch } from 'react-redux'
import { setIsUnsavedChanges } from 'store/account/yourStory'
import { CheckBox } from 'ui'
import { v4 as uuidv4 } from 'uuid'

const docTypes = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'text/plain',
  'text/csv',
  'audio/mpeg',
]

const iconMap = {
  story: <ArticleOutlined fontSize='inherit' />,
  document: <DescriptionOutlined fontSize='inherit' />,
  template: <DesignServicesOutlined fontSize='inherit' />,
  website: <WebAssetOutlined fontSize='inherit' />,
}

export function StoryDocs({ setDocumentsToDelete, documentContext }) {
  const dispatch = useDispatch()
  const { t } = useTranslate()
  const [newUrlState, setNewUrlState] = useState({ active: false, content: '' })

  async function handleFileUpload(e) {
    e.preventDefault()

    const files = e.target.files
    if (!files.length) return

    for (const file of files) {
      if (!docTypes.includes(file.type)) {
        return errorToast(
          t('eleo-your-story-document-not-supported', 'This document type is not supported')
        )
      }

      documentContext.addDocument({
        label: file.name,
        type: 'document',
        content: file,
        id: uuidv4(),
      })
    }

    // Clear file input
    e.target.value = null
    dispatch(setIsUnsavedChanges(true))
  }

  function submitNewUrl() {
    const tempUrlState = newUrlState
    setNewUrlState({ active: false, content: '' })

    if (!tempUrlState.content.length) return

    const isValid = isValidUrl(tempUrlState.content)
    documentContext.addDocument({
      label: tempUrlState.content,
      type: 'website',
      content: tempUrlState.content,
      id: uuidv4(),
      invalid: !isValid,
    })
    dispatch(setIsUnsavedChanges(true))
  }

  return (
    <>
      <div className='bg-white'>
        {/* Documents */}
        <>
          <input
            id='document-input'
            type='file'
            className='hidden'
            multiple={true}
            accept={docTypes.join(',')}
            onChange={handleFileUpload}
          />
          <div
            className='border-brand-form-accent grid border-y p-[24px]'
            style={{ gridTemplateColumns: '1fr 150px 50px' }}
          >
            <div className='col-span-3 mb-4 grid grid-cols-subgrid items-center justify-between'>
              <div className='space-x-[6px] text-[14px]'>
                <span className='text-brand-violet font-medium'>
                  {t('eleo-context-modal-documents-title', 'Documents')}
                </span>
              </div>
              <div className='flex justify-center space-x-[6px] text-[14px]'>
                {Boolean(documentContext.docContext.length) && (
                  <div className='text-brand-violet decoration-brand-form-accent font-medium'>
                    <T keyName='eleo-attach-entire-file'>Attach entire file</T>
                  </div>
                )}
              </div>
            </div>

            {/* <div className='text-brand-violet col-span-3 text-[13px]'> */}
            {documentContext.docContext
              ?.filter((doc) => doc.type === 'document')
              .map((doc) => (
                <div
                  key={doc.id}
                  title={t(
                    'eleo-context-modal-label-remove-item',
                    'Click X to remove from context'
                  )}
                  className='hover:bg-brand-form-accent-light text-brand-violet group relative col-span-3 grid h-[40px] grid-cols-subgrid items-center gap-x-[10px] rounded-[4px] bg-white pl-3 text-[13px] transition-all duration-300 even:bg-[#5F5FBA05] sm:h-[32px]'
                >
                  <div className='flex items-center gap-[6px]'>
                    <span className='flex text-[14px]'>{iconMap.document}</span>
                    <div className='flex-1 truncate font-medium'>{doc.label}</div>
                  </div>

                  <div className={classNames('flex items-center justify-center gap-1')}>
                    <CheckBox
                      classes='border-brand-violet border-2 !border-opacity-100'
                      id={`chunk-checkbox-${doc.id}`}
                      checkBoxValue={!doc.isChunk}
                      setChecked={() => documentContext.toggleChunkDocument(doc.id)}
                    />
                  </div>

                  {/* Remove document button */}
                  <div className='flex justify-end pr-2'>
                    <div
                      className='hover:bg-brand-form-accent-light bg-brand-violet/10 flex size-[28px] cursor-pointer items-center justify-center rounded-[4px] text-[18px] transition-colors group-hover:opacity-100 sm:size-[26px] sm:bg-transparent sm:opacity-0'
                      onClick={() => {
                        documentContext.removeDocument(doc.id)
                        setDocumentsToDelete((prev) => [...prev, doc.id])
                      }}
                    >
                      <Close fontSize='inherit' />
                    </div>
                  </div>
                </div>
              ))}
            <label htmlFor='document-input' className='col-span-3'>
              <div
                key='add-document'
                title={t('eleo-context-modal-label-remove-item', 'Click X to remove from context')}
                className='hover:sm:bg-brand-form-accent-light sm:text-brand-violet bg-brand-violet flex h-[40px] cursor-pointer items-center gap-x-[10px] rounded-[4px] py-2 pl-3 pr-2 text-[13px] text-white transition-all duration-300 sm:h-[32px] sm:bg-white'
              >
                <span className='flex text-[14px]'>{<Add fontSize='inherit' />}</span>
                <span className='font-medium'>
                  {t('eleo-context-modal-add-document', 'Add document')}
                </span>
                <span className='hidden opacity-50 sm:block'>PDF, DOC, TXT, CSV, MP3</span>
              </div>
            </label>
            {/* </div> */}
          </div>
        </>

        {/* Websites */}
        <>
          <div className='border-brand-form-accent border-b p-[24px]'>
            <div className='mb-4 flex items-center justify-between'>
              <div className='text-brand-violet space-x-[6px] text-[14px] font-medium'>
                {t('eleo-your-story-webpages-title', 'Webpages')}
              </div>
            </div>

            <div className='text-brand-violet text-[13px]'>
              {documentContext.docContext
                ?.filter((doc) => doc.type === 'website')
                .map((doc) => (
                  <div
                    key={doc.id}
                    title={t(
                      'eleo-context-modal-label-remove-item',
                      'Click X to remove from context'
                    )}
                    className='hover:bg-brand-form-accent-light group relative flex h-[40px] items-center gap-x-[10px] rounded-[4px] bg-white py-2 pl-3 pr-2 transition-all duration-300 even:bg-[#5F5FBA05] sm:h-[32px]'
                  >
                    <span className='text-[14px]'>{iconMap.website}</span>
                    {doc.edit ? (
                      <AutosizeInput
                        autoFocus
                        className='truncate'
                        inputClassName='bg-transparent'
                        value={doc.label}
                        onChange={(e) =>
                          documentContext.setDocContext((prev) =>
                            prev.map((item) =>
                              item.id === doc.id
                                ? {
                                    ...item,
                                    content: e.target.value,
                                    label: e.target.value,
                                    invalid: !isValidUrl(e.target.value),
                                  }
                                : item
                            )
                          )
                        }
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' || e.key === 'Escape') {
                            documentContext.toggleEditDocument(doc.id)
                            documentContext.removeEmptyDocuments()
                          }
                        }}
                        onBlur={() => {
                          documentContext.toggleEditDocument(doc.id)
                          documentContext.removeEmptyDocuments()
                        }}
                      />
                    ) : (
                      <div className='truncate font-medium'>{doc.label}</div>
                    )}

                    {doc.invalid && (
                      <div className='text-brand-pink flex items-center gap-1 text-nowrap'>
                        <Error className='text-[14px]' fontSize='inherit' />
                        {t('eleo-context-modal-invalid-url', 'Enter a valid url')}
                      </div>
                    )}

                    <div className='flex-1' />

                    <div className='-my-2 flex gap-1'>
                      {/* Remove document button */}
                      <div
                        className='hover:bg-brand-violet/10 bg-brand-violet/10 flex size-[28px] cursor-pointer items-center justify-center rounded-[4px] text-[18px] transition-colors group-hover:opacity-100 sm:size-[26px] sm:bg-transparent sm:opacity-0'
                        onClick={() => {
                          documentContext.removeDocument(doc.id)
                          setDocumentsToDelete((prev) => [...prev, doc.id])
                        }}
                      >
                        <Close fontSize='inherit' />
                      </div>

                      {/* Edit document button */}
                      <div
                        title={t('eleo-context-modal-label-edit-item', 'Click to edit this url')}
                        className={classNames(
                          'hover:bg-brand-violet/10 bg-brand-violet/10 flex size-[28px] cursor-pointer items-center justify-center rounded-[4px] text-[18px] transition-colors group-hover:opacity-100 sm:size-[26px] sm:bg-transparent sm:opacity-0',
                          doc.edit && 'bg-brand-violet/10 !opacity-100'
                        )}
                        onMouseDown={(e) => {
                          e.preventDefault()
                          documentContext.toggleEditDocument(doc.id)
                          documentContext.removeEmptyDocuments()
                        }}
                      >
                        <Edit fontSize='inherit' />
                      </div>
                    </div>
                  </div>
                ))}

              {/* url input */}
              {newUrlState.active ? (
                <label
                  htmlFor='url-input'
                  className={classNames(
                    'hover:bg-brand-violet/10 group flex h-[40px] items-center gap-x-[10px] truncate rounded-[4px] bg-white py-2 pl-3 pr-2 transition-all duration-300 sm:h-[32px]'
                  )}
                >
                  {iconMap.website}
                  <input
                    id='url-input'
                    autoFocus
                    type='url'
                    placeholder={t(
                      'eleo-context-modal-url-input-placeholder',
                      'Enter webpage address'
                    )}
                    value={newUrlState.content}
                    onChange={(e) => setNewUrlState({ active: true, content: e.target.value })}
                    className='caret-brand-violet w-full bg-transparent'
                    onBlur={submitNewUrl}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') submitNewUrl()
                      if (e.key === 'Escape') setNewUrlState({ active: false, content: '' })
                    }}
                  />

                  {/* Discard input button */}
                  <div
                    className='hover:bg-brand-violet/10 -my-2 flex h-[26px] w-[30px] cursor-pointer items-center justify-center rounded-[4px] text-[18px] opacity-0 transition-colors group-hover:opacity-100'
                    onMouseDown={() => setNewUrlState({ active: false, value: '' })}
                  >
                    <Close fontSize='inherit' />
                  </div>
                </label>
              ) : (
                <div
                  onClick={() => setNewUrlState({ active: true, content: '' })}
                  className='hover:bg-brand-form-accent-light sm:text-brand-violet bg-brand-violet flex h-[40px] cursor-pointer items-center gap-x-[10px] rounded-[4px] py-2 pl-3 pr-2 text-[13px] text-white transition-all duration-300 sm:mt-0 sm:h-[32px] sm:bg-white'
                >
                  <span className='text-[14px]'>{<Add fontSize='inherit' />}</span>
                  <span className='font-medium'>
                    {t('eleo-context-modal-add-webpage', 'Add Webpage')}
                  </span>
                </div>
              )}
            </div>
          </div>
        </>
      </div>
    </>
  )
}
