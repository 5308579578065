export const APP_NAME = 'Eleo.ai'
export const DEFAULT_PAGE = '/dashboard'
export const IMAGE_PROXY_URL = 'https://app.exeria.com/images/'
export const BASE_DIRECTORY = '/app'

export const getBaseUrl = () => {
  if (process.env.REACT_APP_ENV === 'development') {
    return ''
  }
  return '/app'
}
