import { T } from '@tolgee/react'

class AIController {
  supportedTextModels = [
    {
      value: 'gpt-4o-mini',
      label: 'GPT-4o mini',
      supportsImages: true,
      supportsStreaming: true,
      supportsWWW: true,
      isGuru: false,
      isDefault: true,
      description: (
        <T keyName={'eleo-chat-settings-model-description-gpt4o-mini'}>
          Best speed and good results
        </T>
      ),
    },
    {
      value: 'claude-sonnet-3.7',
      label: 'Claude Sonnet 3.7',
      supportsImages: true,
      supportsWWW: true,
      supportsStreaming: true,
      isGuru: true,
      isDefault: false,
      description: (
        <T keyName={'eleo-chat-settings-model-description-sonnet-3.7'}>
          Most sophisticated model by Anthropic. Words count x10
        </T>
      ),
    },
    {
      value: 'claude-sonnet-3.7-reasoning',
      label: 'Claude Sonnet 3.7 Reasoning',
      supportsImages: true,
      supportsWWW: true,
      supportsStreaming: true,
      isGuru: true,
      isDefault: false,
      isReasoning: true,
      description: (
        <T keyName={'eleo-chat-settings-model-description-sonnet-3.7-reasoning'}>
          Most sophisticated model by Anthropic with reasoning capability. Words count x10
        </T>
      ),
      disabledIn: ['translator'],
    },
    {
      value: 'gpt-4o',
      label: 'GPT-4o',
      supportsImages: true,
      supportsWWW: true,
      supportsStreaming: true,
      isGuru: true,
      isDefault: false,
      description: (
        <T keyName={'eleo-chat-settings-model-description-gpt4o'}>
          Most sophisticated model by OpenAI. Words count x10
        </T>
      ),
    },
    {
      value: 'gpt-o1',
      label: 'GPT-o1',
      supportsImages: true,
      supportsWWW: true,
      supportsStreaming: true,
      isGuru: true,
      isDefault: false,
      isReasoning: true,
      description: (
        <T keyName={'eleo-chat-settings-model-description-gpto1'}>
          New reasoning model by OpenAI. Words count x10
        </T>
      ),
      experimental: true,
      availableFrom: 'Pro',
    },
    {
      value: 'gpt-o3-mini',
      label: 'GPT-o3-mini',
      supportsImages: false,
      supportsStreaming: true,
      supportsWWW: true,
      isGuru: true,
      isDefault: false,
      isReasoning: true,
      description: (
        <T keyName={'eleo-chat-settings-model-description-gpto3-mini'}>
          Fast reasoning model by OpenAI. Words count x10
        </T>
      ),
      experimental: true,
      availableFrom: 'Standard',
    },
    {
      value: 'gemini-2.0-flash',
      label: 'Gemini Flash',
      supportsImages: true,
      supportsStreaming: true,
      supportsWWW: true,
      isGuru: true,
      isDefault: false,
      description: (
        <T keyName={'eleo-chat-settings-model-description-gemini-2.0-flash'}>
          Fastest reasoning model by Google.
        </T>
      ),
    },
    {
      value: 'gemini-2.0-flash-lite',
      label: 'Gemini Flash Lite',
      supportsImages: true,
      supportsStreaming: true,
      supportsWWW: true,
      isGuru: true,
      isDefault: false,
      description: (
        <T keyName={'eleo-chat-settings-model-description-gemini-2.0-flash-lite'}>
          Fastest reasoning model by Google.
        </T>
      ),
    },
    {
      value: 'gemini-1.5-pro',
      label: 'Gemini 1.5 Pro',
      supportsImages: true,
      supportsStreaming: true,
      supportsWWW: true,
      isGuru: true,
      isDefault: false,
      description: (
        <T keyName={'eleo-chat-settings-model-description-gemini-1.5-pro'}>
          Best reasoning model by Google. Words count x10
        </T>
      ),
    },
    {
      value: 'sonar',
      label: 'Sonar',
      supportsImages: false,
      supportsStreaming: true,
      supportsWWW: false,
      isGuru: true,
      isDefault: false,
      isOnline: true,
      description: (
        <T keyName={'eleo-chat-settings-model-description-sonar'}>Fast model by Perplexity</T>
      ),
    },
    {
      value: 'sonar-deep-research',
      label: 'Sonar-deep-research',
      supportsImages: false,
      supportsStreaming: true,
      supportsWWW: false,
      isGuru: true,
      isDefault: false,
      isOnline: true,
      isReasoning: true,
      description: (
        <T keyName={'eleo-chat-settings-model-description-sonar-deep-research'}>
          Most advanced model by Perplexity
        </T>
      ),
    },
    {
      value: 'r1',
      label: 'Deepseek r1',
      supportsImages: false,
      supportsStreaming: true,
      supportsWWW: true,
      isGuru: true,
      isDefault: false,
      isReasoning: true,
      description: (
        <T keyName={'eleo-chat-settings-model-description-r1'}>Powerful model hosted in USA</T>
      ),
      disabledIn: ['translator'],
    },
    {
      value: 'bielik',
      label: 'Bielik',
      supportsImages: false,
      supportsStreaming: true,
      supportsWWW: true,
      isGuru: true,
      isDefault: false,
      description: (
        <T keyName={'eleo-chat-settings-model-description-bielik'}>
          Advanced Polish speaking model
        </T>
      ),
    },
  ]

  constructor() {
    if (!AIController.instance) {
      AIController.instance = this
    }

    return AIController.instance
  }

  getSupportedTextModels(options = {}) {
    const {
      includeExperimental = false,
      includeOnline = true,
      includeReasoning = true,
      toolName,
    } = options

    let models = this.supportedTextModels
    if (!includeExperimental) models = models.filter((model) => !model.experimental)
    if (!includeOnline) models = models.filter((model) => !model.isOnline)
    if (!includeReasoning) models = models.filter((model) => !model.isReasoning)
    models = models.filter((model) => !model.disabledIn?.includes(toolName))

    return models
  }

  getDefaultTextModel() {
    return this.supportedTextModels.find((model) => model.isDefault)
  }

  getTextModel(id) {
    return this.supportedTextModels.find((model) => model.value === id)
  }
}

const aiController = new AIController()

export default aiController
