import { Animate, AuthContext } from 'components/lib'
import React, { useContext } from 'react'
import { ChatBotGeneralLayout } from 'toolComponents/chat-bot'

const ChatbotLibraryChatbot = ({
  chatBotName,
  visible,
  setIsVisible,
  setChatIconSizes,
  widget = false,
}) => {
  const userInfo = useContext(AuthContext)

  return (
    <Animate>
      <ChatBotGeneralLayout
        chatBotName={chatBotName}
        widget={widget}
        visible={visible}
        setIsVisible={setIsVisible}
        setChatIconSizes={setChatIconSizes}
        showUserHistory={true}
        url='/api/chat-bot/library/conversation'
        isFromLibrary
        userAccountId={userInfo?.user?.account_id}
      />
    </Animate>
  )
}

export default ChatbotLibraryChatbot
