import React from 'react'

const FilledPieLoader = ({ progress = 0, size = 100, fillColor = 'rgb(82 82 158)' }) => {
  const center = size / 2
  const radius = size / 2
  const progressRadians = (progress / 100) * 2 * Math.PI

  // Start at 12 o'clock (270 degrees in standard position)
  const startAngle = -Math.PI / 2
  // End angle (counter-clockwise from start)
  const endAngle = startAngle - progressRadians

  // Arc flag (0 for minor arc, 1 for major arc)
  const largeArcFlag = progress > 50 ? 1 : 0

  // Calculate start and end points
  const startX = center + radius * Math.cos(startAngle)
  const startY = center + radius * Math.sin(startAngle)

  const endX = center + radius * Math.cos(endAngle)
  const endY = center + radius * Math.sin(endAngle)

  // SVG path
  let path = ''
  if (progress === 0) {
    path = ''
  } else if (progress === 100) {
    path = `M ${center},${center} L ${startX},${startY} A ${radius},${radius} 0 1,0 ${startX - 0.001},${startY} Z`
  } else {
    path = `M ${center},${center} L ${startX},${startY} A ${radius},${radius} 0 ${largeArcFlag},0 ${endX},${endY} Z`
  }

  return (
    <svg className='h-full w-full' viewBox={`0 0 ${size} ${size}`}>
      {progress === 100 && <circle cx={center} cy={center} r={radius} fill={fillColor} />}
      {progress > 0 && <path d={path} fill={fillColor} />}
    </svg>
  )
}

export default FilledPieLoader
