import { useEffect, useMemo, useState } from 'react'
import ChatTextInput from './InputFields/Text'
import { OptionsBar } from './OptionsBar'
import ChatPushToTalkInput from './InputFields/audio/PushToTalk'
import ChatVoiceInput from './InputFields/audio/Voice'

export function ChatForm({
  isGenerating,
  handleSubmit,
  state,
  dispatch,
  handleClearContext,
  documentContext,
  audioStream,
  setAudioStream,
  handleStopRequest,
  messageContext,
  isHistoryVisible,
  width,
  setTtsIndex,
  setShowWebCam,
  chatLog,
}) {
  const [inputMode, setInputMode] = useState('text')

  useEffect(() => {
    if (inputMode === 'text') {
      dispatch({ type: 'SET_AUTO_AUDIO', payload: false })
    } else {
      dispatch({ type: 'SET_AUTO_AUDIO', payload: true })
    }
  }, [inputMode, dispatch])

  const textInput = useMemo(
    () => (
      <ChatTextInput
        state={state}
        dispatch={dispatch}
        handleSubmit={handleSubmit}
        messageContext={messageContext}
        isGenerating={isGenerating}
        setShowWebCam={setShowWebCam}
        handleStopRequest={handleStopRequest}
      />
    ),
    [state, dispatch, handleSubmit, messageContext, isGenerating, setShowWebCam, handleStopRequest]
  )

  const pushToTalkInput = useMemo(
    () => (
      <ChatPushToTalkInput
        state={state}
        handleSubmit={handleSubmit}
        isGenerating={isGenerating}
        handleStopRequest={handleStopRequest}
        audioStream={audioStream}
        setAudioStream={setAudioStream}
      />
    ),
    [state, isGenerating, handleSubmit, handleStopRequest, audioStream, setAudioStream]
  )

  const voiceInput = useMemo(
    () => (
      <ChatVoiceInput
        dispatch={dispatch}
        handleSubmit={handleSubmit}
        audioStream={audioStream}
        setAudioStream={setAudioStream}
        chatLog={chatLog}
        state={state}
      />
    ),
    [dispatch, handleSubmit, audioStream, setAudioStream, chatLog, state]
  )

  const inputs = {
    text: textInput,
    read: textInput,
    voice: voiceInput,
    push: pushToTalkInput,
  }

  return (
    <>
      <div className='bg-brand-violet/10 border-brand-form-accent w-full border-t'>
        <div className='relative flex w-full flex-col items-center gap-[10px] p-2 py-3 sm:gap-[16px] sm:p-4 md:px-[30px] md:py-[30px] lg:gap-[24px] 2xl:px-[72px] 2xl:py-[40px]'>
          {/* Input window */}
          {inputs[inputMode]}
          {/* Options bar */}
          <OptionsBar
            state={state}
            dispatch={dispatch}
            documentContext={documentContext}
            handleClearContext={handleClearContext}
            width={width}
            isHistoryVisible={isHistoryVisible}
            setAudioStream={setAudioStream}
            audioStream={audioStream}
            setTtsIndex={setTtsIndex}
            inputMode={inputMode}
            setInputMode={setInputMode}
          />
        </div>
      </div>
    </>
  )
}
