import { Fragment, useState, useContext, useEffect, useRef } from 'react'
import { ViewContext, Card, Form, Icon, Button } from 'components/lib'
import Style from './feedback.tailwind.js'
import { APP_NAME } from 'constants.js'
import { T, useTranslate } from '@tolgee/react'
import classNames from 'classnames'
import { successToast } from 'helpers/index.js'

export function Feedback({
  setIsVisible,
  isVisible,
  setIsFeedbackModalInCompactMode,
  isFeedBackModalInCompactMode = false,
  positionClasses = '',
}) {
  const { t } = useTranslate()
  const [rating, setRating] = useState(null)
  const [textAreaValue, setTextAreaValue] = useState('')
  const feedbackRef = useRef(null)

  // maps
  const icons = ['smile', 'meh', 'frown']
  const ratings = ['positive', 'neutral', 'negative']
  const bgColors = ['#00C29B', '#FFDA39', '#FB427C']

  function saveRating(selectedRating) {
    setRating((prevRating) => (prevRating === selectedRating ? null : selectedRating))
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (feedbackRef.current && !feedbackRef.current.contains(event.target)) {
        setIsVisible(false)
        setIsFeedbackModalInCompactMode(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [feedbackRef])

  if (!isVisible) return null

  return (
    <section ref={feedbackRef}>
      <div
        className={classNames(
          'fixed inset-0 z-[999] bg-black/50',
          !isFeedBackModalInCompactMode ? 'md:hidden' : ''
        )}
        onClick={() => {
          setIsFeedbackModalInCompactMode(false)
          setIsVisible(false)
        }}
      />
      <div
        className={classNames(
          'fixed  left-1/2 top-1/2 z-[999] w-full max-w-[284px] -translate-x-1/2 -translate-y-1/2 rounded-[4px] bg-white drop-shadow-md ',
          !isFeedBackModalInCompactMode &&
            (positionClasses ? positionClasses : 'lg:bottom-2 lg:left-2 lg:right-2'),
          !isFeedBackModalInCompactMode ? 'lg:left-auto lg:top-auto lg:transform-none' : ''
        )}
      >
        <div style={{ boxShadow: 'rgba(0, 0, 0, 0.4) 0px 0px 20px 0px' }} className='rounded-[4px]'>
          <Card className='!m-0 !rounded-[4px] !border-none !bg-[#00000008] !p-0'>
            <Fragment>
              <section className='px-[14px] pb-[14px] pt-[21px]'>
                <div className='flex flex-col items-center justify-center gap-[11px]'>
                  <h2 className='text-brand-violet-tertiary  text-[14px] font-medium leading-[1.1em]'>
                    <T keyName='eleo-feedback-header-text'>Enjoying Eleo.ai?</T>
                  </h2>
                  <div className='flex gap-[6px]'>
                    {icons.map((icon, index) => {
                      const isSelected = rating === index
                      return (
                        <div
                          key={index}
                          style={{
                            backgroundColor: isSelected
                              ? bgColors[index]
                              : 'rgba(128, 90, 213, 0.1)',
                          }}
                          className={classNames(
                            ' flex h-[50px] w-[50px] cursor-pointer items-center justify-center rounded-[4px] transition duration-300 ease-in-out',
                            !isSelected ? 'hover:!bg-brand-violet-tertiary/20' : 'hover:opacity-80'
                          )}
                          onClick={() => saveRating(index)}
                        >
                          <Icon
                            image={icon}
                            style={{
                              color: isSelected ? 'white' : '#805AD5',
                            }}
                            className={classNames(
                              'h-[24px] w-[24px]',
                              isSelected && rating !== 1
                                ? 'text-white'
                                : 'text-brand-violet-tertiary'
                            )}
                          />
                        </div>
                      )
                    })}
                  </div>
                </div>
              </section>
              <hr />
              <Form
                inputs={{
                  rating: {
                    type: 'hidden',
                    value: ratings[rating],
                  },
                  comment: {
                    label: (
                      <p className='first-letter:capitalize'>
                        <T keyName='eleo-feedback-tell-us-more'>
                          Tell us more about your experience
                        </T>
                      </p>
                    ),
                    type: 'textarea',
                    class:
                      '!min-h-[90px] !max-h-[500px] !rounded-[4px] !text-[13px] !text-brand-body',
                    value: textAreaValue,
                  },
                }}
                method='POST'
                url='/api/feedback'
                updateOnChange
                loadingClasses='after:!top-[12px]'
                inputLabelClass='!text-[12px] !leading-[1.2em] !lowercase !text-brand-gray-dark'
                className='p-[14px]'
                buttonText={<T keyName='eleo-feedback-send-feedback'>Send Feedback</T>}
                btnClasses='!rounded-[4px] !bg-brand-violet-tertiary uppercase hover:!bg-brand-violet-secondary !leading-[1.2em] !text-[13px]'
                onChange={(e) => {
                  setTextAreaValue(e.value)
                  return false
                }}
                callback={(e) => {
                  setRating(null)
                  setIsVisible(false)
                  setTextAreaValue('')
                  successToast(
                    t('eleo-feedback-success', 'Thank you, your feedback is most appreciated.')
                  )
                }}
              />
            </Fragment>
          </Card>
        </div>
      </div>
    </section>
  )
}
