import { T, useTranslate } from '@tolgee/react'
import classNames from 'classnames'
import { FieldDescription } from 'components/field-description'
import { useLanguages } from 'components/hooks/language'
import usePanelSize from 'components/hooks/panelSize'
import { transformSelectOptionValue } from 'helpers'
import aiController from 'helpers/AIController'
import { ButtonGroup, Input, ReactSelect } from 'ui'
import { CheckForInputData, Creativity } from '../writer-options-data'

export default function CheckTextOptions({
  visible,
  dispatch,
  actionTypes,
  setWordLength,
  setMyText,
  setCreativity,
  creativity,
  optionsData,
  isGuruModeEnabled,
  setIsGuruModeEnabled,
  model,
  setModel,
}) {
  const handleTopicChange = ({ value, name }) => {
    dispatch({ type: actionTypes[`SET_${name}`], payload: value })
  }

  const isPanelLarge = usePanelSize('check-text')
  const { t } = useTranslate()

  const { defaultToolOutPutLanguage, languages } = useLanguages()

  return (
    visible && (
      <div
        className={classNames(
          'relative break-words pb-6',
          isPanelLarge
            ? 'grid-cols-1 gap-[14px] space-y-4 lg:grid lg:grid-cols-2 lg:gap-4 lg:space-y-0'
            : 'space-y-4'
        )}
      >
        {/* My text */}
        <div
          id='my-text'
          className='col-span-2 space-y-[6px]'
          data-tooltip-id='writer-checkText-myText'
        >
          <FieldDescription>
            <T keyName='eleo-tool-modify-text-my-text'>My Text</T>
          </FieldDescription>
          <Input
            className='placeholder-font-regular min-h-[480px] w-full rounded-[8px] text-[13px] placeholder-[#36363680]'
            onChange={setMyText}
            value={optionsData.myText}
            placeholder={t('eleo-tool-check-text-my-text-placeholder', 'Start typing here')}
            isTextArea
            resize='none'
          />
        </div>

        {/* What to check */}
        <div
          className='space-y-[6px]'
          id='what-to-check'
          data-tooltip-id='writer-checkText-checkFor'
        >
          <FieldDescription>
            <T keyName='eleo-tool-check-text-check-for'>Check For</T>
          </FieldDescription>
          <ReactSelect
            options={CheckForInputData}
            placeHolder={t('eleo-select-drop-down-placeholder', 'Select')}
            value={transformSelectOptionValue(
              CheckForInputData,
              optionsData.state.check,
              CheckForInputData[0]
            )}
            setSelectedValue={handleTopicChange}
            name='CHECK'
            menuPlacement='top'
          />
        </div>

        {/* Language */}
        <div id='language' className='space-y-[6px]' data-tooltip-id='writer-checkText-language'>
          <FieldDescription>
            <T keyName='eleo-write-text-language'>Language</T>
          </FieldDescription>
          <ReactSelect
            options={languages}
            setSelectedValue={handleTopicChange}
            value={transformSelectOptionValue(
              languages,
              optionsData.state.language,
              defaultToolOutPutLanguage
            )}
            name='LANGUAGE'
            menuPlacement='top'
            menuAlignment='right'
          />
        </div>

        {/* Creativity */}
        <div
          className='space-y-[6px]'
          id='creativity'
          data-tooltip-id='writer-checkText-creativity'
        >
          <FieldDescription>
            <T keyName='eleo-write-text-creativity'>Creativity</T>
          </FieldDescription>
          <div className='w-full'>
            <ButtonGroup
              options={Creativity}
              onClick={(event) => setCreativity(event.target.value)}
              value={creativity}
              activeBgClass='bg-[#6363e51a]'
              className='!h-[36px] w-full'
            />
          </div>
        </div>

        <div className='space-y-[6px]'>
          <FieldDescription>
            <T keyName='eleo-chat-settings-model'>Model</T>
          </FieldDescription>

          <div className='h-[36px]'>
            <ReactSelect
              options={aiController.getSupportedTextModels({
                includeReasoning: false,
                includeOnline: false,
              })}
              setSelectedValue={({ value }) => {
                setModel({
                  ...aiController.getSupportedTextModels().find((model) => model.value === value),
                  description: undefined,
                })
              }}
              value={model}
              menuPlacement='top'
              menuAlignment='right'
            />
          </div>
        </div>
      </div>
    )
  )
}
