import { Close } from '@mui/icons-material'
import { useTranslate } from '@tolgee/react'
import classNames from 'classnames'
import { useEffect, useRef, useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import { Microbutton } from 'ui'
import './modal.scss'

export const DEFAULT_MODAL_DATA = {
  isVisible: false,
  title: '',
  acceptLabel: '',
  cancelLabel: '',
  type: '',
  context: {},
}

export function Modal({
  hideModal,
  containerClasses,
  isVisible,
  title,
  subtitle,
  content,
  acceptLabel,
  cancelLabel,
  callback,
  isValid,
  isPrimary = true, // Color scheme
  submitOnEnter = true,
  disableBorder = false,
  width = 280,
  acceptButtonClasses,
  disableCloseOnBackdropClick = false,
  useSecondaryAccept = false,
  secondaryAcceptLabel,
}) {
  const transitionRef = useRef()
  const { t } = useTranslate()
  const [isLoading, setIsLoading] = useState(false)

  const handleCloseModal = (e) => {
    e.stopPropagation()
    e.target === e.currentTarget && hideModal(false)
  }

  const handleSubmitModal = async (isSubmitSecondaryEffect = false) => {
    setIsLoading(true)
    try {
      await callback?.(isSubmitSecondaryEffect)
    } finally {
      setIsLoading(false)
      hideModal(true)
    }
  }

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter' && isVisible && isValid && !isLoading) {
        handleSubmitModal()
      }
    }

    if (submitOnEnter) document.addEventListener('keydown', handleKeyPress)

    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
  }, [isVisible, isValid, isLoading])

  if (!isVisible) return

  return (
    <CSSTransition nodeRef={transitionRef} in appear timeout={0} classNames='modal'>
      <div
        ref={transitionRef}
        className='modal visible'
        onClick={(e) => !disableCloseOnBackdropClick && handleCloseModal(e)}
      >
        <div
          className={classNames(
            'modal-content',
            'flex max-h-[90dvh] !max-w-[95dvw] rounded-[4px] bg-white lg:!w-[500px] lg:max-w-none'
          )}
          style={{ width: `${width}px` }}
        >
          <div className={classNames(containerClasses, 'flex w-full flex-col')}>
            {/* Header */}
            <div
              className={classNames(
                'py-[14px] pl-[24px] pr-3',
                disableBorder ? 'pb-0' : 'border-b'
              )}
            >
              <div className='flex items-center justify-between'>
                <div
                  className={classNames(
                    'text-[14px] font-medium',
                    isPrimary && 'text-brand-violet'
                  )}
                >
                  {title}
                </div>
                <Microbutton
                  variant='plain'
                  icon={<Close fontSize='inherit' />}
                  className={classNames(
                    'bg-transparent hover:bg-black/10',
                    isPrimary && 'text-brand-violet'
                  )}
                  onClick={() => hideModal(false)}
                />
              </div>
              {subtitle && (
                <div
                  className={classNames('body-small opacity-60', isPrimary && 'text-brand-violet')}
                >
                  {subtitle}
                </div>
              )}
            </div>
            {/* Content */}
            {content}
            {/* Footer */}
            <div
              className={classNames(
                'flex flex-wrap items-center justify-center gap-[10px] px-[24px] py-[14px] md:flex-nowrap',
                content && 'border-t'
              )}
            >
              <Microbutton
                variant='transparent-light'
                type='large-font'
                text={cancelLabel ?? t('eleo-cancel', 'cancel')}
                className={classNames(
                  'h-[40px] flex-1 uppercase',
                  !isPrimary && '!text-brand-gray-dark bg-brand-gray-light'
                )}
                textClasses='!font-bold'
                onClick={() => hideModal(false)}
                disabled={isLoading}
              />
              {useSecondaryAccept && secondaryAcceptLabel && (
                <Microbutton
                  variant='transparent-light'
                  type='large-font'
                  disabled={!isValid}
                  text={secondaryAcceptLabel ?? t('eleo-save', 'save')}
                  className={classNames(
                    'h-[40px] flex-1  uppercase',
                    !isValid && 'opacity-80',
                    !isPrimary && '!text-brand-gray-dark bg-brand-gray-light',
                    acceptButtonClasses
                  )}
                  textClasses='!font-bold'
                  isLoading={isLoading}
                  onClick={() => handleSubmitModal(true)}
                />
              )}
              {acceptLabel && (
                <Microbutton
                  disabled={!isValid}
                  variant='solid'
                  type='large-font'
                  text={acceptLabel ?? t('eleo-save', 'save')}
                  className={classNames(
                    'h-[40px] flex-1  uppercase',
                    !isValid && 'opacity-80',
                    !isPrimary && '!text-brand-gray-dark bg-brand-gray-light',
                    acceptButtonClasses
                  )}
                  textClasses='!font-bold'
                  isLoading={isLoading}
                  onClick={() => handleSubmitModal(false)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  )
}
