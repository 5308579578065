import { Article, Close, Description, Download, WebAsset } from '@mui/icons-material'
import { CircularProgress } from '@mui/material'
import classNames from 'classnames'
import { Loader } from 'components/lib'
import { downloadFile } from 'helpers/image'
import { Microbutton } from 'ui'

const iconMap = {
  story: <Article fontSize='inherit' />,
  document: <Description fontSize='inherit' />,
  website: <WebAsset fontSize='inherit' />,
}

const fileTypeMap = {
  'application/pdf': 'pdf',
  'application/msword': 'doc',
  'text/plain': 'txt',
  'text/csv': 'csv',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
  'image/png': 'png',
  'image/jpg': 'jpg',
  'image/webp': 'webp',
}

export function MessageContextItem({
  item,
  messageContext,
  isEditable = true,
  setImagePreviewData,
}) {
  return item.type === 'image' ? (
    <div className='relative h-[40px]'>
      {item.content ? (
        <>
          <img
            alt={item.label}
            src={item.content}
            className='h-[40px] cursor-pointer rounded-[4px] object-contain'
            onClick={() =>
              setImagePreviewData?.({
                target: item.content,
                isMobile: false,
                isVisible: true,
              })
            }
          />
          {isEditable && (
            <Microbutton
              variant='plain'
              iconPlacement='left'
              icon={<Close fontSize='inherit' />}
              className={classNames(
                'absolute bottom-1/2 left-1/2 size-[28px] -translate-x-1/2 -translate-y-1/2 text-transparent hover:bg-[#6363E6] hover:text-white'
              )}
              onClick={() => messageContext.removeDocument(item.id)}
            />
          )}
        </>
      ) : (
        <div className='flex size-[40px] items-center justify-center'>
          <CircularProgress
            disableShrink
            size={24}
            sx={{ animationDuration: '1000ms' }}
            color='inherit'
          />
        </div>
      )}
    </div>
  ) : (
    <div
      title={item.label}
      className='text-brand-text-secondary group relative flex h-[40px] max-w-[100px] items-center gap-[6px] rounded-[4px] bg-black/5 p-2 pr-3 text-[12px]'
    >
      <div className='flex text-[24px]'>{iconMap[item.type]}</div>

      <div className='truncate'>
        <div className='min-w-0 max-w-[130px] truncate'>{item.label}</div>
        <div className='min-w-0 max-w-[130px] text-[11px] font-light uppercase opacity-50'>
          {fileTypeMap[item.metadata?.type] ?? ''}
        </div>
      </div>
      {isEditable && (
        <div className='text-brand-violet bg-brand-gray-light absolute right-[6px] flex rounded-[8px] opacity-0 shadow-sm transition-all group-hover:opacity-100'>
          {['image', 'document'].includes(item.type) && (
            <Microbutton
              variant='plain'
              iconPlacement='left'
              icon={<Download fontSize='inherit' />}
              className='flex rounded-r-none !p-1 hover:bg-[#6363E6] hover:text-white'
              onClick={() => downloadFile(item.content)}
            />
          )}
          <Microbutton
            variant='plain'
            iconPlacement='left'
            icon={<Close fontSize='inherit' />}
            className={classNames(
              '!p-1 hover:bg-[#6363E6] hover:text-white',
              ['image', 'document'].includes(item.type) && 'rounded-l-none'
            )}
            onClick={() => messageContext.removeDocument(item.id)}
          />
        </div>
      )}
    </div>
  )
}
