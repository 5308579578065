import axios from 'axios'
import { ViewContext } from 'components/lib'
import { useContext, useCallback } from 'react'
const CommonBiasFilter = [
  'Transcribed by https://otter.ai',
  'MBC 뉴스 이덕영입니다.',
  'Silence.',
  'Acesse https://www.youtube.com.br',
  'Obrigado por assistir!',
  'www.opusdei.pt',
  'www.mooji.org',
  'Ondertitels ingediend door de Amara.org gemeenschap',
  'Ondertiteld door de Amara.org gemeenschap',
  'Ondertiteling door de Amara.org gemeenschap',
  'Untertitelung aufgrund der Amara.org-Community',
  'Untertitel im Auftrag des ZDF für funk, 2017',
  'Untertitel von Stephanie Geiges',
  'Untertitel der Amara.org-Community',
  'Untertitel im Auftrag des ZDF, 2017',
  'Untertitel im Auftrag des ZDF, 2020',
  'Untertitel im Auftrag des ZDF, 2018',
  'Untertitel im Auftrag des ZDF, 2021',
  'Untertitelung im Auftrag des ZDF, 2021',
  'Copyright WDR 2021',
  'Copyright WDR 2020',
  'Copyright WDR 2019',
  'SWR 2021',
  'SWR 2020',
  "Sous-titres réalisés para la communauté d'Amara.org",
  "Sous-titres réalisés par la communauté d'Amara.org",
  'Sous-titres fait par Sous-titres par Amara.org',
  "Sous-titres réalisés par les SousTitres d'Amara.org",
  'Sous-titres par Amara.org',
  "Sous-titres par la communauté d'Amara.org",
  "Sous-titres réalisés pour la communauté d'Amara.org",
  "Sous-titres réalisés par la communauté de l'Amara.org",
  "Sous-Titres faits par la communauté d'Amara.org",
  "Sous-titres par l'Amara.org",
  "Sous-titres fait par la communauté d'Amara.org",
  "Sous-titrage ST' 501",
  "Sous-titrage ST'501",
  "Cliquez-vous sur les sous-titres et abonnez-vous à la chaîne d'Amara.org",
  '❤️ par SousTitreur.com',
  'Sottotitoli creati dalla comunità Amara.org',
  'Sottotitoli di Sottotitoli di Amara.org',
  'Sottotitoli e revisione al canale di Amara.org',
  'Sottotitoli e revisione a cura di Amara.org',
  'Sottotitoli e revisione a cura di QTSS',
  'Sottotitoli e revisione a cura di QTSS.',
  'Sottotitoli a cura di QTSS',
  'Subtítulos realizados por la comunidad de Amara.org',
  'Subtitulado por la comunidad de Amara.org',
  'Subtítulos por la comunidad de Amara.org',
  'Subtítulos creados por la comunidad de Amara.org',
  'Subtítulos en español de Amara.org',
  'Subtítulos hechos por la comunidad de Amara.org',
  'Subtitulos por la comunidad de Amara.org',
  'Más información www.alimmenta.com',
  'www.mooji.org',
  'Subtítulos realizados por la comunidad de Amara.org',
  'Legendas pela comunidade Amara.org',
  'Legendas pela comunidade de Amara.org',
  'Legendas pela comunidade do Amara.org',
  'Legendas pela comunidade das Amara.org',
  'Transcrição e Legendas pela comunidade de Amara.org',
  'Sottotitoli creati dalla comunità Amara.org',
  "Sous-titres réalisés para la communauté d'Amara.org",
  "Sous-titres réalisés para la communauté d'Amara.org",
  'Napisy stworzone przez społeczność Amara.org',
  'Napisy wykonane przez społeczność Amara.org',
  'Zdjęcia i napisy stworzone przez społeczność Amara.org',
  'napisy stworzone przez społeczność Amara.org',
  'Tłumaczenie i napisy stworzone przez społeczność Amara.org',
  'Napisy stworzone przez społeczności Amara.org',
  'Tłumaczenie stworzone przez społeczność Amara.org',
  'Napisy robione przez społeczność Amara.org',
  'www.multi-moto.eu',
  'Редактор субтитров А.Синецкая Корректор А.Егорова',
  'Yorumlarınızıza abone olmayı unutmayın.',
  'Sottotitoli creati dalla comunità Amara.org',
  '字幕由Amara.org社区提供',
  '小編字幕由Amara.org社區提供',
]

export function useGetTranscription(isChatBot = false) {
  const context = useContext(ViewContext)

  const getTransctiption = useCallback(
    async ({ recordingBlob, callback, setIsLoading, language }) => {
      setIsLoading(true)

      let uploadData = new FormData()
      uploadData.append('file', recordingBlob, 'audio.mp3')
      if (language) uploadData.append('language', language)

      const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
        signal: AbortSignal.timeout(10000), // Timeout after 10s
      }

      try {
        const res = await axios.post(
          isChatBot ? '/api/chat-bot/transcribe' : '/api/utility/transcribe',
          uploadData,
          config
        )

        const text = res.data.transcription?.text
        if (!text?.length || CommonBiasFilter.includes(text.trim())) return

        callback && (await callback(res.data.transcription?.text))
      } catch (err) {
        context.handleError(err)
      } finally {
        setIsLoading(false)
      }
    },
    []
  )

  return getTransctiption
}
