import { MoreVert } from '@mui/icons-material'
import { Popover } from '@mui/material'
import { T, useTranslate } from '@tolgee/react'
import { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import {
  SelectIsUnsavedChanges,
  setClearFields,
  setIsUnsavedChanges,
} from 'store/account/yourStory'
import { ListPosition, Microbutton } from 'ui'

const StoryActions = ({
  selectedStory,
  handleSaveStoryTemplate,
  documentContext,
  handleDeleteTemplate,
  setModalData,
  setNewStoryName,
  setIsShowSelector,
  displayUnsavedChangesModal,
  isLoading,
  handleSelectTemplate,
}) => {
  const { t } = useTranslate()
  const dispatch = useDispatch()
  const isUnsavedChanges = useSelector(SelectIsUnsavedChanges)

  const popoverRef = useRef()

  const [anchorEl, setAnchorEl] = useState(null)

  const handleOpenPopover = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClosePopover = () => {
    setAnchorEl(null)
  }

  const isMainPopoverOpen = Boolean(anchorEl)
  const popoverId = isMainPopoverOpen ? 'templates' : undefined

  const handleResetFields = () => {
    handleSelectTemplate({ value: selectedStory.id, label: selectedStory.templateName })
  }

  const handleResetState = () => {
    dispatch(setClearFields())
    documentContext.clearContext()
    dispatch(setIsUnsavedChanges(false))
  }

  return (
    <div className='border-brand-form-accent sticky bottom-0 mt-auto flex justify-center gap-2 border-t bg-white p-2 sm:gap-[10px] sm:p-4'>
      <Microbutton
        variant='transparent-light'
        type='large-font'
        className='h-[40px] w-[40px] min-w-0 truncate uppercase'
        onClick={handleOpenPopover}
        data-tooltip-id='chatBotPanel-clear'
        icon={<MoreVert fontSize='inherit' />}
      />

      <Popover
        id={popoverId}
        open={isMainPopoverOpen}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div
          ref={popoverRef}
          className='bg-brand-accent-flat border-brand-form-accent space-y-[6px] rounded-[4px] border p-2 pb-[10px]'
        >
          <ListPosition
            text={<T keyName='eleo-reset-changes'>reset changes</T>}
            className='block capitalize sm:hidden'
            onClick={() => {
              handleClosePopover()
              handleResetFields()
            }}
          />
          <ListPosition
            text={<T keyName='eleo-duplicate'>Duplicate</T>}
            className='sm:!mt-0'
            disabled={!selectedStory?.id?.length}
            onClick={() => {
              handleClosePopover()
              setNewStoryName('')
              setModalData({
                isVisible: true,
                title: (
                  <T
                    keyName='eleo-your-story-duplicate-modal-title'
                    defaultValue='Duplicate <span>{storyName}</span>'
                    params={{
                      storyName: selectedStory.templateName,
                      span: <span className='italic'></span>,
                    }}
                  />
                ),
                acceptLabel: t('eleo-duplicate', 'duplicate'),
                type: 'duplicate',
              })
            }}
          />
          <ListPosition
            text={<T keyName='eleo-rename'>Rename</T>}
            onClick={() => {
              handleClosePopover()
              setNewStoryName(selectedStory.templateName)
              setModalData({
                isVisible: true,
                title: (
                  <T
                    keyName='eleo-your-story-rename-modal-title'
                    defaultValue='Update Your Story name'
                  />
                ),
                acceptLabel: t('eleo-rename', 'rename'),
                type: 'rename',
              })
            }}
          />

          <div className='border-brand-form-accent-light mx-[6px] my-[2px] border-b' />
          <ListPosition
            text={<T keyName='eleo-delete'>Delete</T>}
            disabled={!selectedStory?.id?.length}
            onClick={() => {
              handleClosePopover()
              handleDeleteTemplate(selectedStory.id)
            }}
          />
        </div>
      </Popover>
      <Microbutton
        variant='transparent-light'
        type='main'
        className='hidden h-[40px] uppercase sm:block'
        onClick={handleResetFields}
        data-tooltip-id='yourStory-yourStoryClear'
        text={<T keyName='eleo-reset-changes'>reset changes</T>}
      />
      <Microbutton
        variant='transparent-light'
        type='main'
        className='block h-[40px] uppercase sm:hidden'
        onClick={() => {
          if (isUnsavedChanges) {
            displayUnsavedChangesModal({
              secondaryCallback: () => {
                handleResetState()
                setIsShowSelector(true)
              },
              primaryCallback: async () => {
                await handleSaveStoryTemplate()
                handleResetState()
                setIsShowSelector(true)
              },
            })
          } else {
            handleResetState()
            setIsShowSelector(true)
          }
        }}
        text={<T keyName='eleo-back'>back</T>}
      />
      <Microbutton
        id='save'
        variant='solid'
        type='main'
        className='h-[40px] flex-1 uppercase'
        onClick={() => handleSaveStoryTemplate()}
        text={<T keyName='eleo-save'>save</T>}
        isLoading={isLoading}
      />
    </div>
  )
}

export default StoryActions
