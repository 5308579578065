/***
 *
 *   HOMEPAGE
 *   Homepage template with features, testimonials and CTA
 *
 **********/

import React from 'react'

// import DontGetOvertaken from 'tailwindComponents/DontGetOvertaken'
// import ImageCarousel from 'components/carousel/ImageCarousel'
import { Navigate } from 'react-router-dom'
import { DEFAULT_PAGE } from 'constants'

export function Home() {
  return <Navigate to={DEFAULT_PAGE} />

  // return (
  //   <>
  //     <main>
  //       <Hero />
  //       <PoweredBy />
  //       <BoostYoutCapabilities />
  //       <UseEleoToday />
  //       <DontGetOvertaken />
  //       <VideoCTA />
  //       <PotentialImageTilesCTA />
  //       <ImageCarousel showLink />
  //       <PrimaryFeatures />
  //       {/* <Testimonials /> */}
  //       <SimplePricing />
  //       {/* <Pricing /> */}
  //       <PackPricing />
  //       {/* <Faqs /> */}
  //       <EleoOutro />
  //     </main>
  //   </>
  // )
}
