import { Close, Delete, KeyboardArrowLeft } from '@mui/icons-material'
import { T, useTranslate } from '@tolgee/react'
import classNames from 'classnames'
import { errorToast, languages, renderTextWithLinks } from 'helpers'
import MicRecorder from 'mic-recorder-to-mp3'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import ChatMessage from 'toolComponents/chat/ChatMessage/ChatMessage'
import { useGetTranscription } from 'toolComponents/generic/audio/transcription'
import FallbackImage from '../../images/fallback-bot.png'

import ChatBotForm from './chatBotForm'
import ChatNotActive from './chatNotActive'
import { ContextModal } from 'components/document-context/ContextModal'
import { AuthContext } from 'app/auth'
import ActionModal from 'toolComponents/writer/ActionModal'
import { ChatBotAPIService } from 'service/chat-bot-rest-api.service'
import { Microbutton } from 'ui'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useWindowDimensions } from 'components/hooks/window'

const recorder = new MicRecorder({
  bitRate: 128,
})

const ChatBotUi = ({
  chatBotSettings,
  setMessage,
  handleKeyDown,
  message,
  setMessages,
  messages,
  handleChat,
  setChatId,
  setLanguage,
  language,
  userCanAccessHistory,
  setHistoryIsVisible,
  historyIsVisible,
  setChatIsActive,
  widget = false,
  handleTTS,
  isAutoAudio,
  setIsAutoAudio,
  setAudioStream,
  avatar,
  isGenerating,
  handleStopRequest,
  audioStream,
  setTtsIndex,
  ttsIndex,
  isSearchWeb,
  setSearchWeb,
  documentContext,
  isExternal = false,
  isFromLibrary,
}) => {
  const navigate = useNavigate()
  const scrollHandle = useRef()
  const messageContainerRef = useRef()
  const { t } = useTranslate()
  const { width } = useWindowDimensions()
  const authContext = useContext(AuthContext)
  const [isTranscriptionLoading, setIsTranscriptionLoading] = useState(false)
  const [isRecording, setIsRecording] = useState(false)
  const [chatBotToDelete, setChatBotToDelete] = useState({ chatBotId: '' })
  const [modalIsVisible, setModalIsVisible] = useState({
    content: '',
    visible: false,
  })
  const [params] = useSearchParams()
  const isEmbed = params.get('embed')

  const getTranscription = useGetTranscription(true)

  const handleDeleteChatBot = async () => {
    setChatBotToDelete({ chatBotId: chatBotSettings.id })
    setModalIsVisible({ content: 'DELETE_TEMPLATE', visible: true })
  }

  function handleChangeRecording(sendWhenReady = false) {
    if (isRecording) {
      recorder
        .stop()
        .getMp3()
        .then(([buffer, blob]) => {
          setIsRecording(false)
          if (buffer.length < 25) return

          const lang = languages.find((l) => l.value === language)
          getTranscription({
            recordingBlob: blob,
            setIsLoading: setIsTranscriptionLoading,
            callback: (text) => {
              sendWhenReady
                ? handleChat(null, message + (message.length ? ' ' : '') + text)
                : setMessage(text)
            },
            ...(lang.value !== 'auto' && { language: lang.code }),
          })
        })
    } else
      recorder
        .start()
        .then(() => setIsRecording(true))
        .catch((err) => {
          if (err.name === 'NotAllowedError')
            errorToast(
              t('eleo-error-microphone-permission', 'You have to enable the microphone permission')
            )
          else errorToast(t('eleo-error-generic'))
        })
  }

  const handleClearChat = () => {
    setupChat(true)
    setMessage('')
    setChatId('')
    setAudioStream()
  }

  const userAvatar =
    avatar ?? `${process.env.REACT_APP_AWS_CLOUDFRONT_URL}/chatBot/chatIcon/default.jpeg`

  // Resets the chat log inserting the intro message if it exists
  const setupChat = useCallback(
    (force = false) => {
      if (!force && messages?.length) return
      if (chatBotSettings?.intro)
        setMessages([{ role: 'assistant', content: chatBotSettings?.intro }])
      else setMessages([])
    },
    [chatBotSettings?.intro, setMessages]
  )

  useEffect(() => {
    setupChat()
  }, [setupChat])

  useEffect(() => {
    messageContainerRef.current.scrollTop = scrollHandle.current.offsetTop
  }, [messages, scrollHandle, isEmbed])

  return (
    <div className='relative h-full min-w-0 flex-1'>
      {authContext?.user?.permission === 'master' && !widget && !isEmbed && (
        <div
          className='absolute right-4 top-2 z-50 cursor-pointer text-red-700'
          onClick={handleDeleteChatBot}
        >
          <Delete />
        </div>
      )}
      {isFromLibrary && (
        <Microbutton
          variant='transparent-light'
          className='!bg-brand-accent-flat hover:!bg-brand-accent-light-flat  !absolute left-4 top-4 z-50 shadow-[3px_3px_3px_#00000025] lg:left-10 lg:top-10'
          text={
            width > 640 ? (
              <T keyName='eleo-back-to-forum'>Back to chat forum</T>
            ) : (
              <T keyName='eleo-back-to-forum-short'>Back</T>
            )
          }
          icon={<KeyboardArrowLeft fontSize='inherit' />}
          onClick={() => navigate('/chatbot/library')}
        />
      )}

      <div
        className={classNames('relative h-full overflow-y-auto  overflow-x-hidden bg-[#ffffff]', {
          'pt-[60px]': widget,
          'pt-[60px] lg:pt-[140px]': !widget,
        })}
        ref={messageContainerRef}
      >
        {widget && (
          <div className='fixed top-0 z-[99] h-[45px] w-full bg-[#ffffff] shadow-md'>
            <Close
              style={{
                color: '#363636',
                fontSize: 35,
                cursor: 'pointer',
                position: 'fixed',
                right: '10px',
                top: '5px',
              }}
              onClick={() => setChatIsActive(false)}
            />
          </div>
        )}
        {widget &&
        !chatBotSettings?.settings?.activeOnRemoteWebsite &&
        !chatBotSettings?.settings?.publishing?.embed ? (
          <div className='py-[140px]'>
            <ChatNotActive showButton={false} />
          </div>
        ) : (
          <>
            <div className='mx-auto flex h-full flex-1 flex-col'>
              {!widget && !isEmbed && (
                <div className='flex border-b'>
                  <div className='mx-auto pb-[50px]'>
                    <div className='flex flex-col items-start gap-6 px-1 sm:flex-row'>
                      <img
                        src={
                          chatBotSettings.avatar?.length ? chatBotSettings.avatar : FallbackImage
                        }
                        alt='chatbot avatar'
                        className='max-h-[250px] min-h-[190px] rounded-[4px]'
                      />

                      <div className='max-w-[380px] space-y-3 py-[6px] sm:w-[380px]'>
                        <div className='flex items-start justify-between'>
                          <div className='space-y-1'>
                            {/* Name */}
                            <h2 className='text-brand-body line-clamp-2 text-[14px] font-bold leading-[1.1em] tracking-normal'>
                              {chatBotSettings.forum?.displayName?.length
                                ? chatBotSettings.forum.displayName
                                : chatBotSettings.name}
                            </h2>
                            {/* Author */}
                            <div className='mb-[10px] mt-[4px] flex items-center gap-[3px]'>
                              <p className='text-brand-body text-[12px] leading-[1.3em] opacity-70'>
                                {t('eleo-chat-forum-card-author-by', 'By')}
                              </p>
                              <span
                                onClick={(e) => {
                                  e.stopPropagation()
                                  navigate(`/chatbot/library/profile/${chatBotSettings.author}`)
                                }}
                                className=' text-brand-violet-tertiary group-hover:text-brand-violet-tertiary body-small cursor-pointer underline'
                              >
                                {chatBotSettings.author}
                              </span>
                            </div>
                          </div>

                          {chatBotSettings.category && (
                            <div className='text-brand-gray border-brand-gray-light rounded-[4px] border px-[6px] py-1 text-[12px] leading-[1.4em]'>
                              {t(chatBotSettings.category)}
                            </div>
                          )}
                        </div>
                        <div className='text-brand-body whitespace-pre-wrap text-[12px] leading-[1.3em] opacity-70'>
                          {renderTextWithLinks(chatBotSettings.forum?.longDescription)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div
                className={classNames(
                  'mx-auto flex w-full max-w-[1100px] flex-grow flex-col space-y-4 px-2 pb-8 pt-4 sm:px-[70px]',
                  {
                    'pb-8': !widget,
                    'pb-[150px]': widget,
                  }
                )}
              >
                {messages?.map((botMessage, i) => (
                  <ChatMessage
                    message={botMessage}
                    index={i}
                    key={i}
                    customAssistantLogo={chatBotSettings.avatar}
                    language={language}
                    showRegenerate={false}
                    showShareIcon={!widget}
                    avatar={userAvatar}
                    widget
                    handleTTS={handleTTS}
                    isLoad
                    ttsIndex={ttsIndex}
                  />
                ))}
                <div ref={scrollHandle}></div>
              </div>
              <div
                className={classNames('bottom-0 z-10 bg-[#ffffff]', {
                  'fixed w-full': widget,
                  sticky: !widget,
                })}
              >
                <div>
                  <ChatBotForm
                    isGenerating={isGenerating}
                    isRecording={isRecording}
                    isTranscriptionLoading={isTranscriptionLoading}
                    handleAbort={handleStopRequest}
                    handleStopRequest={handleStopRequest}
                    handleChangeRecording={handleChangeRecording}
                    handleSubmit={handleChat}
                    setMessage={setMessage}
                    message={message}
                    language={language}
                    handleClearChat={handleClearChat}
                    userCanAccessHistory={userCanAccessHistory}
                    setHistoryIsVisible={setHistoryIsVisible}
                    isHistoryVisible={historyIsVisible}
                    isAutoAudio={isAutoAudio}
                    setIsAutoAudio={setIsAutoAudio}
                    setAudioStream={setAudioStream}
                    audioStream={audioStream}
                    setTtsIndex={setTtsIndex}
                    setLanguage={setLanguage}
                    widget={widget}
                    isSearchWebAllowed={chatBotSettings.allowWebSearch}
                    isLanguageChangeAllowed={chatBotSettings.allowLanguageChange}
                    isModeChangeAllowed={chatBotSettings.allowModeChange}
                    defaultMode={chatBotSettings.defaultMode}
                    isSearchWeb={isSearchWeb}
                    setSearchWeb={setSearchWeb}
                    isContextAllowed={chatBotSettings.allowContext}
                    documentContext={documentContext}
                    isExternal={isExternal}
                    messages={messages}
                  />
                </div>
              </div>
            </div>
            {documentContext?.isModalVisible && <ContextModal documentContext={documentContext} />}
            {modalIsVisible.visible && (
              <ActionModal
                modalKey={modalIsVisible.content}
                setModalIsVisible={setModalIsVisible}
                reloadTemplatesData={() => {
                  window.location.reload()
                }}
                modalHeaderText={<T keyName='eleo-delete-bot-modal-title'>Delete Chat Bot</T>}
                deleteTemplateEndpoint={() =>
                  ChatBotAPIService.deleteChatBot({ chatBotId: chatBotToDelete.chatBotId })
                }
                mainTitle={
                  <T keyName='eleo-delete-bot-header'>
                    Are you sure you want to delete this Chat Bot?
                  </T>
                }
              />
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default ChatBotUi
