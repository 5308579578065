import { useTranslate } from '@tolgee/react'
import { transformBotName } from 'helpers'
import React, { useState } from 'react'
import { Button, Input } from 'ui'

const DuplicateModalView = ({ originalChatBotId, handleDuplicateChatBot }) => {
  const { t } = useTranslate()
  const [duplicatedName, setDuplicatedName] = useState('')
  return (
    <div className='mt-[10px] flex flex-col gap-[10px] py-[10px]'>
      <Input
        className='w-full'
        value={duplicatedName}
        placeholder={t('eleo-chat-bot-name-placeholder', 'Chatbot name')}
        onChange={(value) => setDuplicatedName(value)}
      />

      <Button
        onClick={() => handleDuplicateChatBot(originalChatBotId, duplicatedName)}
        className='bg-brand-violet-secondary hover:!bg-brand-violet-tertiary !h-[40px] !w-full  !rounded-[4px] !px-[8px] !py-[4px] transition-all duration-300'
      >
        <p className='whitespace-nowrap text-[12px] font-medium uppercase leading-[1.2em] text-white'>
          {t('eleo-chatbot-library-duplicate', 'Duplicate')}
        </p>
      </Button>
    </div>
  )
}

export default DuplicateModalView
