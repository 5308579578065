/***
 *
 *   PROFILE
 *   Update the user profile or close the account
 *
 **********/

import {
  Animate,
  AuthContext,
  Button,
  Card,
  Event,
  Form,
  Message,
  useAPI,
  useNavigate,
} from 'components/lib'
import { Fragment, useContext, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { Error } from '@mui/icons-material'
import { T, useTranslate } from '@tolgee/react'
import axios from 'axios'
import { DEFAULT_MODAL_DATA, Modal } from 'toolComponents/generic/modal'

export function Profile() {
  const navigate = useNavigate()
  const location = useLocation()
  const [modalData, setModalData] = useState(DEFAULT_MODAL_DATA)
  const { t } = useTranslate()

  // context
  const authContext = useContext(AuthContext)

  // fetch
  const user = useAPI('/api/user')

  return (
    <Fragment>
      {/* <AccountNav /> */}
      <Animate>
        {location.state?.message === 'PAYMENT_REQUIRES_ACTION' && (
          <div className='p-2'>
            <Message type='warning' title={<T keyName='eleo-payment-past-due'>Payment past due</T>}>
              <T keyName='eleo-message-contact-owner'>
                Please contact your account owner to complete the payment
              </T>
            </Message>
          </div>
        )}
        <div className='h-full overflow-y-auto'>
          <Card
            title={<T keyName='eleo-profile-edit-your-profile'>Edit Your Profile</T>}
            loading={user.loading}
            restrictWidth
          >
            {user?.data && (
              <Form
                buttonText={<T keyName='eleo-profile-edit-profile-save-button'>Save</T>}
                url='/api/user'
                method='PATCH'
                inputs={{
                  name: {
                    label: <T keyName='eleo-profile-your-name'>Your Name</T>,
                    type: 'text',
                    required: true,
                    value: user.data.name,
                    errorMessage: <T keyName='eleo-profile-info-error-1'>Please enter your name</T>,
                  },
                  email: {
                    label: <T keyName='eleo-profile-email-address'>Email address</T>,
                    type: 'email',
                    required: true,
                    value: user.data.email,
                    errorMessage: (
                      <T keyName='eleo-profile-info-error-2'>Please enter your email address</T>
                    ),
                  },
                  avatar: {
                    label: <T keyName='eleo-profile-profile-picture'>Profile Picture</T>,
                    type: 'file',
                    required: false,
                    max: 1,
                  },
                  ...(['owner', 'master'].includes(user.data.permission) && {
                    account_name: {
                      type: 'text',
                      label: <T keyName='eleo-profile-account-name'>Account Name</T>,
                      value: user.data.account_name,
                    },
                  }),
                  ...(['owner', 'master'].includes(user.data.permission) && {
                    account_description: {
                      type: 'textarea',
                      label: <T keyName='eleo-profile-account-description'>Account Description</T>,
                      value: user.data.account_description,
                    },
                  }),
                  ...(user.data.accounts?.length > 1 && {
                    default_account: {
                      label: <T keyName='eleo-profile-default-account'>Default Account</T>,
                      type: 'select',
                      default: user.data.default_account,
                      options: user.data.accounts.map((x) => {
                        return {
                          value: x.id,
                          label: x.name,
                        }
                      }),
                    },
                  }),
                }}
                callback={(res) => {
                  // update the account name
                  if (authContext.user?.accounts?.length > 0) {
                    const accounts = [...authContext.user.accounts]
                    accounts[accounts.findIndex((x) => x.id === authContext.user.account_id)].name =
                      res.data.data.account_name
                    authContext.update({ accounts: accounts })
                  }

                  // update the user name and avatar
                  authContext.update({
                    name: res.data.data?.name,
                    avatar: res.data.data?.avatar || user.data?.avatar,
                  })
                }}
              />
            )}

            <Fragment>
              <br />
              <Button
                textOnly
                action={() =>
                  setModalData({
                    isVisible: true,
                    title: (
                      <T
                        keyName='eleo-account-modal-close'
                        params={{ i: <i />, accountName: authContext?.user.email }}
                        defaultValue='Close <i>{accountName}</i> account'
                      />
                    ),
                    subtitle: (
                      <Message
                        className='body-small !mb-0 mt-1 !p-3 font-medium text-[#ED7700]'
                        type='warning'
                        isFlex
                        text={
                          <T keyName='eleo-account-modal-close-warning'>
                            All of your data, except for used limits, will be lost. You won't be
                            able to log in to the application. This action can not be undone.
                          </T>
                        }
                        icon={<Error className='float-left mr-[10px]' />}
                      />
                    ),
                    acceptLabel: t('eleo-close-account', 'Close account'),
                    type: 'close',
                  })
                }
                text={<T keyName='eleo-profile-close-account-button'>Close Your Account</T>}
              />
            </Fragment>
          </Card>
        </div>
      </Animate>
      <Modal
        callback={async () => {
          try {
            await axios.delete(authContext.permission.owner ? '/api/account' : '/api/user')
            // destory user
            Event('closed_account')
            localStorage.removeItem('user')
            localStorage.removeItem('registered')
            navigate('/signup')
          } catch (err) {
            console.log(err)
          }
        }}
        isVisible={modalData.isVisible}
        containerClasses='bg-black bg-opacity-[3%]'
        hideModal={() => setModalData(DEFAULT_MODAL_DATA)}
        title={modalData.title}
        subtitle={modalData.subtitle}
        acceptLabel={modalData.acceptLabel}
        isValid
      />
    </Fragment>
  )
}
