import {
  AddOutlined,
  ArticleOutlined,
  Close,
  DescriptionOutlined,
  DesignServicesOutlined,
  WebAssetOutlined,
} from '@mui/icons-material'
import { useTranslate } from '@tolgee/react'
import classNames from 'classnames'
import { Microbutton } from 'ui'

const iconMap = {
  story: <ArticleOutlined fontSize='inherit' />,
  document: <DescriptionOutlined fontSize='inherit' />,
  template: <DesignServicesOutlined fontSize='inherit' />,
  website: <WebAssetOutlined fontSize='inherit' />,
}

export function ContextField({
  documentContext,
  onlyStory = false,
  noLabel = false,
  noIcon = false,
  classes,
}) {
  const { t } = useTranslate()

  return (
    <div id='context-field' className='!w-full space-y-[5px]'>
      {/* Label */}
      {!noLabel && (
        <div className='body-small text-brand-gray-dark pl-[2px]'>
          {t('eleo-context-title', 'Context')}
        </div>
      )}

      {/* Context items */}
      <div
        className={classNames(
          classes,
          'border-brand-gray-light flex w-full flex-wrap items-center gap-[4px] rounded-[4px] border bg-white px-[6px]',
          documentContext.docContext.length ? 'p-[4px]' : 'cursor-pointer py-[4px] pl-[14px] pr-3'
        )}
        onClick={() => !documentContext.docContext.length && documentContext.openModal()}
      >
        {documentContext.docContext?.map((entry) => (
          <div
            key={entry.id}
            className='text-brand-violet bg-brand-form-accent-light group relative flex h-[26px] select-none items-center gap-[5px] rounded-[4px] px-[5px] py-[3px] text-[14px] '
            title={entry.label}
          >
            {iconMap[entry.type]}
            <div className='min-w-[16px] max-w-[100px] truncate text-[12px] font-medium'>
              {entry.label}
            </div>

            <div className='absolute right-0 h-full w-[30px] rounded-[4px] bg-white opacity-0 transition-opacity group-hover:opacity-100'>
              <button
                type='button'
                className='text-brand-violet bg-brand-form-accent-light hover:bg-brand-violet  flex h-full  w-full items-center justify-center rounded-[4px] text-[18px] transition-opacity hover:text-white'
                onClick={() => documentContext.removeDocument(entry.id)}
              >
                <Close fontSize='inherit' />
              </button>
            </div>
          </div>
        ))}
        {/* Add button */}
        {documentContext.docContext.length ? (
          <Microbutton
            iconPlacement='right'
            text={t('eleo-context-add', 'add')}
            variant='plain'
            icon={<AddOutlined fontSize='inherit' />}
            className='bg-brand-form-accent hover:bg-brand-violet-light text-brand-violet h-[26px] uppercase hover:text-white'
            textClasses='!px-[1px]'
            onClick={() => documentContext.openModal()}
          />
        ) : (
          <div className='flex h-[26px] w-full items-center justify-between'>
            <div className='body-secondary select-none truncate opacity-60 '>
              {t('eleo-context-add-empty', 'Add Your Story', {
                onlyStory,
              })}
            </div>
            {!noIcon && (
              <div className='text-brand-gray text-[18px]'>
                <AddOutlined fontSize='inherit' />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
