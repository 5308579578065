import { AutoAwesome } from '@mui/icons-material'
import { T, useTranslate } from '@tolgee/react'
import classNames from 'classnames'
import { ContextMenu } from 'components/context-menu'
import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setIsUnsavedChanges, setYourStoryText } from 'store/account/yourStory'
import { Input, Microbutton } from 'ui'

const StoryGenerator = ({ storyText, wordLength, setWordLength }) => {
  const { t } = useTranslate()
  const dispatch = useDispatch()
  const unsavedChangesTimeout = useRef()

  const [isEnhance, setIsEnhance] = useState(false)

  const handleInputChange = (text) => {
    dispatch(setYourStoryText(text))

    if (unsavedChangesTimeout.current) clearTimeout(unsavedChangesTimeout.current)
    unsavedChangesTimeout.current = setTimeout(() => dispatch(setIsUnsavedChanges(true)), 250)
  }

  useEffect(() => {
    const numWords = storyText?.split(/\s+/)?.length
    const wordLength = storyText?.length ? numWords : 0
    setWordLength(wordLength)
  }, [storyText])

  return (
    <div className='items-start space-y-[6px] p-6' data-tooltip-id='yourStory-yourStoryText'>
      <div className='text-brand-violet flex items-center gap-[14px] text-[14px]'>
        <div className='font-medium'>{t('eleo-your-story-text-content-title', 'Text Content')}</div>
        <span className={classNames(wordLength > 4000 ? 'text-brand-pink' : 'opacity-50')}>
          {wordLength} <T keyName='eleo-words'>words</T>
        </span>
      </div>
      <div id='textbox' className='relative w-full'>
        <Input
          placeholder=''
          // placeholder={t('eleo-your-story-placeholder', 'Write your story')}
          className='placeholder-font-regular min-h-[300px] w-full rounded-[8px] pb-8 text-[13px] placeholder-[#36363680]'
          onChange={handleInputChange}
          value={storyText}
          isTextArea
          resize='none'
        />

        <Microbutton
          iconPlacement='left'
          variant='transparent-light'
          icon={<AutoAwesome fontSize='18' />}
          text={t('eleo-enhance-for-chatbot', 'Enhance for Chatbot')}
          className={classNames('!absolute bottom-2 right-2 !m-0 h-[28px]')}
          onClick={() => setIsEnhance(true)}
        />

        {isEnhance && (
          <div className='absolute left-2 top-0 z-50 -translate-y-full'>
            <ContextMenu
              noHeader
              selectedText={storyText}
              tools={['rephrase']}
              forceNotSynonyms
              regenerateUrl='/api/ai/enhance_prompt'
              type='chatbot-instructions'
              handleChangeSelectedText={(newText) => {
                handleInputChange(newText)
                setIsEnhance(false)
              }}
              onClose={() => setIsEnhance(false)}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default StoryGenerator
