import React from 'react'
import eleoLogo from '../../images/new-logos/new-logo.png'
import { T } from '@tolgee/react'
import { Microbutton } from 'ui'
import { useNavigate } from 'react-router-dom'

const ChatNotAvailable = ({ availableFrom }) => {
  const navigate = useNavigate()

  return (
    <div className='m-auto flex h-full   items-center justify-center'>
      <div className='flex flex-col gap-[15px]'>
        <img className='h-20 w-full object-contain' src={eleoLogo} alt='eleo logo' />
        <h1 className='text-brand-violet mt-2 text-center text-2xl font-bold uppercase'>
          {availableFrom ? (
            <T
              keyName='eleo-chat-available-from'
              defaultValue='This chatbot in available only to {availableFrom} users'
              params={{ availableFrom: availableFrom }}
            ></T>
          ) : (
            <T keyName='eleo-chat-not-available'>This chat is not available for You</T>
          )}
        </h1>

        {availableFrom && (
          <div className='flex items-center justify-center gap-3'>
            <Microbutton
              type='main'
              text={<T keyName='eleo-change-your-plan'>Change your plan</T>}
              onClick={() => navigate(`/account/upgrade?plan=${availableFrom}`)}
            />
            <span className='body-secondary'>
              <T keyName='eleo-or'>or</T>
            </span>
            <Microbutton
              type='main'
              variant='transparent'
              text={<T keyName='eleo-go-back'>Go back</T>}
              onClick={() => navigate('/chatbot/library')}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default ChatNotAvailable
