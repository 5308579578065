import { CircularProgress } from '@mui/material'
import { T } from '@tolgee/react'
import useTranscribe from 'components/hooks/transcribe'
import { transcribeLanguageCodeMap } from 'helpers/transcribe'
import { useCallback, useEffect, useRef, useState } from 'react'
import AudioInputLayout from './AudioInputLayout'
import { Mic } from '@mui/icons-material'

export default function ChatVoiceInput({
  dispatch,
  handleSubmit,
  audioStream,
  setAudioStream,
  chatLog,
  state,
  isChatbot = false,
}) {
  const [speechVolume, setSpeechVolume] = useState(false)
  const [voiceDetectedTime, setVoiceDetectedTime] = useState()
  const startMicRef = useRef()

  const {
    toggleTranscribe: handleChangeRecording,
    stopTranscribe: handleStopRecording,
    isLoading,
    isTranscribing: isRecording,
  } = useTranscribe({
    callback: useCallback(
      (data) => {
        if (audioStream) setAudioStream()

        let text = data
        const lastMsg = chatLog[chatLog.length - 2]?.content
        if (data.startsWith(lastMsg)) {
          text = data.slice(lastMsg.length)
        }

        dispatch({ type: 'SET_INPUT', payload: text })
      },
      [dispatch, audioStream, setAudioStream, chatLog]
    ),
    pauseCallback: useCallback(() => {
      setVoiceDetectedTime()
      handleSubmit()
    }, [handleSubmit]),
    voiceDetectedCallback: useCallback(() => {
      setVoiceDetectedTime(performance.now())
    }, []),
    setSpeechVolume,
    isStopOnPause: false,
    language: transcribeLanguageCodeMap[state.language?.code],
    isChatbot,
  })

  useEffect(() => {
    function handleKeyDown(event) {
      if (event.code === 'Escape') {
        handleStopRecording()
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleStopRecording])

  const getStatusText = () => {
    if (isRecording) {
      return (
        <>
          <T keyName='eleo-chat-voice-status-listening-hold'>Listening...</T>
          <div className='hidden md:block'>
            <T keyName='eleo-click-or-esc-to-abort'>Click or ESC to abort</T>
          </div>
          <div className='flex items-center md:hidden'>
            <T
              keyName='eleo-tap-mic-to-abort'
              defaultValue='Tap <mic></mic> to abort'
              params={{
                mic: (
                  <span className='inline-flex text-[14px]'>
                    <Mic fontSize='inherit' />
                  </span>
                ),
              }}
            ></T>
          </div>
        </>
      )
    }

    if (isLoading) {
      return (
        <span className=''>
          <T keyName='eleo-chat-voice-status-loading'>Loading...</T>
          <div></div>
        </span>
      )
    }

    return (
      <>
        <span className='hidden md:block'>
          <T keyName='eleo-chat-voice-status-cta'>Click to start a conversation</T>
        </span>
        <div className='block md:hidden'>
          <div className='flex items-center'>
            <T
              keyName='eleo-tap-to-start-conversation'
              defaultValue='Tap <mic></mic> to start'
              params={{
                mic: (
                  <span className='inline-flex text-[14px]'>
                    <Mic fontSize='inherit' />
                  </span>
                ),
              }}
            />
          </div>{' '}
          <div>
            <T keyName='eleo-conversation'>conversation</T>
          </div>
        </div>
      </>
    )
  }

  const mouseDownDelayRef = useRef()
  const mouseUpDelayRef = useRef()

  return (
    <AudioInputLayout
      getStatusText={getStatusText}
      isLoading={isLoading}
      isRecording={isRecording}
      onClick={() => {}}
      onMouseDown={() => {
        if (mouseDownDelayRef.current) return
        mouseDownDelayRef.current = true
        setTimeout(() => {
          mouseDownDelayRef.current = false
        }, 500)

        startMicRef.current = performance.now()
        dispatch({ type: 'SET_INPUT', payload: '' })
        handleChangeRecording()
      }}
      onMouseUp={() => {
        if (mouseUpDelayRef.current) return
        mouseUpDelayRef.current = true
        setTimeout(() => {
          mouseUpDelayRef.current = false
        }, 500)

        if (startMicRef.current && performance.now() - startMicRef.current > 2000) {
          handleStopRecording()
        }
      }}
      speechVolume={speechVolume}
      loader={
        <span className='text-brand-violet'>
          <CircularProgress color='inherit' />
        </span>
      }
      voiceDetectedTime={voiceDetectedTime}
    />
  )
}
