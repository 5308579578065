import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import { T, useTranslate } from '@tolgee/react'
import classNames from 'classnames'
import { ContextField } from 'components/document-context/ContextField'
import { FieldDescription } from 'components/field-description'
import { useLanguages } from 'components/hooks/language'
import usePanelSize from 'components/hooks/panelSize'
import { transformSelectOptionValue } from 'helpers'
import aiController from 'helpers/AIController'
import MoreOptions from 'toolComponents/generic/more-options'
import { Input, Range, ReactSelect } from 'ui'
import WriterLengthContainer from '../writer/WriterLengthContainer'
import { ToneInputData } from '../writer/writer-options-data'
import { IdeaFor } from './great-ideas-options-data'

export default function GreatIdeasOptions({
  visible,
  dispatch,
  actionTypes,
  setWordLength,
  setWriterLengthOption,
  setText,
  setDetails,
  setNegativeWords,
  wordLength,
  writerLengthOption,
  setCreativity,
  optionsData,
  creativity,
  isShowAdvanced,
  setIsShowAdvanced,
  setIsGuruModeEnabled,
  isGuruModeEnabled,
  documentContext,
  model,
  setModel,
}) {
  const isPanelLarge = usePanelSize('ideas')
  const { t } = useTranslate()

  const { defaultToolOutPutLanguage, languages } = useLanguages()

  const handleTopicChange = ({ value, name }) => {
    dispatch({ type: actionTypes[`SET_${name}`], payload: value })
  }

  const handleRadioButtonCheck = ({ radioBoxName, checked }) => {
    setWordLength({ id: radioBoxName, checked })
  }

  return (
    visible && (
      <div
        className={classNames(
          'relative break-words pb-6',
          isPanelLarge
            ? 'grid-cols-1 gap-[14px] space-y-4 lg:grid lg:grid-cols-2 lg:gap-4 lg:space-y-0'
            : 'space-y-4'
        )}
      >
        <div className='col-span-2'>
          <ContextField documentContext={documentContext} />
        </div>

        {/* Input 1 */}
        <div id='input' className='col-span-2 space-y-[6px]' data-tooltip-id='greatIdeas-describe'>
          <FieldDescription>
            <T keyName='eleo-tool-great-ideas-describe-what-are-you-looking-for'>
              Describe what are you looking for
            </T>
          </FieldDescription>
          <Input
            placeholder={t('eleo-tool-great-ideas-describe-placeholder', 'Describe')}
            className='placeholder-font-regular min-h-[80px] w-full rounded-[8px] text-[13px] placeholder-[#36363680]'
            onChange={setText}
            value={optionsData.text}
            isTextArea
          />
        </div>

        {/* Details */}
        <div className='col-span-2 space-y-[6px]' data-tooltip-id='greatIdeas-details'>
          <FieldDescription>
            <T keyName='eleo-tool-great-ideas-details'>Details</T>
          </FieldDescription>
          <Input
            placeholder={t('eleo-tool-great-ideas-details-placeholder', 'Details')}
            className='placeholder-font-regular min-h-[100px] w-full rounded-[8px] text-[13px]  placeholder-[#36363680]'
            value={optionsData.details}
            onChange={setDetails}
            isTextArea
          />
        </div>

        {/* Negative */}
        <div className='col-span-2 space-y-[6px]' data-tooltip-id='greatIdeas-negative'>
          <FieldDescription>
            <T keyName='eleo-tool-great-ideas-negative-words'>
              Describe what you don’t want to read
            </T>
          </FieldDescription>
          <Input
            placeholder={t(
              'eleo-tool-great-ideas-negative-words-placeholder',
              'Describe what you don’t want to read'
            )}
            className='placeholder-font-regular min-h-[100px] w-full rounded-[8px] text-[13px]  placeholder-[#36363680]'
            value={optionsData.negativeWords}
            onChange={setNegativeWords}
            isTextArea
          />
        </div>

        {/* Idea for */}
        <div className='space-y-[6px]' data-tooltip-id='greatIdeas-ideaFor'>
          <FieldDescription>
            <T keyName='eleo-tool-great-ideas-idea-for'>Idea For</T>
          </FieldDescription>
          <ReactSelect
            options={IdeaFor}
            placeHolder={t('eleo-select-drop-down-placeholder', 'Select')}
            value={transformSelectOptionValue(IdeaFor, optionsData.state.ideaFor, null)}
            setSelectedValue={handleTopicChange}
            name='IDEA'
            isClearable
            clearLabel={t('eleo-clear-button-label-none', 'None')}
          />
        </div>

        {/* Model */}
        <div className='space-y-[6px]'>
          <FieldDescription>
            <T keyName='eleo-chat-settings-model'>Model</T>
          </FieldDescription>

          <div className='h-[36px]'>
            <ReactSelect
              options={aiController.getSupportedTextModels({
                includeReasoning: false,
                includeOnline: false,
              })}
              setSelectedValue={({ value }) => {
                setModel({
                  ...aiController.getSupportedTextModels().find((model) => model.value === value),
                  description: undefined,
                })
              }}
              value={model}
              menuPlacement='bottom'
              menuAlignment='right'
            />
          </div>
        </div>

        {/* Show advanced button */}
        <div className='col-span-2 flex items-center justify-center text-[14px]'>
          <button
            className={classNames(
              'flex w-auto items-center justify-center rounded-[4px] text-white',
              isShowAdvanced ? 'bg-brand-secondary' : 'bg-brand-green'
            )}
            onClick={() => setIsShowAdvanced((prev) => !prev)}
          >
            <div className='ml-[5px]'>
              <MoreOptions isShowAdvanced={isShowAdvanced} />
            </div>
            {isShowAdvanced ? (
              <ChevronLeft className='rotate-90' />
            ) : (
              <ChevronRight className='rotate-90' />
            )}
          </button>
        </div>
        {/* Advanced section */}
        <div
          className={classNames(
            'col-span-2 flex flex-col',
            !isShowAdvanced && 'hidden',
            isPanelLarge ? 'grid grid-cols-1 gap-[14px] lg:grid-cols-2 lg:gap-4' : 'space-y-4'
          )}
        >
          {/* Tone */}
          <div className='space-y-[6px]' data-tooltip-id='greatIdeas-tone'>
            <FieldDescription>
              <T keyName='eleo-write-text-tone'>Tone</T>
            </FieldDescription>
            <ReactSelect
              options={ToneInputData}
              isClearable
              clearLabel={t('eleo-clear-button-label-default', 'Default')}
              placeHolder={t('eleo-select-drop-down-placeholder', 'Select')}
              // defaultValue={options[0]}
              setSelectedValue={handleTopicChange}
              value={transformSelectOptionValue(ToneInputData, optionsData.state.tone, null)}
              name='TONE'
              menuPlacement='top'
            />
          </div>

          {/* Language */}
          <div className='space-y-[6px]' data-tooltip-id='greatIdeas-language'>
            <FieldDescription>
              <T keyName='eleo-write-text-language'>Language</T>
            </FieldDescription>
            <ReactSelect
              options={languages}
              setSelectedValue={handleTopicChange}
              value={transformSelectOptionValue(
                languages,
                optionsData.state.language,
                defaultToolOutPutLanguage
              )}
              name='LANGUAGE'
              menuPlacement='top'
              menuAlignment='right'
            />
          </div>

          {/* Creativity */}
          <div
            id='creativity'
            className='col-span-2 space-y-[6px]'
            data-tooltip-id='greatIdeas-creativity'
          >
            <FieldDescription>
              <T keyName='eleo-write-text-creativity'>Creativity</T>
            </FieldDescription>
            <div className='!w-full'>
              <Range min={1} max={7} onChange={setCreativity} value={creativity} />
              <div className='text-brand-secondary flex justify-between text-sm'>
                <div>
                  <T keyName='eleo-tool-great-ideas-creativity-literal'>Literal</T>
                </div>
                <div>
                  <T keyName='eleo-tool-great-ideas-creativity-creative'>Creative</T>
                </div>
              </div>
            </div>
          </div>

          {/* Length */}
          <div className='col-span-2 space-y-[6px]' data-tooltip-id='greatIdeas-length'>
            <FieldDescription>
              <T keyName='eleo-write-text-length'>Length</T>
            </FieldDescription>
            <WriterLengthContainer
              handleRadioButtonCheck={handleRadioButtonCheck}
              selectedWordLength={wordLength}
              setWriterLengthOption={setWriterLengthOption}
              writerLengthOption={writerLengthOption}
              forceMobile={!isPanelLarge}
            />
          </div>
        </div>
      </div>
    )
  )
}
