/***
 *
 *   AUTHENTICATION
 *   Auth provider to manage auth functions throughout
 *   the application. <PrivateRoute> component to
 *   protect internal application routes from unauthenticated
 *   access.
 *
 **********/

import { useTolgee } from '@tolgee/react'
import axios from 'axios'
import { Event, useAPI } from 'components/lib'
import { DEFAULT_PAGE } from 'constants'
import { CONSENT_COOKIE_EXPIRATION, getParentDomain } from 'helpers'
import { featureRoutes } from 'helpers/featureRoutes'
import Cookies from 'js-cookie'
import { createContext, useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'

// auth context
export const AuthContext = createContext()

const permissions = require('./permissions.json')

export function AuthProvider(props) {
  const cache = JSON.parse(localStorage.getItem('user'))
  const [user, setUser] = useState(cache)
  const tolgee = useTolgee(['pendingLanguage'])

  const auth = useAPI(user ? '/api/auth' : null)
  useEffect(() => {
    // update the auth status
    if (!auth.loading && auth.data) {
      auth.data.authenticated ? update(auth.data) : signout()
      if (auth.data.language) tolgee.changeLanguage(auth.data.language)
    }
  }, [auth, tolgee])

  function signin(res, next, plan) {
    if (res.data) {
      localStorage.setItem('user', JSON.stringify(res.data))
      localStorage.setItem('registered', true)
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.token
      Cookies.set('eleo-user-status', 'authenticated', {
        domain: getParentDomain(),
        expires: CONSENT_COOKIE_EXPIRATION,
      })
      Event('signin')

      const url = new URL(
        res.data.verified ? '/signup/plan' : '/signup/verify',
        window.location.origin
      )

      if (next) url.searchParams.append('next', next)
      if (plan) url.searchParams.append('plan', plan)

      if (!res.data.plan) return (window.location = url)

      return (window.location = next ? `/${next}` : DEFAULT_PAGE)
    }
  }

  async function signout() {
    axios({ method: 'delete', url: '/api/auth' })
    localStorage.removeItem('user')
    localStorage.removeItem('settings')
    Cookies.remove('eleo-user-status', { domain: getParentDomain() })
    return (window.location = '/signin')
  }

  async function switchAccount(id) {
    const res = await axios({
      method: 'post',
      url: '/api/auth/switch',
      data: { account: id },
    })

    if (res.data) signin(res)
  }

  function update(data) {
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'))
      for (let key in data) {
        user[key] = data[key]
      }

      localStorage.setItem('user', JSON.stringify(user))
      setUser(user)
    }
  }

  return (
    <AuthContext.Provider
      value={{
        user: user,
        signin: signin,
        signout: signout,
        update: update,
        switchAccount: switchAccount,
        permission: permissions[user?.permission],
      }}
      {...props}
    />
  )
}

// custom route object checks for an auth token before
// rendering the route – redirects if token is not present
export function PrivateRoute(props) {
  // check user exists
  const user = JSON.parse(localStorage.getItem('user'))
  const path = window.location.pathname
  const permittedRoutes = [
    '/account/billing',
    '/account/plans',
    '/signup/plan',
    '/account/upgrade',
    '/account',
    '/account/profile',
    '/account/card',
  ]
  if (user?.token) {
    if (permissions[user.permission][props.permission]) {
      if (user.verified) {
        // user has no plan
        if (!user.plan?.id && path !== '/account/profile' && path !== '/signup/plan')
          return <Navigate to='/signup/plan' />

        // user has no subscription
        if (
          user.subscription !== 'active' &&
          user.subscription !== 'trialing' &&
          user.permission !== 'master' &&
          !permittedRoutes.includes(path)
        )
          return user.permission === 'user' ? (
            <Navigate to='/account/profile' state={{ message: 'PAYMENT_REQUIRES_ACTION' }} />
          ) : (
            <Navigate to='/account/plans' />
          )

        // hide verified view if user is verified
        if (path === '/signup/verify') return <Navigate to={DEFAULT_PAGE} />
      } else {
        // user is not verified
        if (path !== '/account/profile' && path !== '/signup/verify')
          return <Navigate to='/signup/verify' />
      }

      // user is good
      return props.children
    }
  }

  // user is not authenticated
  // Check if they should be redirected to signin or signup
  const registered = JSON.parse(localStorage.getItem('registered'))
  // Check where the user should be directed after authenticating
  const next = window.location.pathname.substring(1)

  console.log(window.location.search)

  // Check if the user should later be redirected
  if (!featureRoutes.includes(path) && (!user || !permissions[user.permission][props.permission]))
    return <Navigate to={registered ? `/signin` : `/signup${window.location.search}`} />
  return <Navigate to={registered ? `/signin?next=${next}` : `/signup?next=${next}`} />
}
