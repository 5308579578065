/***
 *
 *   SIGN UP STEP 2
 *   Verify email address
 *   Step 1: create account
 *   Step 2: verify email address
 *   Step 3: select plan
 *
 **********/

import { T } from '@tolgee/react'
import Axios from 'axios'
import { Animate, AuthContext, Card, Link, Message, Row, useNavigate } from 'components/lib'
import { useContext, useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

export function SignupVerification(props) {
  const navigate = useNavigate()
  const params = useSearchParams()[0]

  const authContext = useRef(useContext(AuthContext))

  const [message, setMessage] = useState({
    type: 'infoSecondary',
    title: <T keyName='eleo-check-email-address-header'>Please Check Your Email</T>,
    text: (
      <T keyName='eleo-check-email-address-header-description'>
        Please click the link in the email we sent to verify your account
      </T>
    ),
    button: {
      text: <T keyName='eleo-resend-email-button'>Re-Send Verification Email</T>,
      action: resendVerificationEmail,
    },
  })

  // Check if user was verified from another device
  useEffect(() => {
    async function getToken() {
      try {
        const res = await Axios.post('/api/user/verify/check')

        if (!res.data.token) return

        Axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.token
        authContext.current.update({
          verified: true,
          token: res.data.token,
        })
        const next = params.get('next')
        const plan = params.get('plan')
        const url = new URL('/signup/plan', window.location.origin)

        if (next) url.searchParams.append('next', next)
        if (plan) url.searchParams.append('plan', plan)

        return (window.location = url)
      } catch (err) {
        console.log(err)
      }
    }

    // If user is not signed in, return
    if (!localStorage.getItem('user')) return

    getToken()
    const interval = setInterval(getToken, 5000)
    return () => clearInterval(interval)
  }, [navigate, params])

  useEffect(() => {
    const verifyToken = async (token, next, plan) => {
      try {
        setMessage({
          type: 'success',
          title: <T keyName='eleo-verifying'>Verifying...</T>,
          text: (
            <T keyName='eleo-please-wait-a-moment-verify-email'>
              Please wait a moment while we verify your email.
            </T>
          ),
          button: false,
        })
        const res = await Axios.post('/api/user/verify', {
          token: token,
        })

        if (!localStorage.getItem('user')) {
          setMessage({
            type: 'success',
            title: <T keyName='eleo-account-verified'>Account verified</T>,
            text: (
              <T keyName='eleo-account-verified-switch-device'>
                Your account has been verified. You can go back to the device on which you started
                or log in here.
              </T>
            ),
            button: {
              text: <T keyName='eleo-log-in-here'>Log in here</T>,
              action: () => navigate('/signin'),
            },
          })
          return
        }

        Axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.token
        authContext.current.update({
          verified: true,
          token: res.data.token,
        })

        // The 'next' param is send as a 'null' string if it doesnt exist because of the email templates
        const url = new URL('/signup/plan', window.location.origin)

        if (next && next !== 'null') url.searchParams.append('next', next)
        if (plan && plan !== 'null') url.searchParams.append('plan', plan)

        console.log(url, url.toString())

        return (window.location = url)
      } catch (err) {
        // token isnt for this account, force signout
        if (err.response?.status === 401) return authContext.current.signout()
        setMessage({
          type: 'error',
          title: <T keyName='eleo-verification-link-expired'>Verification Link Has Expired</T>,
          text: (
            <T keyName='eleo-resend-email'>Please re-send the verification email and try again</T>
          ),
          button: {
            text: <T keyName='eleo-resend-email-button'>Re-Send Verification Email</T>,
            action: resendVerificationEmail,
          },
        })
      }
    }

    const token = params.get('token')
    if (token) {
      const next = params.get('next')
      const plan = params.get('plan')
      verifyToken(token, next, plan)
    }
  }, [authContext, navigate, params])

  async function resendVerificationEmail() {
    setMessage({
      type: 'success',
      title: <T keyName='eleo-check-email-address-header'>Please Check Your Email</T>,
      text: (
        <T keyName='eleo-check-email-address-header-description'>
          Please click the link in the email we sent to verify your account
        </T>
      ),
    })

    await Axios({ method: 'post', url: '/api/user/verify/request' })
  }

  return (
    <Animate type='pop'>
      <Row title={<T keyName='eleo-verify-email-address'>Verify Your Email Address</T>}>
        <Card loading={false} restrictWidth center className='mx-auto !max-w-[600px]'>
          <Message
            className='bg-transparent shadow-md'
            messageSectionClasses='flex flex-col'
            sectionTitleClasses='text-brand-violet'
            type={message.type}
            title={message.title}
            text={message.text}
            buttonText={message.button?.text}
            buttonAction={message.button?.action}
          />

          <div className='mt-4 text-center'>
            <Link
              url='/account/profile'
              text={<T keyName='eleo-manage-your-account'>Manage Your Account</T>}
              className='text-brand-violet hover:text-indigo-500'
            />
          </div>
        </Card>
      </Row>
    </Animate>
  )
}
