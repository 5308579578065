import { T } from '@tolgee/react'
import axios from 'axios'
import classNames from 'classnames'
import { ContextModal } from 'components/document-context/ContextModal'
import { HelpTooltips } from 'components/help/helpTooltips'
import { useDocumentContext } from 'components/hooks/context'
import { Loader, ToolLayoutPrimary, ViewContext } from 'components/lib'
import { errorToast, successToast } from 'helpers'
import {
  CHATBOT_AVAILABILITY_OPTIONS,
  compareChatbotConfigs,
  handleTelegramWebHook,
  ignoredFieldPerType,
} from 'helpers/chatbot'
import {
  PUBLISH_STATE_ACTIVE,
  PUBLISH_STATE_PENDING,
  PUBLISH_STATE_UNPUBLISHED,
} from 'helpers/enums'
import {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import {
  chatBotInitialState,
  generateChatBot,
  generateChatBotDraft,
  getChatBotList,
  SelectChatBotInputValues,
  SelectChatBotListData,
  SelectChatBotSettings,
  SelectLoadChatsAsyncStates,
  setChatBotInputValues,
  setClearFields,
  setSelectedChatBotSettings,
  setSelectedChatBotValues,
} from 'store/account/chatBot'
import { DEFAULT_MODAL_DATA, Modal } from 'toolComponents/generic/modal'
import { v4 } from 'uuid'
import ChatActions from './chatActions'
import { ChatbotGeneratorHeader } from './chatbotGeneratorHeader'
import ChatbotPreviewWrapper from './chatbotPreviewWrapper'
import { ChatbotPublishing } from './chatBotPublishing'
import ChatGenerator from './chatGenerator'

// Tour steps
// const steps = [
//   {
//     target: '#selector',
//     content: (
//       <T keyName='eleo-tour-chatbot-1'>
//         Update your Chatbot. If you have previously created a chatbot, you can choose it here for
//         editing.
//       </T>
//     ),
//   },
//   {
//     target: '#name',
//     content: (
//       <T keyName='eleo-tour-chatbot-2'>
//         Choose a name for your Chatbot. This name will also appear in the chatbot link.
//       </T>
//     ),
//   },
//   {
//     target: '#story',
//     content: (
//       <T keyName='eleo-tour-chatbot-3'>
//         Add Your Story. Your Chatbot will draw information about you, your company, or how to behave
//         from the Your Story indicated here.
//       </T>
//     ),
//   },
//   {
//     target: '#advanced',
//     content: (
//       <T keyName='eleo-tour-chatbot-4'>
//         Select additional options. Set the tone, language, introduction, avatar, and Chatbot
//         response length.
//       </T>
//     ),
//   },
//   {
//     target: '#submit',
//     content: (
//       <T keyName='eleo-tour-chatbot-5'>
//         Create or update the Chatbot. You can have multiple different Chatbots running.
//       </T>
//     ),
//   },
//   {
//     target: '#output',
//     content: (
//       <T keyName='eleo-tour-chatbot-6'>
//         Manage your Chatbot. Copy the chatbot link, place the Chatbot on your website or on
//         Telegram.
//       </T>
//     ),
//   },
// ]

const mapChatbotParams = (data, setId = true) => {
  return {
    ...(setId && { selectedChatBot: data.id }),
    avatar: data.avatar,
    language: data.language,
    intro: data.intro,
    monthlyLimit: data.monthlyLimit,
    isMonthlyLimit: data.isMonthlyLimit ?? true,
    name: data.name,
    responseLength: data.responseLength,
    storyId: data.storyId,
    tone: data.tone,
    wordsConsumed: data.wordsConsumed,
    category: data.category,
    voice: data.voice,
    isPublic: data.isPublic,
    modelId: data.modelId,
    displayName: data.displayName,
    shortDescription: data.shortDescription,
    longDescription: data.longDescription,
    allowWebSearch: data.allowWebSearch,
    allowContext: data.allowContext,
    relatedGroups: data?.relatedGroups,
    visibilityOption: data?.visibilityOption,
    availabilityOption: data?.availabilityOption ?? CHATBOT_AVAILABILITY_OPTIONS[0].value,
    author: data.author,
    allowLanguageChange: data.allowLanguageChange,
    allowModeChange: data.allowModeChange,
    defaultMode: data.defaultMode ?? 'text',
    isStartWithAudio: data.isStartWithAudio,
    isStartWithWebSearch: data.isStartWithWebSearch,
    instructions: data.instructions ?? '',
    customUrlName: data.customUrlName ?? null,
    enableCustomUrl: data.enableCustomUrl ?? false,
    customUrls: data.customUrls ?? {
      forum: { enableCustomUrl: false, customUrlName: null },
      public: { enableCustomUrl: false, customUrlName: null },
    },
    forum: data.forum ?? {
      displayName: '',
      shortDescription: '',
      longDescription: '',
      category: 'eleo-chatbot-category-other',
    },
    documents: data.documents,
    stories: data.stories,
    maxConversationLength: data.maxConversationLength ?? {
      forum: 10000,
      public: 10000,
      embed: 10000,
    },
  }
}

const ChatBot = forwardRef(({ handleHideModal, chatbotId, onCreateCallback }, ref) => {
  const params = useSearchParams()[0]
  const dispatch = useDispatch()
  const documentContext = useDocumentContext('chatbot-creator')
  const context = useContext(ViewContext)

  const chatBotInputValues = useSelector(SelectChatBotInputValues)
  const chatBotList = useSelector(SelectChatBotListData)
  const chatBotSettings = useSelector(SelectChatBotSettings)
  const isFetchChatbotlistLoading = useSelector(SelectLoadChatsAsyncStates).loading

  const [folded, setFolded] = useState(false)
  const [uplodLoading, setUploadLoading] = useState(false)
  const [isShowAdvanced, setIsShowAdvanced] = useState(false)
  const [isTourOverride, setIsTourOverride] = useState(false)
  const [isShowPublishing, setIsShowPublishing] = useState(true)
  const [chatbotServerState, setChatbotServerState] = useState()
  const [modalData, setModalData] = useState(DEFAULT_MODAL_DATA)
  const [chatbotDraftServerState, setChatbotDraftServerState] = useState()
  const [publishState, setPublishState] = useState(
    chatbotId ? PUBLISH_STATE_PENDING : PUBLISH_STATE_UNPUBLISHED
  )
  const [loadingState, setLoadingState] = useState({
    publish: false,
    draft: false,
    chatbot: Boolean(chatbotId),
  })

  const isDocumentContextChanged =
    (chatbotServerState &&
      (documentContext.docContext.length !==
        chatbotServerState.inputValues.documents?.length +
          chatbotServerState.inputValues.stories?.length ||
        !documentContext.docContext.every(
          (i1) =>
            chatbotServerState.inputValues.documents?.some((i2) => i2.name === i1.label) ||
            chatbotServerState.inputValues.stories?.some((i2) => i2.name === i1.label)
        ))) ||
    (chatbotDraftServerState &&
      (documentContext.docContext.length !==
        chatbotDraftServerState.inputValues.documents?.length +
          chatbotDraftServerState.inputValues.stories?.length ||
        !documentContext.docContext.every(
          (i1) =>
            chatbotDraftServerState.inputValues.documents?.some((i2) => i2.name === i1.label) ||
            chatbotDraftServerState.inputValues.stories?.some((i2) => i2.name === i1.label)
        )))

  // Update is pending if either:
  // No data from server and the form is not in default state
  // There is data from server and its not matching the form
  // There is data from server and the publishing options dont match
  const isUpdatePending =
    (!chatbotServerState && chatBotInputValues !== chatBotInitialState.inputValues) ||
    isDocumentContextChanged ||
    (chatbotServerState &&
      !compareChatbotConfigs(
        { inputValues: chatBotInputValues, settings: chatBotSettings },
        chatbotServerState,
        ignoredFieldPerType.global
      )[0]) ||
    (chatbotServerState &&
      Object.keys(chatBotSettings.publishing || {}).some(
        (type) => chatBotSettings.publishing[type] !== chatbotServerState.settings.publishing[type]
      ))

  const isDraftSaved = Boolean(
    !isDocumentContextChanged &&
      chatbotDraftServerState &&
      compareChatbotConfigs(
        { inputValues: chatBotInputValues, settings: chatBotSettings },
        chatbotDraftServerState,
        ignoredFieldPerType.global
      )[0] &&
      Object.keys(chatBotSettings.publishing || {}).every(
        (type) =>
          chatBotSettings.publishing[type] === chatbotDraftServerState.settings.publishing[type]
      )
  )

  // Compare current form values to config from server to update the publishing section badges
  useEffect(() => {
    if (chatbotServerState) {
      setPublishState((prev) =>
        Object.fromEntries(
          Object.entries(prev).map(([type, _]) => {
            if (
              !chatbotDraftServerState?.settings.publishing?.[type] &&
              !chatbotServerState?.settings.publishing?.[type]
            )
              return [type, 'unpublished']

            return compareChatbotConfigs(
              chatbotServerState,
              { inputValues: chatBotInputValues, settings: chatBotSettings },
              ignoredFieldPerType[type]
            )[0] && !isDocumentContextChanged
              ? [type, 'active']
              : [type, 'pending']
          })
        )
      )
    }
  }, [
    chatBotInputValues,
    chatbotServerState,
    setPublishState,
    chatBotSettings,
    isDocumentContextChanged,
  ])

  useEffect(() => {
    if (!params.get('publishing') || !chatBotList.length) {
      dispatch(getChatBotList())
      documentContext.clearContext()
    }

    return () => {
      dispatch(setClearFields())
    }
  }, [dispatch])

  const chatbotLoaded = useRef(false)
  useEffect(() => {
    if (!chatbotLoaded.current) {
      if (!chatbotId) {
        chatbotLoaded.current = true
        dispatch(setClearFields())
      } else if (chatBotList.length) {
        chatbotLoaded.current = true
        setTimeout(
          () => {
            setLoadingState((prev) => ({ ...prev, chatbot: false }))
          },
          params.get('publishing') ? 100 : 500
        )
        const requestedChatbot = chatBotList.find((bot) => bot.id === chatbotId)
        if (requestedChatbot) handleSelectBot(requestedChatbot.id)
      }
    }
  }, [chatBotList, chatbotId, dispatch])

  const handleGenerateChatBot = async (saveAsDraft = false, createDuplicate = false, newName) => {
    if (!chatBotInputValues.name?.length) {
      if (saveAsDraft)
        errorToast(
          <T keyName='eleo-chat-bot-name-required-draft'>Enter chatbot name before saving</T>
        )
      else
        errorToast(
          <T keyName='eleo-chat-bot-name-required'>Enter chatbot name before publishing</T>
        )
      return
    }
    if (
      !saveAsDraft &&
      !Object.values(chatBotSettings.publishing || {}).some((type) => type === true)
    ) {
      errorToast(<T keyName='eleo-chat-bot-publishing-type-required'>Select publication type</T>)
      setIsShowPublishing(true)
      return
    }

    if (saveAsDraft) setLoadingState((prev) => ({ ...prev, draft: true }))
    else setLoadingState((prev) => ({ ...prev, publish: true }))

    const newId = v4()

    // Enable telegram when:
    // publishing: telegram - is true
    // There is no server state or the server state has telegram turned off
    let telegramBotUsername
    if (
      !saveAsDraft &&
      chatBotSettings.telegramToken?.length &&
      chatBotSettings.publishing.telegram &&
      (!chatbotServerState?.settings?.publishing.telegram ||
        chatbotServerState?.settings.telegramToken !== chatBotSettings.telegramToken)
    ) {
      telegramBotUsername = await handleTelegramWebHook(
        chatBotSettings.telegramToken,
        true,
        'setWebhook',
        true,
        chatBotInputValues.selectedChatBot || newId
      )
    }
    // Disable telegram if there was a server state where it was turned on
    else if (
      !chatBotSettings.publishing.telegram &&
      chatbotServerState?.settings?.publishing.telegram
    ) {
      await handleTelegramWebHook(chatBotSettings.telegramToken, false, 'deleteWebhook', true)
    }

    if (!saveAsDraft) {
      dispatch(
        generateChatBot({
          ...chatBotInputValues,
          settings: {
            ...chatBotSettings,
            ...(telegramBotUsername && { telegramBotUsername: telegramBotUsername }),
          },
          stories: documentContext.docContext.filter((item) => item.type === 'story'),
          context: documentContext.docContext,
          newId: newId,
        })
      ).then((res) => {
        setLoadingState((prev) => ({ ...prev, publish: false }))

        if (res.type === 'chatBot/generate/fulfilled' && res.payload) {
          onCreateCallback?.()
          setPublishState(PUBLISH_STATE_ACTIVE)

          const { settings, ...inputValues } = res.payload

          dispatch(setSelectedChatBotValues(mapChatbotParams(inputValues, true)))

          setChatbotServerState({ inputValues, settings })
          setChatbotDraftServerState()
        }
      })
    } else {
      dispatch(
        generateChatBotDraft({
          ...chatbotDraftServerState?.inputValues,
          ...chatBotInputValues,
          settings: chatBotSettings,
          stories: documentContext.docContext,
          ...(newName && { name: newName }),
          ...(newName && { id: undefined }),
          selectedChatBot:
            createDuplicate || chatbotDraftServerState ? null : chatBotInputValues.selectedChatBot,
        })
      ).then((res) => {
        setLoadingState((prev) => ({ ...prev, draft: false }))

        if (res.type === 'chatBot/draft/generate/fulfilled' && res.payload) {
          onCreateCallback?.()

          const { settings, ...inputValues } = res.payload
          dispatch(setChatBotInputValues({ field: 'name', value: inputValues.name }))

          dispatch(setSelectedChatBotValues(mapChatbotParams(inputValues, false)))

          setChatbotDraftServerState({ inputValues, settings })
          setChatbotServerState()

          if (createDuplicate) {
            successToast(
              <T keyName='eleo-toast-chatbot-draft-duplicated'>
                Your chatbot has been duplicated as draft. To publish the new draft please change
                the name to be unique.
              </T>
            )
          } else {
            if (chatbotDraftServerState) {
              successToast(<T keyName='eleo-draft-saved'>Draft saved</T>)
            }
          }
        }
      })
    }
  }

  async function handleFileUpload(e, handler, dir) {
    try {
      setUploadLoading(true)

      const url = '/api/utility/upload'
      let data = new FormData()
      data.append('file', e.target.files[0])
      data.append('dir', dir ? dir : 'chatBot/')

      const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
      }

      const res = await axios.post(url, data, config)
      handler(res.data.items[0])
    } catch (err) {
      console.log(err)
    } finally {
      setUploadLoading(false)
    }
  }

  function handleTourProgress(e) {
    if (e.action === 'reset' || e.action === 'close' || e.action === 'stop') {
      setIsTourOverride(false)
      return
    }
    if (!e.action === 'update') return

    const step = e.index
    if (step === 0) setIsTourOverride(true)
  }

  const handleSelectBot = (chatbotId) => {
    let selectedChatbot = chatBotList.find((bot) => bot.id === chatbotId)
    if (!selectedChatbot) return

    const values = mapChatbotParams(selectedChatbot, false)
    dispatch(setSelectedChatBotValues(values))

    dispatch(setSelectedChatBotSettings(selectedChatbot.settings))

    documentContext.clearContext()
    selectedChatbot.stories?.map((story) =>
      documentContext.addDocument({
        label: story.name,
        type: 'story',
        id: story.id,
      })
    )

    selectedChatbot.documents?.map((document) => {
      if (!document) return null
      documentContext.addDocument({
        label: document.name,
        type: document.mimetype === 'url' ? 'website' : 'document',
        id: document.document_id,
      })

      return null
    })

    let botId
    if (selectedChatbot.type === 'draft' && selectedChatbot.originalChatBotId?.length) {
      botId = selectedChatbot.originalChatBotId
    } else botId = selectedChatbot.id

    dispatch(
      setChatBotInputValues({
        field: 'selectedChatBot',
        value: botId,
      })
    )

    if (selectedChatbot.type === 'draft')
      setChatbotDraftServerState({
        inputValues: { ...values, id: selectedChatbot.id },
        settings: selectedChatbot.settings,
      })
    else
      setChatbotServerState({
        inputValues: values,
        settings: selectedChatbot.settings,
      })
  }

  const handleOverrideBot = async (idToOverride, newChatbotId) => {
    if (!idToOverride) return
    const name = chatBotInputValues.name
    const id = chatBotInputValues.selectedChatBot

    try {
      let selectedChatbot = chatBotList.find((bot) => bot.id === newChatbotId)
      if (!selectedChatbot) return

      const values = {
        selectedChatBot: id,
        name: name,
        avatar: selectedChatbot.avatar,
        language: selectedChatbot.language,
        intro: selectedChatbot.intro,
        monthlyLimit: selectedChatbot.monthlyLimit,
        isMonthlyLimit: selectedChatbot.isMonthlyLimit ?? true,
        responseLength: selectedChatbot.responseLength,
        storyId: selectedChatbot.storyId,
        tone: selectedChatbot.tone,
        wordsConsumed: selectedChatbot.wordsConsumed,
        category: selectedChatbot.category,
        voice: selectedChatbot.voice,
        isPublic: selectedChatbot.isPublic,
        modelId: selectedChatbot.modelId,
        displayName: selectedChatbot.displayName,
        shortDescription: selectedChatbot.shortDescription,
        longDescription: selectedChatbot.longDescription,
        allowWebSearch: selectedChatbot.allowWebSearch,
        allowContext: selectedChatbot.allowContext,
        relatedGroups: selectedChatbot?.relatedGroups,
        visibilityOption: selectedChatbot?.visibilityOption,
        availabilityOption:
          selectedChatbot?.availabilityOption ?? CHATBOT_AVAILABILITY_OPTIONS[0].value,
        author: selectedChatbot.author,
        allowLanguageChange: selectedChatbot.allowLanguageChange,
        allowModeChange: selectedChatbot.allowModeChange,
        defaultMode: selectedChatbot.defaultMode ?? 'text',
        isStartWithAudio: selectedChatbot.isStartWithAudio,
        instructions: selectedChatbot.instructions ?? '',
        customUrls: selectedChatbot.customUrls ?? {
          forum: { enableCustomUrl: false, customUrlName: null },
          public: { enableCustomUrl: false, customUrlName: null },
        },
        forum: selectedChatbot.forum ?? {
          displayName: '',
          shortDescription: '',
          longDescription: '',
          category: 'eleo-chatbot-category-other',
        },
        defaultMode: 'text',
      }

      dispatch(setSelectedChatBotValues(values))

      dispatch(setSelectedChatBotSettings(selectedChatbot.settings))

      documentContext.clearContext()
      selectedChatbot.stories?.map((story) =>
        documentContext.addDocument({
          label: story.name,
          type: 'story',
          id: story.id,
        })
      )
    } catch (err) {
      context.handleError(err)
    }
  }

  const handleCloseModal = useCallback(() => {
    if (isUpdatePending && !isDraftSaved) {
      setModalData({
        isVisible: true,
        title: (
          <T
            keyName='eleo-chatbot-modal-unsaved-changes-title'
            defaultValue='You have unsaved changes'
          />
        ),
        subtitle: (
          <T
            keyName='eleo-chatbot-modal-unsaved-changes-subtitle'
            defaultValue='You will lose permamently all the changes you applied.'
          />
        ),
        acceptLabel: <T keyName='eleo-discard-and-close'>Discard and close</T>,
        cancelLabel: <T keyName='eleo-back'>Back</T>,
        type: 'unsaved-changes',
        context: {},
      })
    } else handleHideModal()
  }, [handleHideModal, isDraftSaved, isUpdatePending])

  const generatorLayout = (
    <ChatGenerator
      isShowAdvanced={isTourOverride || isShowAdvanced}
      setIsShowAdvanced={setIsShowAdvanced}
      isShowPublishing={isShowPublishing}
      setIsShowPublishing={setIsShowPublishing}
      handleGenerateChatBot={handleGenerateChatBot}
      handleFileUpload={handleFileUpload}
      documentContext={documentContext}
      publishState={publishState}
      setPublishState={setPublishState}
      chatbotServerState={chatbotServerState}
      chatbotDraftServerState={chatbotDraftServerState}
      isEditMode={Boolean(chatbotServerState || chatbotDraftServerState)}
      loadingState={loadingState}
      handleResetToServerState={() =>
        (chatbotServerState || chatbotDraftServerState) &&
        handleSelectBot(chatBotInputValues.selectedChatBot)
      }
      isUpdatePending={isUpdatePending}
      isDraftSaved={isDraftSaved}
      setModalData={setModalData}
      isDraft={Boolean(chatbotDraftServerState)}
    />
  )

  const previewLayout = (
    <ChatbotPreviewWrapper
      chatBotSettings={chatBotSettings}
      chatBotInputValues={chatBotInputValues}
      isDraft={Boolean(chatbotDraftServerState)}
      isReady={Boolean(chatbotDraftServerState || chatbotServerState)}
      chatbotContext={documentContext}
    />
  )

  const publishingLayout = (
    <div className='flex h-full flex-col items-center justify-between pt-2'>
      <div className='h-[calc(100%-73px)] w-full overflow-x-clip overflow-y-scroll'>
        <div className='flex justify-center'>
          <ChatbotPublishing
            isPanelLarge={true}
            isShowPublishing={true}
            publishState={publishState}
            setPublishState={setPublishState}
            chatbotServerState={chatbotServerState}
            chatbotDraftServerState={chatbotDraftServerState}
            handleFileUpload={handleFileUpload}
            setModalData={setModalData}
            isUpdatePending={isUpdatePending}
            isDraftSaved={isDraftSaved}
            handleGenerateChatBot={handleGenerateChatBot}
          />
        </div>
      </div>
      <div className='writer-actions bg-brand-grey-bg border-brand-gray-light sticky bottom-0 z-[300] flex w-full items-center gap-[10px] border-t p-4'>
        <ChatActions
          handleGenerateChatBot={handleGenerateChatBot}
          isEditMode={Boolean(chatbotServerState || chatbotDraftServerState)}
          loadingState={loadingState}
          handleResetToServerState={() =>
            (chatbotServerState || chatbotDraftServerState) &&
            handleSelectBot(chatBotInputValues.selectedChatBot)
          }
          isUpdatePending={isUpdatePending}
          isDraftSaved={isDraftSaved}
          isDraft={Boolean(chatbotDraftServerState)}
          setModalData={setModalData}
        />
      </div>
    </div>
  )

  useImperativeHandle(ref, () => {
    return {
      handleCloseModal() {
        handleCloseModal()
      },
    }
  }, [handleCloseModal])

  if (loadingState.chatbot || (isFetchChatbotlistLoading && !chatBotList.length)) return <Loader />

  return (
    <div className={classNames('relative flex h-full flex-col')}>
      {/* Header */}
      <ChatbotGeneratorHeader
        handleHideModal={handleCloseModal}
        chatbotId={chatBotInputValues?.selectedChatBot || chatbotId}
        handleSelectBot={handleSelectBot}
        isDraft={Boolean(chatbotDraftServerState)}
      />

      <div className='h-[calc(100%-57px)]'>
        <ToolLayoutPrimary
          breakpoint={520}
          useTabularLayout
          folded={folded}
          setFolded={setFolded}
          storageKey='chatbot'
          maxPanelWidth={800}
          minPanelWidth={350}
          leftSideContent={generatorLayout}
          rightSideContent={previewLayout}
          tabs={{
            form: {
              title: <T keyName='eleo-chatbot-creation-tab-form'>Settings</T>,
              component: generatorLayout,
            },
            preview: {
              title: <T keyName='eleo-chatbot-creation-tab-preview'>Preview</T>,
              component: previewLayout,
            },
            publishing: {
              title: (
                <div className='flex items-center gap-[3px] '>
                  <div className>
                    <T keyName='eleo-chatbot-creation-tab-publishing'>Publishing</T>
                  </div>
                  {Object.keys(publishState).some(
                    (type) =>
                      publishState[type] === 'unpublished' && chatBotSettings.publishing?.[type]
                  ) && <div className='bg-brand-pink size-3 rounded-full [&:nth-child(2)]:ml-1' />}
                  {Object.keys(publishState).some(
                    (type) => publishState[type] === 'pending' && chatBotSettings.publishing?.[type]
                  ) && <div className='size-3 rounded-full bg-[#F38F2B] [&:nth-child(2)]:ml-1' />}
                </div>
              ),
              component: publishingLayout,
            },
          }}
        />
      </div>

      <Modal
        isVisible={modalData.isVisible}
        containerClasses='bg-black bg-opacity-[3%]'
        hideModal={(shouldSave) => {
          setModalData(DEFAULT_MODAL_DATA)

          if (shouldSave && modalData.type !== 'override') handleHideModal()
        }}
        callback={() => {
          if (modalData.type === 'override') {
            handleOverrideBot(chatBotInputValues.selectedChatBot, modalData.context.id)
          }
        }}
        title={modalData.title}
        subtitle={modalData.subtitle}
        acceptLabel={modalData.acceptLabel}
        cancelLabel={modalData.cancelLabel}
        isValid
        isPrimary
        width={450}
      />
      <HelpTooltips tool='chatBotPanel' />
      {/* <Tour steps={steps} name='chatbot' callback={handleTourProgress} /> */}
      <ContextModal documentContext={documentContext} />
    </div>
  )
})

export default ChatBot
