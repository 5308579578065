import { RecordVoiceOver } from '@mui/icons-material'
import { Popover } from '@mui/material'
import { T } from '@tolgee/react'
import classNames from 'classnames'
import { useState } from 'react'
import { ListPosition, Microbutton } from 'ui'

const options1 = [
  {
    value: 'text',
    label: <T keyName='eleo-chat-mode-option-text-label'>Text chat</T>,
    shortLabel: <T keyName='eleo-chat-mode-option-text-short-label'>Text</T>,
  },
  {
    value: 'read',
    label: <T keyName='eleo-chat-mode-option-read-label'>Read answers aloud</T>,
    shortLabel: <T keyName='eleo-chat-mode-option-read-short-label'>Read</T>,
  },
]

const options2 = [
  {
    value: 'voice',
    label: <T keyName='eleo-chat-mode-option-voice-label'>Voice chat</T>,
    shortLabel: <T keyName='eleo-chat-mode-option-voice-short-label'>Voice</T>,
  },
  {
    value: 'push',
    label: <T keyName='eleo-chat-mode-option-ptt-label'>Push to talk</T>,
    shortLabel: <T keyName='eleo-chat-mode-option-ptt-short-label'>Push</T>,
  },
]

export default function ModeSelector({ inputMode, setInputMode }) {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const renderOption = (option) => (
    <label
      key={option.value}
      htmlFor={option.value}
      className='text-brand-violet hover:bg-brand-violet/10 cursor-pointer rounded-[4px] p-[6px] transition-colors'
    >
      <div className='flex items-center gap-2'>
        <div className='relative flex items-center justify-center'>
          <input
            id={option.value}
            type='radio'
            name={option.value}
            className='border-brand-violet text-brand-violet peer relative h-3 w-3 cursor-pointer appearance-none rounded-full border transition-all '
            value={option.shortLabel}
            checked={inputMode === option.value}
            onChange={() => {
              handleClose()
              setInputMode(option.value)
            }}
          />
          <span className='text-brand-violet pointer-events-none absolute left-2/4 top-2/4 -translate-x-2/4 -translate-y-2/4 opacity-0 transition-opacity peer-checked:opacity-100'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='size-[6px]'
              viewBox='0 0 16 16'
              fill='currentColor'
            >
              <circle data-name='ellipse' cx='8' cy='8' r='8'></circle>
            </svg>
          </span>
        </div>
        <div className={classNames('text-[13px]', inputMode === option.value && 'font-medium')}>
          {option.label}
        </div>
      </div>
    </label>
  )

  const open = Boolean(anchorEl)
  const popoverId = open ? 'context-popover' : undefined

  return (
    <>
      <Microbutton
        iconPlacement='none'
        variant={'transparent'}
        onClick={handleClick}
        className={classNames('!bg-brand-form-accent hover:!bg-brand-violet h-[30px]')}
      >
        <div className='flex items-center justify-between gap-2 text-[12px] font-medium'>
          <span className='group flex min-w-[15px] text-[14px]'>
            <RecordVoiceOver
              fontSize='inherit'
              className='fill-brand-violet group-hover:fill-white'
            />
          </span>
          <T keyName='eleo-chat-mode-label'>Mode:</T>
          <div className='flex items-center gap-1'>
            <div className='max-w-[75px] truncate'>
              {[...options1, ...options2].find((option) => option.value === inputMode).shortLabel}
            </div>
          </div>
        </div>
      </Microbutton>
      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <div className={classNames('flex w-[200px] flex-col rounded-[4px] bg-white p-2')}>
          {options1.map((option) => renderOption(option))}

          <div className='my-[7px] border-b px-2' />

          {options2.map((option) => renderOption(option))}
        </div>
      </Popover>
    </>
  )
}
