/***
 *
 *   SIGN UP STEP 2
 *   Signup form for account owners
 *   Step 1: create account
 *   Step 2: verify email address
 *   Step 3: select plan
 *
 **********/
import { Check } from '@mui/icons-material'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { useTranslate } from '@tolgee/react'
import { default as axios } from 'axios'
import classNames from 'classnames'
import { useWindowDimensions } from 'components/hooks/window'
import {
  Animate,
  AuthContext,
  CardInput,
  Event,
  ViewContext,
  useNavigate,
  usePlans,
} from 'components/lib'
import { DEFAULT_PAGE } from 'constants'
import { getLandingUrl, successToast } from 'helpers'
import parse from 'html-react-parser'
import { CircleNotch } from 'phosphor-react'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Button, CheckBox, Input, Toggle } from 'ui'

export function SignupPlan() {
  const stripe = useStripe()
  const elements = useElements()
  const authContext = useContext(AuthContext)
  const context = useContext(ViewContext)
  const navigate = useNavigate()
  const [params, setParams] = useSearchParams()
  const plans = usePlans()
  const { t } = useTranslate()
  const { width } = useWindowDimensions()

  const requestedPlan = params.get('plan')

  const [selectedPlan, setSelectedPlan] = useState()
  const [period, setPeriod] = useState('month')
  const [isCardValid, setIsCardValid] = useState(true)
  const [isLoading, setIsLoading] = useState({ submit: false, discount: false })
  const [isDiscountCode, setIsDiscountCode] = useState(false)
  const [discountCodeName, setDiscountCodeName] = useState('')
  const [discountCode, setDiscountCode] = useState({
    id: null,
    discount_percent: 0,
    discount_flat: 0,
    error: false,
  })

  useEffect(() => {
    // Redirect users who already selected a plan
    if (authContext.user?.plan?.name) {
      if (requestedPlan) {
        navigate(`/account/upgrade?plan=${requestedPlan}`)
        return
      }
      navigate(DEFAULT_PAGE)
    }
  }, [navigate, authContext.user?.plan, requestedPlan])

  useEffect(() => {
    if (selectedPlan?.name === 'Free') setPeriod('month')
    else {
      const cardElement = elements?.getElement(CardElement)
      cardElement?.focus()
    }
  }, [selectedPlan, elements])

  const next = params.get('next')
  const plansData = useMemo(
    () =>
      plans.data?.raw?.plans?.map((plan) => ({
        id: plan.id,
        name: plan.name,
        trial: plan.trial,
        isTeam: plan.isTeam,
        tolgeeId: plan.tolgee_id,
        price: plan.pricing[period].price / (period === 'year' ? 12 : 1), // Calculate monthly price
      })),
    [period, plans.data]
  )
  const getCouponMessage = () => {
    if (discountCode.error) return t('eleo-discount-error', 'This code is invalid')
    if (discountCode.discount_percent) {
      return t(
        'eleo-discount-percent-description',
        'You will receive <span className="font-bold">{discount}</span> for the first transaction, which is <span className="font-bold">$0</span> for the selected plan. Pick an annual plan to gain more!',
        {
          discount: `${discountCode.discount_percent}%`,
          amountReduced:
            ((selectedPlan?.price * discountCode.discount) / 100) * (period === 'year' ? 12 : 1),
        }
      )
    }
    if (discountCode.discount_flat) {
      return t(
        'eleo-discount-percent-flat-description',
        'You will receive <span className="font-bold">{discount}</span> off for the first transaction',
        {
          discount: `$${discountCode.discount_flat / 100}`,
        }
      )
    }

    return ''
  }

  const couponMessage = getCouponMessage()

  // Update selected plan when period changes
  useEffect(() => {
    if (!plansData) return
    if (!selectedPlan)
      setSelectedPlan(
        plansData.find((plan) => plan.name.toLowerCase() === (requestedPlan ?? 'standard'))
      )
    setSelectedPlan((prev) => plansData.find((plan) => plan.id === prev?.id))
  }, [period, plansData, selectedPlan, requestedPlan])

  function handleChangePeriod(val) {
    if (val) return setPeriod('year')
    setPeriod('month')
  }

  async function handleCheckDiscountCode() {
    if (!discountCodeName) return
    setIsLoading((prev) => ({ ...prev, discount: true }))

    try {
      const res = await axios.get(`/api/utility/promotions/${discountCodeName}`)
      if (res.data.code)
        return setDiscountCode({
          id: res.data.code,
          discount_percent: res.data.percent_off,
          discount_flat: res.data.amount_off,
          error: false,
        })

      setDiscountCode({ id: 'ERROR', discount: 0, error: true })
    } catch (err) {
      context.handleError(err)
    } finally {
      setIsLoading((prev) => ({ ...prev, discount: false }))
    }
  }

  async function handleSubmit(e) {
    e?.preventDefault()
    setIsLoading((prev) => ({ ...prev, submit: true }))

    const data = {
      period: period,
      plan: selectedPlan?.id,
    }

    // handle paid plans
    if (selectedPlan?.id !== 'free') {
      // Card info
      const cardElement = await elements.getElement(CardElement)
      const token = await stripe.createToken(cardElement)
      if (token.error) setIsCardValid(false)

      data.token = { id: token.token?.id }

      // Discount code
      if (isDiscountCode && !discountCode.error && discountCode?.id !== 'ERROR')
        data.promo = discountCode?.id
    }

    try {
      const url = selectedPlan.name === 'Pro' ? '/api/account/plan' : '/api/account/plan/trial'
      let res = await axios.post(url, data)

      // Handle additional stripe steps
      if (res.data.requires_payment_action) {
        let stripeRes
        if (res.data.type === 'setup')
          stripeRes = await stripe.confirmCardSetup(res.data.client_secret)
        else stripeRes = await stripe.handleCardPayment(res.data.client_secret)

        if (stripeRes.error) {
          setIsLoading((prev) => ({ ...prev, submit: false }))
          context.handleError(stripeRes.error.message)
          return false
        } else {
          // re-send the form
          data.stripe = res.data

          // If a payment method was prevalidated send it back
          if (res.data.type === 'setup' && stripeRes.setupIntent?.payment_method)
            data.payment_method = stripeRes.setupIntent.payment_method

          res = await axios.post(url, data)
        }
      }

      Event('selected_plan', { plan: res.data.plan.name })
      window.gtag('event', 'sign_up', {
        method: authContext.user?.social_token ? 'social' : 'email',
        plan: res.data.plan.name,
      })

      authContext.update({
        plan: res.data.plan,
        subscription: res.data.subscription,
      })
      const isTeamPlan = res.data.plan.name === 'Team'
      navigate(next && next !== 'null' ? `/${next}` : isTeamPlan ? '/account/users' : DEFAULT_PAGE)
      if (res.data.received_promo)
        successToast(
          t(
            'eleo-plan-upgraded-for-free',
            'Your plan has been upgraded to Standard for free! You can use the advanced functionalities for a week.'
          )
        )
    } catch (err) {
      console.log('🚀 ~ handleSubmit ~ err:', err)
      if (err.response.status === 403) {
        successToast(
          t(
            'eleo-error-plan-already-selected',
            'It seems you have already selected your plan. If you want to change it you can do it in the plans tab'
          )
        )
        authContext.update({
          plan: err.response.data.plan,
          subscription: err.response.data.subscription,
        })
        navigate(DEFAULT_PAGE)
      } else {
        context.handleError(err)
      }
    } finally {
      setIsLoading((prev) => ({ ...prev, submit: false }))
    }
  }

  const planTile = (plan, isSingle = false) => {
    return (
      <div
        key={plan.id}
        className={classNames(
          'border-brand-grey flex cursor-pointer justify-between rounded-[10px] border p-[10px] pl-[14px] transition-colors duration-[400ms]',
          selectedPlan?.id === plan.id
            ? 'bg-brand-violet text-white shadow-[0px_0px_10px_0px_rgba(0,0,0,0.25)]'
            : 'hover:bg-brand-hover',
          isSingle && 'col-span-2'
        )}
        onClick={() => setSelectedPlan(plan)}
      >
        <div>
          <p className='text-[18px] font-bold uppercase leading-[1.2em] tracking-[0.02em]'>
            {plan.isTeam ? 'Pro' : plan.name}{' '}
            {plan.isTeam && <span className='font-light'>Team</span>}
          </p>
          <p className='text-[14px] font-normal leading-[1.1em] opacity-80'>
            {t(`eleo-plan-summary-${plan.tolgeeId}`, `$${plan.price}`, {
              pricePerMonth: plan.price,
            })}
          </p>
        </div>
        {plan.trial && (
          <div className='flex items-center'>
            <p
              className={classNames(
                'bg-gradient-to-tr  bg-clip-text text-end text-[12px] font-semibold uppercase leading-[1.1em] text-transparent',
                selectedPlan?.name === plan.name
                  ? 'from-[#99D5FF] to-[#EFBFFF]'
                  : 'from-[#1CA0FF] to-[#CE3BFF]'
              )}
            >
              {parse(t('eleo-7-day-trial', '7 days <br /> free trial'))}
            </p>
          </div>
        )}
      </div>
    )
  }

  return (
    <Animate type='pop'>
      <div className='flex justify-center border-y py-5 sm:py-10'>
        <div className='w-[min(420px,95%)] space-y-[20px] sm:w-[616px] sm:space-y-[30px]'>
          {/* Header */}
          <div className='mx-[16px] grid grid-cols-1 gap-[12px] sm:mx-[40px] sm:grid-cols-2'>
            <strong className='flex-1 text-center text-[26px] font-black leading-[1.2em] sm:text-start'>
              {t('eleo-select-plan', 'Select the plan')}
            </strong>
            <div
              className={classNames(
                'flex flex-1 items-center justify-center gap-2 sm:justify-end',
                selectedPlan?.name === 'Free' && 'pointer-events-none opacity-70'
              )}
            >
              <p className='text-[14px] leading-[1.1em]'>
                {t('eleo-payment-frequency-month', 'Monthly')}
              </p>
              <Toggle
                type='simple'
                setIsChecked={handleChangePeriod}
                isChecked={period === 'year'}
                disabled={selectedPlan?.name === 'Free'}
              />
              <p className='text-[14px] leading-[1.1em]'>
                {t('eleo-payment-frequency-year', 'Annually')}
              </p>
            </div>
          </div>

          {/* Container */}
          <div className='rounded-[20px] sm:p-[10px] sm:shadow-[0px_0px_10px_0px_rgba(0,0,0,0.25)]'>
            <div className='rounded-[10px] bg-white px-[10px] py-[20px] shadow-[0px_0px_10px_0px_rgba(0,0,0,0.25)] sm:p-[30px] sm:px-[20px]'>
              {/* Plans */}
              <div className='grid grid-cols-1 gap-[16px] sm:grid-cols-2 '>
                {plansData && selectedPlan ? (
                  requestedPlan ? (
                    planTile(selectedPlan, true)
                  ) : (
                    plansData?.map((plan) => planTile(plan))
                  )
                ) : (
                  <div className='col-span-2 flex h-72 items-center justify-center sm:h-36'>
                    <CircleNotch weight='fill' color='#6363E5' size={24} className='animate-spin' />
                  </div>
                )}
              </div>

              {/* Card input */}
              <div
                className={classNames(
                  'relative flex items-end overflow-clip transition-all duration-[400ms] ease-out',
                  selectedPlan?.name === 'Free'
                    ? 'h-0'
                    : {
                        'h-[90px] sm:h-[100px]': isCardValid,
                        'h-[126px] sm:h-[136px]': !isCardValid,
                      }
                )}
              >
                <div
                  className={classNames(
                    'absolute top-[20px] w-full space-y-[2px] transition-transform duration-500 ease-out sm:top-[30px]',
                    selectedPlan?.name === 'Free' && '-translate-y-[35px]'
                  )}
                >
                  <label className='px-[6px] text-[13px] font-medium leading-[1.2em]'>
                    {t('eleo-your-credit-card-number', 'Your credit card number')}
                    <span className='text-brand-violet'> *</span>
                  </label>
                  <CardInput valid={isCardValid} onFocus={() => setIsCardValid(true)} />
                </div>
              </div>

              {/* Spacer */}
              <div className='mt-[20px] w-full border-b sm:mt-[30px]'></div>
              {/* Discount code */}
              <div
                className={classNames(
                  'space-y-[10px] overflow-clip transition-all duration-[400ms] sm:mt-[30px] sm:h-auto sm:duration-300',
                  selectedPlan?.name === 'Free'
                    ? 'h-0 sm:h-auto sm:opacity-0'
                    : {
                        'mt-[20px]': true,
                        'h-5': !isDiscountCode,
                        'h-[140px]': isDiscountCode && !discountCode.error && discountCode.id,
                        'h-[105px]': isDiscountCode && (discountCode.error || !discountCode.id),
                      }
                )}
              >
                {/* Checkbox */}
                <div className='flex items-center gap-x-[10px] px-[6px]'>
                  <CheckBox
                    id='couponCheckbox'
                    checkBoxValue={isDiscountCode}
                    setChecked={({ checked }) => setIsDiscountCode(checked)}
                  />
                  <label
                    htmlFor='couponCheckbox'
                    className='cursor-pointer text-[14px] leading-[1.1em]'
                  >
                    {t('eleo-i-have-discount', 'I have a discount code')}
                  </label>
                </div>

                <div
                  className={classNames(
                    ' transition-all duration-300 sm:h-auto sm:min-h-24',
                    !isDiscountCode
                      ? 'h-0 opacity-0'
                      : {
                          'h-28': !discountCode.error && discountCode.id,
                          'h-20': discountCode.error || !discountCode.id,
                        }
                  )}
                >
                  {/* Input */}
                  <div
                    className={classNames(
                      'relative rounded-[10px] bg-opacity-10 ',
                      // !isDiscountCode ? 'opacity-0' : ``,
                      discountCode.id && (discountCode.error ? 'bg-[#FA47411A]' : 'bg-[#00C19C]')
                    )}
                  >
                    <Input
                      className='h-[38px] w-full !rounded-[10px] bg-white'
                      placeholder=''
                      value={discountCodeName}
                      onChange={(val) => {
                        setDiscountCodeName(val)
                        setDiscountCode((prev) => ({
                          id: null,
                          discount: prev.discount,
                          error: prev.error,
                        }))
                      }}
                    />
                    <Button
                      color={discountCode.id ? 'green' : 'violet'}
                      className='absolute right-0 top-0 h-[38px] !px-4 !text-[14px] uppercase transition-all duration-300 ease-in-out'
                      onClick={handleCheckDiscountCode}
                      disabled={discountCode.id?.toUpperCase() === discountCodeName.toUpperCase()}
                    >
                      <>
                        {isLoading.discount && (
                          <CircleNotch
                            weight='fill'
                            color='#FFFFFF'
                            size={24}
                            className='mr-1 animate-spin'
                          />
                        )}
                        <div className={classNames(!isLoading.discount && 'px-[14px]')}>
                          {discountCode.id && discountCode.id !== 'ERROR' ? (
                            <>
                              <Check fontSize='small' />
                              <span className='ml-1'>{t('eleo-applied', 'Applied')}</span>
                            </>
                          ) : width > 768 ? (
                            t('eleo-apply-code', 'Apply code')
                          ) : (
                            t('eleo-apply', 'Apply')
                          )}
                        </div>
                      </>
                    </Button>

                    {/* Discount description */}
                    <div
                      className={classNames(
                        'p-[10px] text-[14px] font-normal leading-[1.2em] transition-opacity duration-300',
                        discountCode.error ? 'text-[#FA4741]' : 'text-[#00A383]',
                        !discountCode.id && 'opacity-0'
                      )}
                    >
                      {parse(couponMessage)}
                    </div>
                  </div>
                </div>
              </div>

              {/* Submit button */}
              <Button
                color='violet'
                className='mt-[20px] h-12 w-full !text-[15px] !font-medium uppercase leading-[1.2em] sm:mt-[30px]'
                onClick={handleSubmit}
                disabled={isLoading.submit}
              >
                {isLoading.submit ? (
                  <CircleNotch weight='fill' color='#FFFFFF' size={24} className='animate-spin' />
                ) : (
                  t('eleo-go-to-app', 'Save and go to app')
                )}
              </Button>
            </div>
          </div>

          <div className='flex items-center justify-center gap-[10px] text-[14px]'>
            <a
              href={getLandingUrl() + `/plans?period=${period}`}
              target='_blank'
              rel='noreferrer'
              className='text-brand-violet underline'
            >
              {t('eleo-plans-overview', 'Features and plans overview')}
            </a>

            {requestedPlan && (
              <>
                <div className='text-brand-violet'>|</div>
                <button
                  className='text-brand-violet underline'
                  onClick={() => {
                    const newParams = new URLSearchParams(params)
                    newParams.delete('plan')
                    setParams(newParams)
                  }}
                >
                  {t('eleo-plans-select-different-plan', 'Select different plan')}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </Animate>
  )
}
