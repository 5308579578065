import { History, ImportExport } from '@mui/icons-material'
import { T, useTranslate } from '@tolgee/react'
import classNames from 'classnames'
import { useAccess } from 'components/hooks/access'
import { useLanguages } from 'components/hooks/language'
import { useWindowDimensions } from 'components/hooks/window'
import { useYourStory } from 'components/hooks/yourStory'
import { ViewContext } from 'components/lib'
import { transformSelectOptionValue } from 'helpers'
import aiController from 'helpers/AIController'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import UpgradePlan from 'toolComponents/writer/UpgradePlan'
import { ModelSelector, ReactSelect, Toggle } from 'ui'

export default function TranslatorSettings({
  state,
  dispatch,
  showHistory,
  setShowHistory,
  handleFlipLang,
}) {
  const navigate = useNavigate()
  const { t } = useTranslate()
  const { width } = useWindowDimensions()
  const { formatted: stories } = useYourStory()
  const { languages } = useLanguages()
  const hasAccess = useAccess()
  const context = useContext(ViewContext)

  function handleShowHistory() {
    setShowHistory((prev) => !prev)
  }

  const handleChangeModel = async (model) => {
    if (model.availableFrom && !hasAccess(model.availableFrom)) {
      context.modal.show({
        children: <UpgradePlan availableFrom={model.availableFrom} />,
        modalCardClassNames: '!bg-transparent !px-[0px] !py-[0px]',
      })
      return
    }

    dispatch({ type: 'SET_MODEL', payload: model })
  }

  return (
    <div
      className='mb-6 grid gap-y-2 px-4 py-5 lg:mb-0'
      style={
        width > 1280 && !(width < 1480 && showHistory)
          ? { gridTemplateColumns: '1fr auto 1fr' }
          : {}
      }
    >
      <div
        className={classNames('flex items-center', showHistory ? 'justify-end' : 'justify-between')}
      >
        {!showHistory && (
          <div
            id='history'
            data-tooltip-id='translator-history'
            className='flex cursor-pointer items-center gap-1 text-[13px] uppercase text-[#656565]'
            onClick={handleShowHistory}
          >
            <History />
            <T keyName='translator-history-header'>History</T>
          </div>
        )}

        <ModelSelector
          id='model-selector'
          models={aiController.getSupportedTextModels({
            includeOnline: false,
            includeReasoning: false,
            toolName: 'translator',
          })}
          model={state.model}
          handleModelChange={handleChangeModel}
          translations={{
            buttonLabel: t('eleo-chat-settings-model', 'Model'),
            noImages: t('eleo-chat-settings-model-no-images', "doesn't support images"),
            defaultModel: t('eleo-chat-settings-model-default', 'dafault'),
            popupTitle: t('eleo-chat-settings-model-popup-title', 'Select AI model'),
            popupDescription: (
              <T
                keyName='eleo-chat-settings-model-popup-description'
                params={{
                  a: (
                    // eslint-disable-next-line jsx-a11y/anchor-has-content
                    <a
                      target='_blank'
                      rel='noreferrer'
                      href='https://news.eleo.ai/which-language-model-to-choose'
                      className='text-brand-violet font-medium opacity-100'
                    />
                  ),
                  span: <span className='opacity-70' />,
                }}
                defaultValue="<span>Don't know how to choose the model? Give them a try or read more</span> <a>here</a>"
              ></T>
            ),
          }}
          className='!w-full'
          origin='bottom'
        />
      </div>

      <div id='language' className='mb-3 flex flex-wrap content-center justify-center lg:mb-0'>
        <div
          className='flex justify-end align-middle lg:w-52'
          data-tooltip-id='translator-from'
          data-tooltip-place='bottom'
        >
          <span className='text-brand-violet mr-2 hidden flex-1 items-center justify-end lg:flex'>
            <T keyName='eleo-tool-translator-from'>From</T>
          </span>
          <div className='w-32 lg:w-40'>
            <ReactSelect
              options={languages}
              value={transformSelectOptionValue(languages, state.inputLanguage)}
              setSelectedValue={(val) =>
                dispatch({
                  type: 'SET_INPUT_LANGUAGE',
                  payload: val.value,
                })
              }
              // defaultValue={{ value: 'Polish', label: 'Polish' }}
            />
          </div>
        </div>

        <div className='mx-3 flex flex-col justify-center'>
          <button onClick={handleFlipLang} className='bg-brand-grey rounded-lg p-2'>
            <ImportExport className='rotate-90' color='#363636' size={24} />
            {/* <Icon image="repeat" size="24" /> */}
          </button>
        </div>

        <div
          className='flex text-start align-middle lg:w-52'
          data-tooltip-id='translator-to'
          data-tooltip-place='bottom'
        >
          <span className='text-brand-violet mr-2 hidden items-center lg:flex'>
            <T keyName='eleo-tool-translator-to'>To</T>
          </span>
          <div className='w-32 lg:w-40'>
            <ReactSelect
              options={languages}
              value={transformSelectOptionValue(languages, state.outputLanguage)}
              setSelectedValue={(val) =>
                dispatch({
                  type: 'SET_OUTPUT_LANGUAGE',
                  payload: val.value,
                })
              }
              defaultValue={{ value: 'auto', label: 'auto' }}
              menuAlignment='right'
            />
          </div>
        </div>
      </div>

      <div className='flex justify-between'>
        {/* Write as native */}
        <div id='native' className='flex items-center' data-tooltip-id='translator-writeAsNative'>
          <Toggle
            isChecked={state.isNative}
            setIsChecked={() =>
              dispatch({
                type: 'SET_IS_NATIVE',
                payload: !state.isNative,
              })
            }
          />
          <label className='text-brand-violet mx-3 text-sm font-semibold'>
            <T keyName='eleo-tool-translator-revise-as-native'>Revise as native</T>
          </label>
        </div>

        {/* Your story */}
        <div id='story' className='w-40' data-tooltip-id='translator-yourStory'>
          <ReactSelect
            isClearable
            clearLabel={t('eleo-clear-button-label-none', 'None')}
            placeHolder={<T keyName='eleo-feature-your-story'>Your Story</T>}
            options={stories}
            value={state.yourStory}
            setSelectedValue={(val) =>
              dispatch({
                type: 'SET_YOUR_STORY',
                payload: transformSelectOptionValue(stories, val.value),
              })
            }
            menuAlignment='right'
            action={() => navigate('/account/your-story')}
            actionLabel={t('eleo-add', 'Add')}
            menuMinWidth={200}
          />
        </div>
      </div>
    </div>
  )
}
