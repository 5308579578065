/***
 *
 *   ANIMATE
 *   Wrapper component to animate in children
 *
 *   PROPS
 *   type: slideup, slidedown, pop (default: slideup)
 *   timeout: timeout (optional, default: 300)
 *   children: children to render
 *
 **********/

import { CSSTransition } from 'react-transition-group'
import './animate.scss'
import classNames from 'classnames'
import { useRef } from 'react'

export function Animate(props) {
  const type = props.type || 'slideup'
  const animateClassNames = classNames('animate h-full rounded-b-[6px]', type, props.classOverrides)
  const transitionRef = useRef()

  return (
    <CSSTransition
      nodeRef={transitionRef}
      in
      appear
      timeout={props.timeout || 300}
      classNames={classNames('animate', type)}
    >
      <div ref={transitionRef} className={animateClassNames}>
        {props.children}
      </div>
    </CSSTransition>
  )
}
