import { AutoAwesome, ChevronLeft, ChevronRight } from '@mui/icons-material'
import { T, useTranslate } from '@tolgee/react'
import classNames from 'classnames'
import { ContextMenu } from 'components/context-menu'
import { ContextField } from 'components/document-context/ContextField'
import { FieldDescription } from 'components/field-description'
import { useAccess } from 'components/hooks/access'
import { useLanguages } from 'components/hooks/language'
import usePanelSize from 'components/hooks/panelSize'
import { transformReadyMadeTemplatesData, transformSelectOptionValue } from 'helpers'
import aiController from 'helpers/AIController'
import { useState } from 'react'
import MoreOptions from 'toolComponents/generic/more-options'
import { ButtonGroup, CheckBox, Input, Microbutton, ReactSelect, Toggle } from 'ui'
import WriterLengthContainer from '../WriterLengthContainer'
import {
  AuthorInputData,
  Creativity,
  FormatInputData,
  ToneInputData,
  WritingStyleInputData,
  checkboxData,
} from '../writer-options-data'
import { readyMadeTemplates } from '../writer-options-data/ready-made-templates'

export default function WriterOptions({
  visible,
  dispatch,
  actionTypes,
  checkBoxValues,
  setCheckBoxValues,
  setWordLength,
  setTopic,
  setWriterContext,
  setKeyWords,
  wordLength,
  setWriterLengthOption,
  writerLengthOption,
  setCreativity,
  creativity,
  isOptimizedForSeoEnabled,
  setIsOptimizedForSeoEnabled,
  optionsData,
  templateOptions,
  setTemplateId,
  showUpgradePlanModal,
  templateId,
  handleDeleteTemplate,
  isShowAdvanced,
  setIsShowAdvanced,
  contextPlaceHolder,
  topicPlaceHolder,
  isGuruModeEnabled,
  setIsGuruModeEnabled,
  handleClearTemplate,
  documentContext,
  model,
  setModel,
  setPlan,
}) {
  const { t } = useTranslate()
  const isPanelLarge = usePanelSize('writer')
  const [isEnhance, setIsEnhance] = useState(false)

  const hasAccess = useAccess()
  const allowStandard = hasAccess('Standard')

  const { defaultToolOutPutLanguage, languages } = useLanguages()

  const readyMadeTemplatesData = transformReadyMadeTemplatesData(readyMadeTemplates, t)

  const handleTopicChange = ({ value, name }) => {
    dispatch({ type: actionTypes[`SET_${name}`], payload: value })
  }

  const handleCheckBoxValues = ({ checkBoxName, checked }) => {
    const existingItem = checkBoxValues?.find((item) => item.name === checkBoxName)

    if (!existingItem) {
      setCheckBoxValues((prev) => [...prev, { name: checkBoxName, checked }])
    } else {
      setCheckBoxValues((items) => {
        const values = [...items]
        return values.filter((item) => item.name !== checkBoxName)
      })
    }
  }

  const handleRadioButtonCheck = ({ radioBoxName, checked }) => {
    setWordLength({ id: radioBoxName, checked })
  }

  const handleSelectTemplate = (e) => {
    if (!allowStandard) return showUpgradePlanModal()

    setTemplateId(e)
  }

  const getEnhanceText = () => `
Topic: ${optionsData.topic}
${optionsData.writerContext ? 'Context: ' + optionsData.writerContext : ''}
${optionsData.state?.format ? 'Format: ' + optionsData.state.format : ''}
${optionsData.state?.style ? 'Style: ' + optionsData.state.style : ''}
${optionsData.state?.author ? 'Author: ' + optionsData.state.author : ''}
${optionsData.state?.tone ? 'Tone: ' + optionsData.state.tone : ''}
`

  return (
    visible && (
      <div
        className={classNames(
          'relative break-words pb-6',
          isPanelLarge
            ? 'grid-cols-1 gap-[14px] space-y-4 lg:grid lg:grid-cols-2 lg:gap-4 lg:space-y-0'
            : 'space-y-4'
        )}
      >
        <div className='col-span-2'>
          <ContextField documentContext={documentContext} />
        </div>

        {/* Template */}
        <div className='space-y-[6px]'>
          <FieldDescription>
            <T keyName='eleo-write-text-template'>Template</T>
          </FieldDescription>
          <ReactSelect
            id='template'
            options={templateOptions}
            isClearable
            clearLabel={t('eleo-clear-button-label-none', 'None')}
            placeHolder={t('eleo-select-drop-down-placeholder', 'Select')}
            customOptions={readyMadeTemplatesData}
            // defaultValue={options[0]}
            setSelectedValue={handleSelectTemplate}
            value={templateId.value ? templateId : null}
            showDeleteIcon
            deleteAction={(templateId) => handleDeleteTemplate(templateId)}
            name='TEMPLATE'
            onClear={handleClearTemplate}
          />
        </div>

        {/* Model */}
        <div className='space-y-[6px]'>
          <FieldDescription>
            <T keyName='eleo-chat-settings-model'>Model</T>
          </FieldDescription>

          <div className='h-[36px]'>
            <ReactSelect
              options={aiController.getSupportedTextModels({
                includeReasoning: false,
                includeOnline: false,
              })}
              setSelectedValue={({ value }) => {
                setModel({
                  ...aiController.getSupportedTextModels().find((model) => model.value === value),
                  description: undefined,
                })
              }}
              value={model}
              menuPlacement='bottom'
              menuAlignment='right'
            />
          </div>
        </div>

        {/* Topic */}
        <div className='col-span-2 space-y-[6px]' data-tooltip-id='writer-writeText-topic'>
          <FieldDescription>
            <T keyName='eleo-write-text-topic'>Topic</T>
          </FieldDescription>
          <Input
            id='topic'
            placeholder={
              topicPlaceHolder.value
                ? t(topicPlaceHolder.tolgee_id, topicPlaceHolder.value)
                : t(
                    'eleo-write-text-topic-placeholder',
                    'For Example: How AI can change the world?'
                  )
            }
            className='placeholder-font-regular w-full text-[13px] placeholder-[#36363680]'
            onChange={setTopic}
            value={optionsData.topic}
          />
        </div>

        {/* Description */}
        <div className='col-span-2 space-y-[6px]' data-tooltip-id='writer-writeText-context'>
          <FieldDescription>
            <T keyName='eleo-write-text-context'>Description</T>
          </FieldDescription>
          <Input
            placeholder={
              contextPlaceHolder.value
                ? t(contextPlaceHolder.tolgee_id, contextPlaceHolder.value)
                : t('eleo-write-text-context-placeholder', 'Provide more details about your text')
            }
            className='placeholder-font-regular min-h-[85px] w-full rounded-[8px] text-[13px] placeholder-[#36363680] min-[1370px]:min-h-[100px]'
            onChange={setWriterContext}
            value={optionsData.writerContext}
            isTextArea
          />
        </div>

        {/* Format */}
        <div className='space-y-[6px]' data-tooltip-id='writer-writeText-format'>
          <FieldDescription>
            <T keyName='eleo-write-text-format'>Format</T>
          </FieldDescription>
          <ReactSelect
            options={FormatInputData}
            // defaultValue={options[0]}
            setSelectedValue={handleTopicChange}
            placeHolder={t('eleo-select-drop-down-placeholder', 'Select')}
            value={transformSelectOptionValue(FormatInputData, optionsData.state.format, null)}
            name='FORMAT'
            menuMinWidth={250}
            isClearable
            clearLabel={t('eleo-clear-button-label-default', 'Default')}
          />
        </div>

        {/* Style */}
        <div className='space-y-[6px]' data-tooltip-id='writer-writeText-writingStyle'>
          <FieldDescription>
            <T keyName='eleo-write-text-style'>Style</T>
          </FieldDescription>
          <ReactSelect
            options={WritingStyleInputData}
            isClearable
            clearLabel={t('eleo-clear-button-label-default', 'Default')}
            // defaultValue={options[0]}
            setSelectedValue={handleTopicChange}
            placeHolder={t('eleo-select-drop-down-placeholder', 'Select')}
            value={transformSelectOptionValue(WritingStyleInputData, optionsData.state.style, null)}
            name='STYLE'
            menuAlignment='right'
            menuMinWidth={350}
          />
        </div>

        {/* Show advanced button */}
        <div className='col-span-2 flex items-center justify-center text-[14px] '>
          <button
            className={classNames(
              'flex w-auto items-center justify-center rounded-[4px] text-white',
              isShowAdvanced ? 'bg-brand-secondary' : 'bg-brand-green'
            )}
            onClick={() => setIsShowAdvanced((prev) => !prev)}
          >
            <div className='ml-[5px]'>
              <MoreOptions isShowAdvanced={isShowAdvanced} />
            </div>
            {isShowAdvanced ? (
              <ChevronLeft className='rotate-90' />
            ) : (
              <ChevronRight className='rotate-90' />
            )}
          </button>
        </div>
        {/* Advanced section */}
        <div
          className={classNames(
            'col-span-2 flex flex-col',
            !isShowAdvanced && 'hidden',
            isPanelLarge ? 'grid grid-cols-1 gap-[14px] lg:grid-cols-2 lg:gap-4' : 'space-y-4'
          )}
        >
          {/* SEO */}
          <div
            className='col-span-2 items-center gap-[12px] space-y-[6px]'
            data-tooltip-id='writer-writeText-keywords'
          >
            <FieldDescription>
              <T keyName='eleo-write-seo-keywords'>SEO Keywords</T>
            </FieldDescription>
            <div
              className={classNames('flex w-full items-center gap-x-2', isPanelLarge && 'flex-1')}
            >
              <Toggle
                isChecked={isOptimizedForSeoEnabled}
                setIsChecked={setIsOptimizedForSeoEnabled}
              />
              <Input
                placeholder={t('eleo-write-seo-keywords-placeholder', 'Your SEO keywords')}
                className={classNames(
                  'placeholder-font-regular w-full rounded-[8px] text-[13px]  placeholder-[#36363680]',
                  !isOptimizedForSeoEnabled && 'text-brand-secondary !bg-gray-100'
                )}
                disabled={!isOptimizedForSeoEnabled}
                value={optionsData.keyWords}
                onChange={setKeyWords}
              />
            </div>
          </div>

          {/* Length */}
          <div
            className={classNames('col-span-2 space-y-[6px]')}
            data-tooltip-id='writer-writeText-length'
          >
            <FieldDescription>
              <T keyName='eleo-write-text-length'>Length</T>
            </FieldDescription>
            <WriterLengthContainer
              handleRadioButtonCheck={handleRadioButtonCheck}
              selectedWordLength={wordLength}
              setWriterLengthOption={setWriterLengthOption}
              writerLengthOption={writerLengthOption}
              forceMobile={!isPanelLarge}
            />
          </div>

          {/* Checkboxes */}
          <div className='col-span-2 space-y-[6px]' data-tooltip-id='writer-writeText-include'>
            <FieldDescription>
              <T keyName='eleo-write-text-include'>Include</T>
            </FieldDescription>
            <div className='flex !w-full flex-wrap gap-[10px]'>
              {checkboxData.map((checkbox, i) => (
                <CheckBox
                  key={i}
                  checkBoxName={checkbox.name}
                  id={checkbox.name}
                  checkBoxValue={
                    optionsData.checkBoxValues.find((item) => item.name === checkbox.name)
                      ?.checked || false
                  }
                  size={15}
                  labelClasses='text-[13px]'
                  setChecked={handleCheckBoxValues}
                  label={<T keyName={`${checkbox.tolgee_id}`}>{checkbox.title}</T>}
                />
              ))}
            </div>
          </div>

          {/* Creativity */}
          <div className='space-y-[6px]' data-tooltip-id='writer-writeText-creativity'>
            <FieldDescription>
              <T keyName='eleo-write-text-creativity'>Creativity</T>
            </FieldDescription>
            <div className='w-full'>
              <ButtonGroup
                options={Creativity}
                onClick={(event) => setCreativity(event.target.value)}
                value={creativity}
                activeBgClass='bg-[#6363e51a]'
                className='!flex !h-[36px] flex-shrink'
              />
            </div>
          </div>

          {/* Author */}
          <div className='space-y-[6px]' data-tooltip-id='writer-writeText-author'>
            <FieldDescription>
              <T keyName='eleo-write-text-author'>Author</T>
            </FieldDescription>
            <ReactSelect
              options={AuthorInputData}
              isClearable
              clearLabel={t('eleo-clear-button-label-default', 'Default')}
              // defaultValue={options[0]}
              placeHolder={t('eleo-select-drop-down-placeholder', 'Select')}
              setSelectedValue={handleTopicChange}
              value={transformSelectOptionValue(AuthorInputData, optionsData.state.author, null)}
              name='AUTHOR'
              menuPlacement='top'
              menuAlignment='right'
            />
          </div>

          {/* Language */}
          <div className='space-y-[6px]' data-tooltip-id='writer-writeText-language'>
            <FieldDescription>
              <T keyName='eleo-write-text-language'>Language</T>
            </FieldDescription>
            <ReactSelect
              options={languages}
              setSelectedValue={handleTopicChange}
              placeHolder={t('eleo-select-drop-down-placeholder', 'Select')}
              value={transformSelectOptionValue(
                languages,
                optionsData.state.language,
                defaultToolOutPutLanguage
              )}
              name='LANGUAGE'
              menuPlacement='top'
              menuAlignment='right'
            />
          </div>

          {/* Tone */}
          <div className='space-y-[6px]' data-tooltip-id='writer-writeText-tone'>
            <FieldDescription>
              <T keyName='eleo-write-text-tone'>Tone</T>
            </FieldDescription>
            <div className='!w-full'>
              <ReactSelect
                options={ToneInputData}
                isClearable
                clearLabel={t('eleo-clear-button-label-default', 'Default')}
                // defaultValue={options[0]}
                placeHolder={t('eleo-select-drop-down-placeholder', 'Select')}
                setSelectedValue={handleTopicChange}
                value={transformSelectOptionValue(ToneInputData, optionsData.state.tone, null)}
                name='TONE'
                menuPlacement='top'
                menuAlignment='right'
              />
            </div>
          </div>
          <div className='relative col-span-2 space-y-[6px]'>
            <FieldDescription>
              <T keyName='eleo-write-text-plan'>Plan</T>
            </FieldDescription>

            <div className='relative'>
              <Input
                placeholder={t(
                  'eleo-write-text-plan-placeholder',
                  'A detailed plan for writing the text'
                )}
                className='placeholder-font-regular min-h-[85px] w-full rounded-[8px] text-[13px] placeholder-[#36363680] min-[1370px]:min-h-[100px]'
                onChange={setPlan}
                value={optionsData.plan}
                isTextArea
              />

              <Microbutton
                iconPlacement='left'
                variant='transparent-light'
                icon={<AutoAwesome fontSize='18' />}
                text={t('eleo-enhance', 'Enhance')}
                className={classNames('!absolute bottom-2 right-2 h-[28px]')}
                onClick={() => setIsEnhance(true)}
              />
            </div>
            {isEnhance && (
              <div className='absolute left-1/2 top-0 z-50 -translate-x-1/2 -translate-y-[calc(100%-20px)]'>
                <ContextMenu
                  noHeader
                  selectedText={getEnhanceText()}
                  getTextContext={getEnhanceText}
                  tools={['rephrase']}
                  forceNotSynonyms
                  regenerateUrl='/api/ai/enhance_prompt'
                  type='writer'
                  language={optionsData.state.language}
                  handleChangeSelectedText={(newText) => {
                    setPlan(newText)
                    setIsEnhance(false)
                  }}
                  onClose={() => setIsEnhance(false)}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    )
  )
}
