/***
 *
 *   SOCIAL SIGN IN
 *   After social authentication has been completed on the server
 *   the user is redirected back to this view - which obtains
 *   the token and completes the signin flow as normal
 *
 **********/

import axios from 'axios'
import { Animate, AuthContext, Card, Message, Row, ViewContext, useNavigate } from 'components/lib'
import { useContext, useEffect, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'

export function SocialSignin(props) {
  // wrap in useRef to prevent triggering useEffect multiple times
  const authContext = useRef(useContext(AuthContext))
  const viewContext = useRef(useContext(ViewContext))
  const navigate = useNavigate()
  const params = useSearchParams()[0]

  const provider = params.get('provider')
  const token = params.get('token')

  useEffect(() => {
    async function fetchToken() {
      try {
        const res = await axios({
          method: 'post',
          url: '/api/auth',
          data: { token: token },
        })

        const next = params.get('next')
        const plan = params.get('plan')

        res.data['2fa_required']
          ? navigate(`/signin/otp?token=${res.data.token}`)
          : authContext.current.signin(res, next, plan)
      } catch (err) {
        viewContext.current.handleError(err)
      }
    }

    if (token) fetchToken()
  }, [token, viewContext, authContext, navigate, params])

  return (
    <Animate type='pop'>
      <Row title={`Signing in with ${provider.charAt(0).toUpperCase() + provider.slice(1)}`}>
        <Card loading={token}>
          {!token && (
            <div style={{ width: '32em', margin: '0 auto' }}>
              <Message
                type='error'
                title={`Something wen't wrong`}
                text='Invalid URL, please try in again'
                buttonText='Log In'
                buttonLink='/signin'
              />
            </div>
          )}
        </Card>
      </Row>
    </Animate>
  )
}
