import { AutoAwesome, Mic, MicOff, StopCircle, TransitEnterexit } from '@mui/icons-material'
import { useTranslate } from '@tolgee/react'
import imageCompression from 'browser-image-compression'
import classNames from 'classnames'
import { ContextMenu } from 'components/context-menu'
import useTranscribe from 'components/hooks/transcribe'
import { useWindowDimensions } from 'components/hooks/window'
import { detectRtlDirection, errorToast } from 'helpers'
import { useCallback, useEffect, useRef, useState } from 'react'
import { MessageContext, Microbutton } from 'ui'
import { v4 as uuidv4 } from 'uuid'
import { MessageLoader } from '../ChatMessage/MessageLoader'
import { MessageContextItem } from '../MessageContext/item'
import { MessageContextModal } from '../MessageContext/modal'
import { useSearchParams } from 'react-router-dom'

const docTypes = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'text/plain',
  'text/csv',
]

export default function ChatTextInput({
  state,
  dispatch,
  handleSubmit,
  messageContext,
  isGenerating,
  setShowWebCam,
  handleStopRequest,
  enableEnhance = true,
  isChatbot = false,
}) {
  const { t } = useTranslate()
  const { width } = useWindowDimensions()
  const changeWritingDirection = detectRtlDirection(state.language.value)

  const [params] = useSearchParams()
  const isEmbed = params.get('embed')

  const textAreaRef = useRef()

  const [modal, setModal] = useState({ visible: false, content: '' })
  const [isEnhance, setIsEnhance] = useState(false)
  const [hasCamera, setHasCamera] = useState(false)

  // Holds existing input to concat with stt results
  const [previousInput, setPreviousInput] = useState('')

  const {
    toggleTranscribe,
    stopTranscribe,
    isLoading: isTranscriptionLoading,
    isTranscribing: isRecording,
  } = useTranscribe({
    callback: useCallback(
      (text) => {
        console.log(text)
        if (previousInput?.length)
          dispatch({ type: 'SET_INPUT', payload: previousInput + ' ' + text })
        else dispatch({ type: 'SET_INPUT', payload: text })
      },
      [dispatch, previousInput]
    ),
    isChatbot,
  })

  useEffect(() => {
    detectCamera()
  }, [])

  // Remove uploaded images when switching to model which doesnt support image input
  useEffect(() => {
    if (!state.model?.supportsImages) messageContext?.removeDocumentsOfType('image')
  }, [state.model])

  useEffect(() => {
    if (!state.inputValue.length) setPreviousInput('')
  }, [state.inputValue])

  useEffect(() => {
    if (isEmbed) return

    // Doesn't work without a timeout
    const timeout = setTimeout(() => {
      if (width > 460) textAreaRef.current?.focus()
    }, 50)
    return () => clearTimeout(timeout)
  }, [width])

  useEffect(() => {
    function handlePaste(e) {
      const items = e.clipboardData.items
      for (let i = 0; i < items.length; i++) {
        if (items[i].type.indexOf('image') !== -1 && state.model?.supportsImages) {
          const file = items[i].getAsFile()
          handleImageUpload({ preventDefault: () => {}, target: { files: [file] } })
          break
        }
      }
    }

    if (state.model) document.addEventListener('paste', handlePaste)
    return () => {
      document.removeEventListener('paste', handlePaste)
    }
  }, [state.model])

  async function handleImageUpload(e) {
    if (!messageContext) return

    e.preventDefault()
    const file = e.dataTransfer?.files[0] || e.target.files[0]
    if (!file) return

    // Allow only 1 image at a time
    messageContext.removeDocumentsOfType('image')

    const id = uuidv4()
    messageContext.addDocument({
      id: id,
      label: file.name,
      content: null,
      type: 'image',
      invalid: true,
      metadata: { type: file.type },
    })

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1024,
      useWebWorker: true,
    }

    try {
      const compressedFile = await imageCompression(file, options)

      const reader = new FileReader()
      reader.onload = () => {
        const imageBlob = new Blob([reader.result], { type: file.type })
        messageContext.setDocContext((prev) =>
          prev.map((item) =>
            item.id === id
              ? { ...item, content: URL.createObjectURL(imageBlob), invalid: false }
              : item
          )
        )
      }
      reader.onerror = function () {
        errorToast(t('eleo-error-image-upload', 'Failed to upload your image'))
      }

      reader.readAsArrayBuffer(compressedFile)
    } catch (error) {
      console.log(error)
    } finally {
      e.target.value = null
    }
  }

  async function handleDocumentUpload(e) {
    if (!messageContext) return
    e.preventDefault()

    const files = e.target.files
    if (!files.length) return

    for (const file of files) {
      if (!docTypes.includes(file.type)) {
        return errorToast(
          t('eleo-your-story-document-not-supported', 'This document type is not supported')
        )
      }

      messageContext.addDocument({
        label: file.name,
        type: 'document',
        id: uuidv4(),
        content: file,
        isChunk: false,
        metadata: { type: file.type },
      })
    }

    // Clear file input
    e.target.value = null
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      handleSubmit()
    }
  }

  const handleOpenModal = (type) => {
    setModal({ visible: true, content: type })
  }

  const detectCamera = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices()
      const videoDevices = devices.filter((device) => device.kind === 'videoinput')

      if (videoDevices.length > 0) {
        setHasCamera(hasCamera)
      }
    } catch (error) {
      errorToast(t('eleo-generic-error'))
    }
  }

  return (
    <>
      {isEnhance && (
        <div className='absolute left-1/2 top-0 z-50 -translate-x-2/3 -translate-y-[calc(100%-60px)]'>
          <ContextMenu
            noHeader
            selectedText={state.inputValue}
            getTextContext={() => state.inputValue}
            tools={['rephrase']}
            forceNotSynonyms
            regenerateUrl='/api/ai/enhance_prompt'
            type='chat'
            language={state.language.value}
            handleChangeSelectedText={(newText) => {
              dispatch({ type: 'SET_INPUT', payload: newText })
              setIsEnhance(false)
            }}
            onClose={() => setIsEnhance(false)}
          />
        </div>
      )}
      <div
        id='chat-input'
        className='xs:gap-2 xs:p-2 relative flex w-full max-w-[1000px] overflow-x-hidden rounded-[4px] bg-[#8383DE] shadow-[0_0_50px_#7575FF40,0_0_10px_#6363E550]'
      >
        <div className='xs:rounded-[4px] flex flex-1 rounded-l-[4px] bg-white'>
          <div className='flex h-full w-full flex-1 flex-col'>
            {isRecording && !state.inputValue.length ? (
              <div className='text-brand-violet xs:pl-4 xs:pt-5 h-full w-full pl-2 pt-3 text-[15px] font-medium leading-[1.2em]'>
                {t('eleo-chat-tts-placeholder', 'Im listening... You can speak now.')}
              </div>
            ) : (
              <textarea
                // disabled={isGenerating || isTranscriptionLoading}
                style={{ fieldSizing: 'content' }}
                ref={textAreaRef}
                className={classNames(
                  'xs:p-[10px] max-h-[300px] flex-1 resize-none rounded-[4px] p-2 text-[15px] leading-[1.5em] transition-all',
                  (isGenerating || isTranscriptionLoading) && 'bg-[#efefef40]',
                  Boolean(messageContext?.docContext.length) && 'mb-[6px] h-[54px] pb-0'
                )}
                rows={1}
                spellCheck={false}
                dir={changeWritingDirection ? 'rtl' : 'ltr'}
                placeholder={t('eleo-chat-input-placeholder', 'Write a message...')}
                value={state.inputValue}
                onChange={(e) => dispatch({ type: 'SET_INPUT', payload: e.target.value })}
                onKeyDown={handleKeyDown}
              />
            )}

            {/* Documents from messageContext */}
            {Boolean(messageContext?.docContext.length) && (
              <div
                className={classNames(
                  'xs:p-[10px] flex flex-wrap items-center gap-x-2 gap-y-1 p-2 pt-0',
                  (isGenerating || isTranscriptionLoading) && 'pointer-events-none bg-[#efefef40]'
                )}
              >
                {messageContext?.docContext.map((item) => (
                  <MessageContextItem key={item.id} item={item} messageContext={messageContext} />
                ))}
              </div>
            )}
          </div>
          <div
            className={classNames(
              'xs:flex-col xs:items-end xs:p-[10px] hidden items-start justify-start p-2 !pr-0 transition-all sm:flex'
            )}
          >
            {enableEnhance && (
              <Microbutton
                iconPlacement='left'
                variant='transparent'
                icon={<AutoAwesome fontSize='18' />}
                text={t('eleo-enhance', 'Enhance')}
                className={classNames(
                  '!text-brand-gray h-[40px] hover:!text-white',
                  !state.inputValue?.length && 'pointer-events-none'
                )}
                onClick={() => setIsEnhance(true)}
              />
            )}
          </div>
          <div
            className={classNames(
              'xs:flex-col xs:items-end xs:p-[10px] flex items-start justify-start p-2 !pl-[6px] transition-all',
              (isGenerating || isTranscriptionLoading) && 'pointer-events-none bg-[#efefef40]'
            )}
          >
            <div
              id='speech-to-text'
              className={classNames(
                'flex',
                (isGenerating || isTranscriptionLoading) && 'pointer-events-none opacity-40'
              )}
              onClick={() => {
                if (state.inputValue?.length) setPreviousInput(state.inputValue)
                toggleTranscribe()
              }}
            >
              <div
                data-tooltip-id={width >= 740 && `eleo-tooltip`}
                data-tooltip-keyname='eleo-chat-tooltip-stt'
                data-tooltip-hidden={isRecording}
                className={classNames(
                  'text-brand-gray relative flex size-[40px] cursor-pointer items-center justify-center rounded-[4px] bg-black/5 transition-colors ',
                  isRecording ? 'rounded-r-none' : 'hover:bg-brand-violet hover:text-white'
                )}
              >
                {isTranscriptionLoading ? (
                  <MessageLoader />
                ) : isRecording ? (
                  <div className='h-full w-full text-white'>
                    <div
                      style={{
                        animation: 'myAnimation 1.5s infinite',
                        animationTimingFunction: 'ease-in-out',
                      }}
                      className='bg-brand-violet absolute left-1/4 top-1/4 size-5 rounded-full'
                    />
                    <Mic className='absolute left-1/2 top-1/2 size-5 -translate-x-1/2 -translate-y-1/2' />
                  </div>
                ) : (
                  <Mic />
                )}
              </div>
              <div
                className={classNames(
                  'text-brand-gray flex size-[40px] cursor-pointer items-center justify-center rounded-r-[4px] bg-black/5 transition-all',
                  isRecording ? '' : 'w-0 opacity-0'
                )}
              >
                <MicOff />
              </div>
            </div>
            {messageContext && (
              <MessageContext
                id='message-context'
                translations={{
                  header: t('eleo-chat-message-context-header', 'Add context to this message'),
                  documentsLabel: t('eleo-chat-message-context-document', 'Document'),
                  imagesLabel: t('eleo-chat-message-context-image', 'Image'),
                  websitesLabel: t('eleo-chat-message-context-website', 'Website'),
                  storiesLabel: t('eleo-feature-your-story', 'Your Story'),
                  cameraLabel: t('eleo-feature-take-a-picture', 'Take a picture'),
                }}
                tooltipId={width >= 740 && `eleo-tooltip`}
                tooltipKeyname={`eleo-chat-tooltip-message-context`}
                hideTooltip={modal.visible}
                isDisabled={isGenerating || isTranscriptionLoading}
                handleOpenModal={handleOpenModal}
                enableImages={state.model?.supportsImages}
                handleImageUpload={handleImageUpload}
                handleDocumentUpload={handleDocumentUpload}
                handleShowWebCam={() => setShowWebCam(true)}
                disableWebsites={
                  messageContext.docContext?.filter((doc) => doc.type === 'website').length >= 5
                }
                showCameraContext={hasCamera}
              />
            )}
          </div>
        </div>
        <button
          title={t('eleo-chat-tooltip-send', 'Send query')}
          disabled={isTranscriptionLoading}
          className={classNames(
            'bg-brand-violet xs:rounded-[4px] xs:w-[194px] w-[56px] rounded-r-[4px] text-[15px] uppercase leading-[1.2em] text-white',
            (isGenerating || isTranscriptionLoading) && 'bg-opacity-50 opacity-80'
          )}
          onClick={() => {
            if (isGenerating) return handleStopRequest()

            if (isRecording) {
              stopTranscribe()
            }

            handleSubmit()
          }}
        >
          {isGenerating ? (
            <div
              className='flex items-center justify-center gap-[6px] text-[18px]'
              onClick={() => handleStopRequest()}
            >
              {width >= 466 && (
                <span className='text-[15px]'>{t('eleo-chat-input-abort', 'stop')}</span>
              )}{' '}
              <StopCircle fontSize='inherit' />
            </div>
          ) : width < 466 ? (
            <span className='text-[22px]'>
              <TransitEnterexit fontSize='inherit' className='rotate-[135deg]' />
            </span>
          ) : (
            t('eleo-chat-input-submit', 'send')
          )}
        </button>
      </div>
      {modal.visible && (
        <MessageContextModal
          modal={modal}
          setModal={setModal}
          messageContext={messageContext}
          width={width}
        />
      )}
    </>
  )
}
