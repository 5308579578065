import { Close, History } from '@mui/icons-material'
import React from 'react'
import { T } from '@tolgee/react'
import classNames from 'classnames'

const WriterHeader = ({
  historyIsVisible,
  setHistoryIsVisible,
  showHistoryIcon = true,
  headerContainerClasses,
  headerTitle,
  toolName,
}) => {
  return (
    <div className={classNames('border-b-brand-gray-light border-b')}>
      <div className={classNames(headerContainerClasses, 'px-[16px] py-[6px]')}>
        <div className='flex items-center justify-between'>
          <p className='text-brand-body flex items-center gap-[10px] text-[15px] font-normal uppercase leading-[1.2em]'>
            <T keyName={`eleo-tool-header-title-${headerTitle}`}>{headerTitle}</T>
            {/* <button
              className="bg-brand-black text-[#ffffff] px-[4px] py-[2px] rounded"
              onClick={handleEdit}
            >
              Debug Temperature
            </button> */}
          </p>
          {historyIsVisible ? (
            <div className='flex items-center  justify-end gap-[8px]'>
              <Close
                weight='bold'
                style={{ color: '#363636', fontSize: 24 }}
                className='cursor-pointer'
                onClick={() => setHistoryIsVisible(false)}
              />
            </div>
          ) : (
            <div className='flex items-center gap-[8px]'>
              {showHistoryIcon && (
                <div
                  className='flex cursor-pointer items-center gap-1 text-[13px] uppercase text-[#656565]'
                  onClick={() => setHistoryIsVisible(true)}
                  data-tooltip-id={`${toolName}-history`}
                >
                  <History />
                  <T keyName='eleo-tool-chat-history-header'>History</T>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default WriterHeader
