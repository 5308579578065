import { Close, History, Search, SearchOff } from '@mui/icons-material'
import { Popover } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import classNames from 'classnames'
import { useWindowDimensions } from 'components/hooks/window'
import { useState } from 'react'
import ModeSelector from 'toolComponents/chat/ModeSelector'
import { ConversationContext, LanguageSelector, Microbutton } from 'ui'

const ChatBotOptionBar = ({
  language,
  languages,
  setLanguage,
  handleClose,
  isAutoAudio,
  audioStream,
  handleVolumeOff,
  setIsAutoAudio,
  userCanAccessHistory,
  setHistoryIsVisible,
  handleClearChat,
  isSearchWebAllowed,
  isSearchWeb,
  setSearchWeb,
  isLanguageChangeAllowed,
  isContextAllowed,
  documentContext,
  isExternal,
  inputMode,
  setInputMode,
  isModeChangeAllowed,
  isHistoryVisible,
}) => {
  const { t } = useTranslate()
  const { width } = useWindowDimensions()

  const isCompactLayout = width < (isHistoryVisible ? 1400 : 1060)

  const [anchorEl, setAnchorEl] = useState(null)
  const handleClick = (e) => {
    setAnchorEl(e.currentTarget)
  }

  const handleClosePopover = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'language-popover' : undefined

  if (isCompactLayout)
    return (
      <div className='mx-auto flex w-full max-w-[1000px] items-center justify-between gap-[5px] md:gap-0'>
        <div className='flex gap-[5px]'>
          <Microbutton
            text={t('eleo-chat-options-settings', 'Settings')}
            variant='transparent'
            onClick={handleClick}
            className='h-[30px]'
          />
        </div>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClosePopover}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <div className='flex flex-col gap-2 p-2 py-3'>
            <div className='flex items-center gap-[7px]'>
              {isLanguageChangeAllowed && (
                <div className='w-full'>
                  <LanguageSelector
                    label={t('eleo-chat-settings-language', 'Language')}
                    language={{ label: language, value: language }}
                    languages={languages}
                    microButtonVariant='grey'
                    onClick={(val) => setLanguage(val.value)}
                    translations={{
                      showAll: t('eleo-chat-settings-language-show-all', 'Show all'),
                      languageNotFound: t(
                        'eleo-chat-settings-language-not-found',
                        'Language not found'
                      ),
                      searchPlaceholder: t(
                        'eleo-chat-settings-language-search-placeholder',
                        'Find a language'
                      ),
                    }}
                    callback={handleClose}
                    buttonTextClassOverrides='!font-black'
                    labelWrapperClassOverrides='!md:w-[85px] !w-[60px]'
                  />
                </div>
              )}
            </div>
            {isContextAllowed ? (
              <ConversationContext
                id='conversation-context'
                tooltipId={`eleo-tooltip`}
                tooltipKeyname={`eleo-chat-tooltip-context`}
                documentContext={documentContext}
                model={{ label: 'model', value: 'model' }}
                translations={{
                  buttonLabel: t('eleo-chat-settings-context-button-label', 'Context'),
                  storiesLabel: t('eleo-chat-settings-context-stories', 'Your stories'),
                  documentsLabel: t('eleo-context-modal-documents-title', 'Documents'),
                  websitesLabel: t('eleo-context-modal-websites-title', 'Websites'),
                  addContextLabel: t('eleo-chat-settings-context-add-context', 'Add context'),
                  noContextSelected: t('eleo-chat-settings-context-no-context-selected', 'None'),
                }}
                docOptions={['documents', 'stories', 'websites']}
              />
            ) : null}

            {userCanAccessHistory ? (
              <div>
                <Microbutton
                  title={t('eleo-tool-chat-history-header', 'History')}
                  iconPlacement='left'
                  text={<span className=''>{t('eleo-tool-chat-history-header', 'History')}</span>}
                  variant={isExternal ? 'grey' : 'transparent'}
                  icon={<History fontSize='inherit' />}
                  onClick={() => {
                    setHistoryIsVisible((prev) => !prev)
                    handleClosePopover()
                  }}
                  className='h-[28px] md:h-[30px]'
                  textClasses='!font-black md:px-[3px] !px-0'
                />
              </div>
            ) : null}

            <div className='flex'>
              {/* WWW switch */}
              {isSearchWebAllowed && (
                <div
                  className='bg-brand-violet/10 flex rounded-[4px]'
                  data-tooltip-id={`eleo-tooltip`}
                  data-tooltip-keyname={'eleo-chat-tooltip-www'}
                >
                  <Microbutton
                    iconPlacement='left'
                    variant='plain'
                    icon={<SearchOff fontSize='inherit' />}
                    disabled={!isSearchWeb}
                    onClick={() => setSearchWeb(false)}
                    className={classNames(
                      'rounded-r-none',
                      isSearchWeb
                        ? 'text-brand-violet/50 hover:text-brand-violet'
                        : 'text-brand-violet bg-brand-violet/10 '
                    )}
                  />

                  <Microbutton
                    iconPlacement='left'
                    text='www'
                    variant={isSearchWeb ? 'solid' : 'plain'}
                    icon={<Search fontSize='inherit' />}
                    disabled={isSearchWeb}
                    onClick={() => setSearchWeb(true)}
                    className={classNames(
                      'rounded-l-none',
                      !isSearchWeb &&
                        'text-brand-violet/50 hover:bg-brand-violet/70 hover:text-white'
                    )}
                  />
                </div>
              )}
            </div>
          </div>
        </Popover>

        <div className='flex items-center gap-[7px]'>
          {isModeChangeAllowed && (
            <ModeSelector inputMode={inputMode} setInputMode={setInputMode} />
          )}

          <Microbutton
            title={t('eleo-chat-tooltip-clear-chat', 'Start a new conversation')}
            iconPlacement='left'
            text={t('eleo-chat-settings-clear-chat', 'Clear chat')}
            variant={isExternal ? 'grey' : 'transparent'}
            icon={<Close fontSize='inherit' />}
            onClick={handleClearChat}
            className='h-[30px]'
            textClasses='!font-black'
          />
        </div>
      </div>
    )

  return (
    <div className='mx-auto flex w-full max-w-[1000px] items-center justify-between gap-[5px] md:gap-0'>
      <div className='flex items-center gap-[7px]'>
        {isLanguageChangeAllowed && (
          <div>
            <LanguageSelector
              label={t('eleo-chat-settings-language', 'Language')}
              language={{ label: language, value: language }}
              languages={languages}
              microButtonVariant='grey'
              onClick={(val) => setLanguage(val.value)}
              translations={{
                showAll: t('eleo-chat-settings-language-show-all', 'Show all'),
                languageNotFound: t('eleo-chat-settings-language-not-found', 'Language not found'),
                searchPlaceholder: t(
                  'eleo-chat-settings-language-search-placeholder',
                  'Find a language'
                ),
              }}
              callback={() => {
                handleClosePopover()
                handleClose()
              }}
              buttonTextClassOverrides='!font-black'
              labelWrapperClassOverrides='!md:w-[85px] !w-[60px]'
            />
          </div>
        )}
        {isContextAllowed ? (
          <ConversationContext
            id='conversation-context'
            tooltipId={`eleo-tooltip`}
            tooltipKeyname={`eleo-chat-tooltip-context`}
            documentContext={documentContext}
            model={{ label: 'model', value: 'model' }}
            translations={{
              buttonLabel: t('eleo-chat-settings-context-button-label', 'Context'),
              storiesLabel: t('eleo-chat-settings-context-stories', 'Your stories'),
              documentsLabel: t('eleo-context-modal-documents-title', 'Documents'),
              websitesLabel: t('eleo-context-modal-websites-title', 'Websites'),
              addContextLabel: t('eleo-chat-settings-context-add-context', 'Add context'),
              noContextSelected: t('eleo-chat-settings-context-no-context-selected', 'None'),
            }}
            docOptions={['documents', 'stories', 'websites']}
          />
        ) : null}
        {/* WWW switch */}
        {isSearchWebAllowed && (
          <div
            className='bg-brand-violet/10 flex rounded-[4px]'
            data-tooltip-id={`eleo-tooltip`}
            data-tooltip-keyname={'eleo-chat-tooltip-www'}
          >
            <Microbutton
              iconPlacement='left'
              variant='plain'
              icon={<SearchOff fontSize='inherit' />}
              disabled={!isSearchWeb}
              onClick={() => setSearchWeb(false)}
              className={classNames(
                'rounded-r-none',
                isSearchWeb
                  ? 'text-brand-violet/50 hover:text-brand-violet'
                  : 'text-brand-violet bg-brand-violet/10 '
              )}
            />
            <Microbutton
              iconPlacement='left'
              text='www'
              variant={isSearchWeb ? 'solid' : 'plain'}
              icon={<Search fontSize='inherit' />}
              disabled={isSearchWeb}
              onClick={() => setSearchWeb(true)}
              className={classNames(
                'rounded-l-none',
                !isSearchWeb && 'text-brand-violet/50 hover:bg-brand-violet/70 hover:text-white'
              )}
            />
          </div>
        )}
        {/* <div
          id='audio-switch'
          className={classNames(
            isExternal ? 'bg-[#000000]/5' : 'bg-brand-violet/10',
            'flex rounded-[4px] '
          )}
        >
          <Microbutton
            iconPlacement='left'
            variant='plain'
            icon={<VolumeOff fontSize='inherit' />}
            // disabled={isAutoAudio && !audioStream}
            onClick={handleVolumeOff}
            className={classNames(
              'rounded-r-none p-[5px]',
              isAutoAudio && !audioStream
                ? {
                    'text-brand-body/50 hover:text-brand-body': isExternal,
                    'text-brand-violet/50 hover:text-brand-violet': !isExternal,
                  }
                : {
                    'text-brand-body bg-brand-body/10 ': isExternal,
                    'text-brand-violet bg-brand-violet/10 ': !isExternal,
                  }
            )}
          />
          <Microbutton
            iconPlacement='left'
            variant={isAutoAudio ? (isExternal ? 'grey' : 'solid') : 'plain'}
            icon={<VolumeUp fontSize='inherit' />}
            disabled={isAutoAudio}
            onClick={() => setIsAutoAudio(true)}
            className={classNames(
              'rounded-l-none p-[5px]',
              isAutoAudio && {
                'text-brand-body hover:text-brand-body/70': isExternal,
                ' hover:text-brand-violet/70': !isExternal,
              },
              !isAutoAudio && {
                'text-brand-body bg-brand-body/10 ': isExternal,
                'text-brand-violet/50 ': !isExternal,
              }
            )}
          />
        </div> */}
      </div>
      <div className='flex items-center gap-[7px]'>
        {userCanAccessHistory ? (
          <div>
            <Microbutton
              title={t('eleo-tool-chat-history-header', 'History')}
              iconPlacement='left'
              text={
                <span className='hidden lg:block'>
                  {t('eleo-tool-chat-history-header', 'History')}
                </span>
              }
              variant={isExternal ? 'grey' : 'transparent'}
              icon={<History fontSize='inherit' />}
              onClick={() => {
                setHistoryIsVisible((prev) => !prev)
                handleClosePopover()
              }}
              className='h-[28px] md:h-[30px]'
              textClasses='!font-black md:px-[3px] !px-0'
            />
          </div>
        ) : null}

        {isModeChangeAllowed && <ModeSelector inputMode={inputMode} setInputMode={setInputMode} />}

        <Microbutton
          title={t('eleo-chat-tooltip-clear-chat', 'Start a new conversation')}
          iconPlacement='left'
          text={t('eleo-chat-settings-clear-chat', 'Clear chat')}
          variant={isExternal ? 'grey' : 'transparent'}
          icon={<Close fontSize='inherit' />}
          onClick={handleClearChat}
          className='h-[30px]'
          textClasses='!font-black'
        />
      </div>
    </div>
  )
}

export default ChatBotOptionBar
