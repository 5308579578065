import { Error } from '@mui/icons-material'
import { T } from '@tolgee/react'
import axios from 'axios'
import { HelpTooltips } from 'components/help/helpTooltips'
import { useDocumentContext } from 'components/hooks/context'
import { useWindowDimensions } from 'components/hooks/window'
import { Animate, Message, ViewContext } from 'components/lib'
import Tour from 'components/tour'
import { clamp, errorToast, successToast } from 'helpers'
import { useCallback, useContext, useEffect, useLayoutEffect, useReducer, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectClientId,
  selectFinished,
  selectPending,
  selectQueue,
  setFinished,
  setPending,
  setQueue,
} from 'store/generations'
import {
  imageCreatorInitialState,
  selectImageCreatorInputHistory,
  selectImageHolderHistoryValues,
  setImageCreatorInputHistory,
  setImageHolderHistoryValues,
} from 'store/toolInputHistoriesHolder/image-creator'
import { DEFAULT_MODAL_DATA, Modal } from 'toolComponents/generic/modal'
import ImageForm from 'toolComponents/image/create/ImageForm'
import { styles } from 'toolComponents/image/models/imageOptions'
import { resolutions } from 'toolComponents/image/models/imageSizes'
import { Footer } from 'toolComponents/image/output/Footer'
import ImagesOutput from 'toolComponents/image/output/ImageOutput'
import { CheckBox, ResizableContainer } from 'ui'
import { v4 as uuidv4 } from 'uuid'

// Tour steps
const steps = [
  {
    target: '#description',
    content: (
      <T keyName='eleo-tour-image-1'>
        Describe your image. Write what should be in the foreground, background, what colors, light,
        shadows, and mood the image should have.
      </T>
    ),
  },
  {
    target: '#samples',
    content: (
      <T keyName='eleo-tour-image-2'>
        Choose the number of generated images. The more you choose, the greater the selection, but
        you will have to wait longer.
      </T>
    ),
  },
  {
    target: '#style',
    content: (
      <T keyName='eleo-tour-image-3'>
        Choose a style. Your image can be realistic or mimic a chosen artistic style.
      </T>
    ),
  },
  {
    target: '#save-template',
    content: (
      <T keyName='eleo-tour-image-4'>
        Save your settings as a template. Use the template to generate multiple images in a similar
        style.
      </T>
    ),
  },
  {
    target: '#image-navigation',
    content: (
      <T keyName='eleo-tour-image-5'>
        Navigate between versions using arrows. You can go back to previously generated images.
      </T>
    ),
    placementBeacon: 'top',
  },
  {
    target: '#context-menu',
    content: (
      <T keyName='eleo-tour-image-6'>
        Use the context menu. Hover over the selected image to zoom in, modify, save to the gallery,
        or download to your disk.
      </T>
    ),
  },
]
function reducer(state, action) {
  switch (action.type) {
    case 'SET_STATE':
      return { ...state, ...action.payload }
    case 'SET_PROMPT':
      return { ...state, prompt: action.payload }
    case 'SET_KEYWORDS':
      return { ...state, keywords: action.payload }
    case 'SET_NEGATIVE_PROMPT':
      return { ...state, negativePrompt: action.payload }
    case 'SET_QUALITY':
      return { ...state, quality: action.payload }
    case 'SET_CREATIVITY':
      return { ...state, creativity: action.payload }
    case 'SET_STYLE':
      return { ...state, style: action.payload }
    case 'SET_SAMPLES':
      return { ...state, samples: Number(action.payload) }
    case 'SET_PROPORTIONS':
      return { ...state, chosenProportions: action.payload }
    case 'SET_SIZE':
      return { ...state, imageSize: action.payload }
    case 'SET_TEMPLATE':
      return { ...state, template: action.payload }
    case 'APPLY_TEMPLATE':
      const values = action.payload

      let templateData = {
        prompt: values.description,
        keywords: values.keywords,
        negativePrompt: values.avoid,
        quality: values.quality ? String(clamp(0, values.quality, 10)) : null,
        creativity: values.creativity ? clamp(0, values.creativity, 10) : null,
        style: styles.find((s) => values.style?.toLowerCase() === s.value.toLowerCase())?.value,
        chosenProportions: [...resolutions.sd15, ...resolutions.sdxl].find(
          (item) => item.value === values.proportions
        )
          ? values.proportions
          : null,
        template: values.id,
        ...(values.samples && {
          samples: clamp(1, Number(values.samples), 4),
        }),
      }

      templateData = Object.fromEntries(
        Object.entries(templateData).filter(([key, value]) => value !== null && value !== undefined)
      )

      return { ...state, ...templateData }
    case 'RESET':
      return imageCreatorInitialState.imageFormValues
    default:
      return state
  }
}

export function Image() {
  const context = useContext(ViewContext)
  const dispatchAction = useDispatch()
  const { width } = useWindowDimensions()

  const imageFormData = useSelector(selectImageCreatorInputHistory)
  const imageHolderHistoryValues = useSelector(selectImageHolderHistoryValues)
  const pendingGeneration = useSelector(selectPending).generate
  const finishedGeneration = useSelector(selectFinished).generate
  const queueSize = useSelector(selectQueue).generate
  const clientId = useSelector(selectClientId)

  const documentContext = useDocumentContext('image')

  const [currentIndex, setCurrentIndex] = useState(imageHolderHistoryValues.currentIndex)
  const [selectedImage, setSelectedImage] = useState(imageHolderHistoryValues.selectedImage)
  const [previewData, setPreviewData] = useState({ proportions: '1:1', amount: 2 })
  const [isLoading, setIsLoading] = useState(imageHolderHistoryValues.isLoading)
  const [isFormOpen, setIsFormOpen] = useState(imageHolderHistoryValues.isOpen)
  const [history, setHistory] = useState(imageHolderHistoryValues.history)
  const [modalData, setModalData] = useState(DEFAULT_MODAL_DATA)
  const [imagePreviewData, setImagePreviewData] = useState({
    target: '',
    isMobile: false,
    isVisible: false,
  })
  // Only used for mobile layout
  const [isDisplayOutput, setIsDisplayOutput] = useState(false)

  const [state, dispatch] = useReducer(reducer, imageFormData)
  // Update store
  useEffect(() => {
    dispatchAction(setImageCreatorInputHistory(state))
  }, [state, dispatchAction])

  async function requestGeneration(formState) {
    try {
      const data = {
        clientId: clientId,
        data: { action: 'generate', ...formState },
        context: documentContext.docContext?.filter((item) => !item.invalid),
        promptId: uuidv4(),
      }

      // Save form data to associate with generation later
      dispatchAction(
        setPending({
          generate: {
            id: data.promptId,
            data: formState,
            context: documentContext.docContext,
            inQueue: true,
          },
        })
      )
      if (!history.length || history[history.length - 1]?.images.length)
        setHistory((prev) => [
          ...prev,
          { images: [], data: formState, context: documentContext.docContext },
        ])

      const res = await axios.post('/api/ai/comfy', data)
      if (res.data.prompt_id !== data.promptId) throw new Error('Image generation failed')

      context.setImagesLeft((prev) => prev - formState.samples)

      return res.data
    } catch (err) {
      setIsLoading(false)
      dispatchAction(
        setPending({
          generate: {},
        })
      )

      context.handleError(err)
    }
  }

  const getGeneration = useCallback(
    async (promptId) => {
      // if (promptId !== pendingGeneration.promptId) return
      try {
        const res = await axios.get(`/api/ai/comfy/${promptId}`)

        let images = []
        if (finishedGeneration.data.moreLikeThis)
          images = [finishedGeneration.data.image, ...res.data]
        else images = res.data

        handleImageReady(images, finishedGeneration.data, finishedGeneration.context)
      } catch (err) {
        context.handleError(err)
      } finally {
        setIsLoading(false)
      }
    },
    [context, finishedGeneration.data]
  )

  async function abortGeneration() {
    try {
      const data = { promptId: pendingGeneration.id, samples: pendingGeneration.data?.samples }
      await axios.post(`/api/ai/comfy/abort`, data)

      dispatchAction(setQueue({ generate: 0 }))
      dispatchAction(
        setPending({
          generate: {},
        })
      )
      setHistory((prev) => [...prev.slice(0, -1), { images: [], data: null, context: null }])

      context.setImagesLeft((prev) => prev + Number(pendingGeneration.data?.samples))

      setIsLoading(false)
    } catch (err) {
      context.handleError(err)
    } finally {
      setIsLoading(false)
    }
  }

  // Handle infinite loading - in some cases the request (or response) is lost
  useEffect(() => {
    let requestFailedTimeout
    if (pendingGeneration.id && queueSize === 0) {
      requestFailedTimeout = setTimeout(() => {
        console.warn('Image request timed out')
        dispatchAction(setPending({ generate: {} }))
        setIsLoading(false)

        errorToast(
          <T keyName='eleo-error-image-generation'>Image generation failed. Please try again</T>
        )
      }, 90000)
    }

    return () => {
      if (!pendingGeneration.id || queueSize === 0) clearTimeout(requestFailedTimeout)
    }
  }, [pendingGeneration, dispatchAction, queueSize])

  useEffect(() => {
    if (!finishedGeneration?.id) return

    getGeneration(finishedGeneration.id)
    dispatchAction(
      setFinished({
        generate: {},
      })
    )
  }, [finishedGeneration, dispatchAction, getGeneration])

  useLayoutEffect(() => {
    setCurrentIndex(history.length - 1)
  }, [history])

  // Update display proportions
  const { chosenProportions, samples } = useSelector(
    selectImageCreatorInputHistory,
    (a, b) => a.chosenProportions === b.chosenProportions && a.samples === b.samples // rerender only when chosenProportions or samples change
  )
  useLayoutEffect(() => {
    const options = Object.entries(resolutions)
      .map((item) => item[1])
      .flat()

    const imageProportions = history[currentIndex]?.data?.chosenProportions ?? chosenProportions

    let newSamples = samples
    if (history[currentIndex]?.data)
      newSamples = history[currentIndex]?.data?.moreLikeThis
        ? history[currentIndex]?.data?.samples + 1
        : history[currentIndex]?.data?.samples

    setPreviewData({
      proportions: options.find((item) => item.value === imageProportions)?.proportions,
      samples: newSamples,
    })
  }, [chosenProportions, samples, history, currentIndex, setPreviewData])

  function handleImageReady(images, imageData, contextData) {
    setHistory((prev) => [
      ...prev.slice(0, -1),
      { images: images, data: imageData, context: contextData },
    ])

    const sessionHistory = JSON.parse(sessionStorage.getItem('image-creator-history')) ?? []
    sessionStorage.setItem(
      'image-creator-history',
      JSON.stringify([...sessionHistory, { images: images, data: imageData }])
    )
  }

  function handleApplyForm(index) {
    if (!history[index]?.data || JSON.stringify(history[index].data) === JSON.stringify(state))
      return successToast(<T keyName='eleo-form-restored'>Form has been restored</T>)

    if (!localStorage.getItem('disable-apply-settigns-warning'))
      return setModalData({
        isVisible: true,
        title: (
          <T
            keyName='eleo-modal-apply-settings-title'
            defaultValue='Are you sure you want to apply form settings?'
          />
        ),
        subtitle: (
          <>
            <div className='body-small text-brand-violet pt-1'>
              <T keyName='eleo-modal-apply-settings-subtitle'>
                Form on the left will be filled with the values used to generate current images.
              </T>
            </div>
            <Message
              className='body-small !mb-0 mt-[14px] !p-3 font-medium text-[#ED7700]'
              type='warning'
              isFlex
              text={
                <div>
                  <T keyName='eleo-chatbot-modal-apply-settings-warning'>
                    Your current form will be overwritten.
                  </T>
                </div>
              }
              icon={<Error className='float-left mr-[10px]' />}
            />
          </>
        ),
        type: 'apply-settings',
        acceptLabel: <T keyName='eleo-apply-settings'>Apply settings</T>,
        context: { index: index },
      })

    dispatch({
      type: 'SET_STATE',
      payload: history[index]?.data,
    })

    if (history[index].context) {
      documentContext.clearContext()
      documentContext.setDocContext(history[index]?.context)
    }

    successToast(<T keyName='eleo-form-restored'>Form has been restored</T>)
  }

  function handleClearOutput() {
    if (!localStorage.getItem('disable-clear-image-output-warning'))
      return setModalData({
        isVisible: true,
        title: (
          <T
            keyName='eleo-modal-clear-output-title'
            defaultValue='Are you sure you want to clear all images?'
          />
        ),
        subtitle: (
          <Message
            className='body-small !mb-0 mt-[14px] !p-3 font-medium text-[#ED7700]'
            type='warning'
            isFlex
            text={
              <div>
                <T keyName='eleo-chatbot-modal-clear-output-warning'>
                  Unsaved images from all pages will be lost
                </T>
              </div>
            }
            icon={<Error className='float-left mr-[10px]' />}
          />
        ),
        type: 'clear-output',
        acceptLabel: <T keyName='eleo-clear'>Clear</T>,
      })

    setHistory([])
    sessionStorage.removeItem('image-creator-history')
  }

  useEffect(() => {
    dispatchAction(
      setImageHolderHistoryValues({
        history,
        selectedImage,
        currentIndex,
        isLoading,
      })
    )
  }, [history, selectedImage, currentIndex, isLoading, dispatchAction])

  const isLayoutLarge = width >= 1024

  const FormComponent = (
    <ImageForm
      handleImageReady={handleImageReady}
      setIsLoading={setIsLoading}
      isLoading={isLoading}
      requestGeneration={requestGeneration}
      getGeneration={getGeneration}
      queueSize={queueSize}
      isLayoutLarge={isLayoutLarge}
      setIsDisplayOutput={setIsDisplayOutput}
      history={history}
      documentContext={documentContext}
      dispatch={dispatch}
      state={state}
      setModalData={setModalData}
    />
  )

  const OutputComponent = (
    <div className='flex h-full flex-col'>
      <ImagesOutput
        images={history[currentIndex]?.images}
        selectedImage={selectedImage}
        setSelectedImage={setSelectedImage}
        imageData={history[currentIndex]?.data}
        imagePreviewData={imagePreviewData}
        setImagePreviewData={setImagePreviewData}
        setIsLoading={setIsLoading}
        handleImageReady={handleImageReady}
        requestGeneration={requestGeneration}
        currentIndex={currentIndex}
        isGenerating={isLoading}
        history={history}
        queueSize={queueSize}
        previewData={previewData}
        handleAbort={abortGeneration}
      />
      <Footer
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
        history={history}
        isGenerating={isLoading}
        setIsDisplayOutput={setIsDisplayOutput}
        isLayoutLarge={isLayoutLarge}
        handleApplyForm={handleApplyForm}
        handleClearOutput={handleClearOutput}
      />
    </div>
  )

  const [isDisableApplySettingsWarning, setIsDisableApplySettingsWarning] = useState(false)
  const [isDisableClearOutputWarning, setIsDisableClearOutputWarning] = useState(false)

  const modalContent = {
    styles: (
      <div className='max-h-[60dvh] w-full overflow-y-auto p-[14px]'>
        <div className='grid grid-cols-2 gap-[10px]'>
          {styles.map((style) => (
            <div
              key={style.value}
              className='border-brand-gray-light rounded-[4px] border bg-white'
              onClick={() => {
                dispatch({
                  type: 'SET_STYLE',
                  payload: style.value,
                })
                setModalData(DEFAULT_MODAL_DATA)
              }}
            >
              <img
                className='h-[130px] rounded-t-[4px]'
                src={style.imageMobile ?? style.image}
                alt=''
              />
              <div className='list-position text-brand-gray-dark  p-3'>{style.label}</div>
            </div>
          ))}
        </div>
      </div>
    ),
    'apply-settings': (
      <div className='max-h-[60dvh] w-full overflow-y-auto p-[14px] px-[24px]'>
        <label className='flex items-center gap-1'>
          <CheckBox
            setChecked={({ checked }) => setIsDisableApplySettingsWarning(checked)}
            checkBoxValue={isDisableApplySettingsWarning}
          />
          <span className='body-small'>
            <T keyName='eleo-dont-show-again'>Don't show this message again</T>
          </span>
        </label>
      </div>
    ),
    'clear-output': (
      <div className='max-h-[60dvh] w-full overflow-y-auto p-[14px] px-[24px]'>
        <label className='flex items-center gap-1'>
          <CheckBox
            setChecked={({ checked }) => setIsDisableClearOutputWarning(checked)}
            checkBoxValue={isDisableClearOutputWarning}
          />
          <span className='body-small'>
            <T keyName='eleo-dont-show-again'>Don't show this message again</T>
          </span>
        </label>
      </div>
    ),
  }

  return (
    <>
      <Animate type='pop'>
        {isLayoutLarge ? (
          <div className='flex h-full'>
            {/* Input Data */}
            <ResizableContainer
              isOpen={isFormOpen}
              setIsOpen={setIsFormOpen}
              storageKey='images'
              minWidth={400}
              initWidth={500}
              maxWidth={Math.min(900, (width - 200) / 2)}
            >
              {FormComponent}
            </ResizableContainer>

            {/* Output Data  */}
            <div className='flex min-w-0 flex-1 flex-col'>{OutputComponent}</div>
          </div>
        ) : isDisplayOutput ? (
          OutputComponent
        ) : (
          FormComponent
        )}
      </Animate>

      <HelpTooltips group='images' tool='imageCreator' />
      <Tour steps={steps} name='images' />
      <Modal
        isVisible={modalData.isVisible}
        containerClasses='bg-black bg-opacity-[3%]'
        hideModal={() => setModalData(DEFAULT_MODAL_DATA)}
        title={modalData.title}
        subtitle={modalData.subtitle}
        acceptLabel={modalData.acceptLabel}
        content={modalContent[modalData.type]}
        callback={() => {
          if (modalData.type === 'apply-settings') {
            const index = modalData.context?.index
            dispatch({
              type: 'SET_STATE',
              payload: history[index]?.data,
            })

            if (history[index].context) {
              documentContext.clearContext()
              documentContext.setDocContext(history[index]?.context)
            }
            successToast(<T keyName='eleo-form-restored'>Form has been restored</T>)

            if (isDisableApplySettingsWarning)
              localStorage.setItem('disable-apply-settigns-warning', 1)
          } else if (modalData.type === 'clear-output') {
            setHistory([])
            sessionStorage.removeItem('image-creator-history')

            if (isDisableClearOutputWarning)
              localStorage.setItem('disable-clear-image-output-warning', 1)
          }
        }}
        isValid
        isPrimary={['apply-settings', 'clear-output'].includes(modalData.type)}
        disableBorder={['apply-settings', 'clear-output'].includes(modalData.type)}
        width={300}
        acceptButtonClasses='grow-[2]'
      />
    </>
  )
}
