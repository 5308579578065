import { Close, Mic } from '@mui/icons-material'
import classNames from 'classnames'
import Lottie from 'react-lottie'
import * as animationData from '../sparkle-lottie.json'
import FilledPieLoader from 'components/loader/pieLoader'
import { useEffect, useState } from 'react'

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
}

export default function AudioInputLayout({
  isRecording,
  speechVolume,
  isLoading,
  onMouseDown,
  onMouseUp,
  onClick,
  getStatusText,
  voiceDetectedTime,
  loader = (
    <>
      <div className=''>
        <Lottie options={defaultOptions} height={24} width={24} />
      </div>
      {/* <div className='block md:hidden'>
        <Close />
      </div> */}
    </>
  ),
}) {
  const [progress, setProgress] = useState(100)

  useEffect(() => {
    const interval = setInterval(() => {
      if (voiceDetectedTime) {
        const elapsedTime = performance.now() - voiceDetectedTime
        setProgress(Math.max(0, 100 - (elapsedTime < 1000 ? 0 : elapsedTime - 1000) / 20))
      } else {
        setProgress(100)
        clearInterval(interval)
      }
    }, 50)

    return () => {
      clearInterval(interval)
    }
  }, [voiceDetectedTime])

  return (
    <>
      <div
        className={classNames(
          'absolute left-1/2 top-0 -translate-x-1/2 -translate-y-1/2 select-none'
        )}
      >
        {/* bg */}
        <div className='absolute inset-0 -z-20 scale-[70%] rounded-full bg-white transition-all' />

        {/* Main pulse */}
        <div
          className='absolute inset-0 -z-10 rounded-full transition-all'
          style={{
            ...(isRecording && {
              transform: `scaleX(${0.7 + speechVolume * 3}) scaleY(${0.7 + speechVolume * 3})`,
            }),
          }}
        >
          {voiceDetectedTime ? (
            <FilledPieLoader
              progress={isRecording && !speechVolume ? progress : 100}
              fillColor='rgb(82 82 158)'
            />
          ) : (
            <div
              className={classNames(
                ' h-full w-full rounded-full',
                isLoading ? 'bg-brand-form-accent' : 'bg-brand-violet-light'
              )}
            />
          )}
        </div>

        {/* Background pulse */}
        <div
          className={classNames(
            'absolute inset-0 -z-10 rounded-full transition-all',
            isRecording || isLoading ? 'bg-brand-form-accent' : 'bg-brand-violet-light'
          )}
          style={{
            transition: 'transform 0.45s cubic-bezier(0.25, 0.1, 0, 1.5)',
            ...(isRecording && {
              animation: 'pulseAnimation 2s infinite',
            }),
          }}
        />

        {/* Icon */}
        <div
          className={classNames(
            'pointer-events-none absolute inset-0 z-30 flex items-center justify-center',
            isRecording || isLoading ? 'text-white' : 'text-white'
          )}
        >
          {isLoading ? loader : <Mic />}
        </div>

        <div
          className={classNames('size-[60px] cursor-pointer select-none')}
          onMouseDown={() => {
            setProgress(100)
            onMouseDown()
          }}
          onMouseUp={onMouseUp}
          onTouchStart={onMouseDown}
          onTouchEnd={onMouseUp}
          onClick={onClick}
          onContextMenu={(e) => e.preventDefault()}
        />
      </div>
      <div className='mt-2 flex w-full gap-1 rounded-[4px] px-4 md:justify-center 2xl:-mt-1'>
        <span className='h-[30px] text-[12px] leading-[1.2em] text-[#52529E]'>
          {getStatusText()}
        </span>
      </div>
    </>
  )
}
